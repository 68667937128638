import React from 'react'
import { } from "react-icons/bs";
import Content from './Components/Content';
import Prince from './Components/Prince';
import Navbar from './Pages/Navbar';

import { Link, useNavigate } from 'react-router-dom';
import '../src/assets/css/style.css'
import './amit.css'
import '../src/assets/css/icon-list.css'



const Employee = () => {




  // useEffect(() => {
  //   const token = localStorage.getItem('Token');

  //   if (!token) {
  //     console.log(token);
  //     navigate('/');
  //   }
  // }, [navigate]);
  return (
    <>
      <div className="page">

        <Navbar />
        <Prince />

        <Content />

      </div>



    </>
  )
}

export default Employee