import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './prince.css'
import mediaQuery from '../mediaQueries';


function Prince() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
    const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
    const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
    const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);
    const [isDropdownOpen6, setIsDropdownOpen6] = useState(false);
    const [isDropdownOpen7, setIsDropdownOpen7] = useState(false);
    const [isDropdownOpen8, setIsDropdownOpen8] = useState(false);
    const [isDropdownOpen9, setIsDropdownOpen9] = useState(false);
    const [isDropdownEnabled, setIsDropdownEnabled] = useState(true);
    const [profilePicFile, setProfilePicFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [users, setUsers] = useState([]);
    const [activeStatus, setActiveStatus] = useState(null);
    const [activeStatus2, setActiveStatus2] = useState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [toggleTab, setToggleTab] = useState(1000);

    const [isModalOpen5, setIsModalOpen5] = useState(false);
    const [options, setOptions] = useState([]);

    const [statusFilter, setStatusFilter] = useState('');
    const [search, setSearch] = useState('');
    const [genderFilter, setGenderFilter] = useState('');

    const [newleadcount, setNewleadcount] = useState();
    const [newleadcount2, setNewleadcount2] = useState();
    const [newleadcount3, setNewleadcount3] = useState();
    const [newleadcount4, setNewleadcount4] = useState();
    const [newleadcount5, setNewleadcount5] = useState();
    const [newleadcount6, setNewleadcount6] = useState();
    const [newleadcount7, setNewleadcount7] = useState();

    const [refresh, setRefresh] = useState(false);

    const { status } = useParams();
    const { status2 } = useParams();
    const navigate = useNavigate();


    const [isHovered, setIsHovered] = useState(false);
    const [employee, setEmployee] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [userType, setUserType] = useState(1);
    const [isSticky, setIsSticky] = useState(false);
    const initialFormData = {
        fullName: '',
        email: '',
        mobileNo: '',
        companyName: '',
        companyEmail: '',
        companyPhone: '',
        companyWebsite: '',
        category: '',
        quality: '',
        quantity: '',
        dateOfDelivery: '',
        shipFrom: '',
        shipTo: '',
        rate: '',
    }
    const initialFormData2 = {
        id: "",
    }
    const [formData, setFormData] = useState(initialFormData);
    const [formData2, setFormData2] = useState(initialFormData2);
    const navigation = useNavigate();
    const apiUrl = process.env.REACT_APP_URL;


    const handleStatusChange = (status) => {
        // If status is null or not provided, set it to null
        const newStatus = status ? `/newCom/${status}` : null;

        setActiveStatus(newStatus);
        localStorage.setItem('activeStatus', newStatus);
    };


    useEffect(() => {
        const storedActiveStatus = localStorage.getItem('activeStatus');
        if (storedActiveStatus) {
            setActiveStatus(storedActiveStatus);
        }
    }, []);



    // ledes list api  
    useEffect(() => {
        const fetchData = async () => {
            try {
                const Token = localStorage.getItem('Token');
                const response = await fetch(`${apiUrl}/lead/getAllLead`, {
                    headers: {
                        'Authorization': `Bearer ${Token}`
                    }
                });

                const data = await response.json();

                setNewleadcount(data.New);
                setNewleadcount2(data.Followup);
                setNewleadcount3(data.Assigned);
                setNewleadcount4(data.convertToSale);
                setNewleadcount5(data.preProforma);
                setNewleadcount6(data.Quotations);
                setNewleadcount7(data.LOST);

                if (data.status === 'success' && Array.isArray(data.data)) {
                    setUsers(data.data);
                    setRefresh(!refresh);
                } else {
                    console.error('API request was not successful or data is not an array:', data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    //refresh


    const filteredUsers = users.filter((user) => {
        return (
            (user.id.toString().includes(search) || user.fullName.includes(search)) &&
            (statusFilter === '' || user.status.toString() === statusFilter) &&
            (genderFilter === '' || user.gender === genderFilter || (genderFilter === 'male' && user.gender === 'Male') || (genderFilter === 'female' && user.gender === 'Female'))
        );
    });


    const toggleDropdown = () => {
        if (!isDropdownOpen) {
            setIsDropdownOpen(true);
            setIsDropdownOpen1(false);
            setIsDropdownOpen2(false);
            setIsDropdownOpen3(false);
            setIsDropdownOpen4(false);
        } else {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isDropdownOpen) {
                // Close the dropdown only if it's open
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isDropdownOpen]);



    const toggleDropdown1 = () => {
        if (!isDropdownOpen1) {
            setIsDropdownOpen1(true);
            setIsDropdownOpen2(false);
            setIsDropdownOpen3(false);
            setIsDropdownOpen4(false);
            setIsDropdownOpen5(false);
            setIsDropdownOpen6(false);
            setIsDropdownOpen7(false);
            setIsDropdownOpen(false);
        } else {
            setIsDropdownOpen1(false);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isDropdownOpen1) {
                // Close the dropdown only if it's open
                setIsDropdownOpen1(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isDropdownOpen1]);

    const toggleDropdown2 = () => {
        setIsDropdownOpen2(!isDropdownOpen2);
        setIsDropdownOpen1(false);
        setIsDropdownOpen3(false);
        setIsDropdownOpen4(false);
        setIsDropdownOpen(false);
    };


    useEffect(() => {
        const handleClickOutsideDropdown = (event) => {
            if (isDropdownOpen2) {
                // Close the dropdown only if it's open
                setIsDropdownOpen2(false);
            }
        };

        document.addEventListener('click', handleClickOutsideDropdown);

        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, [isDropdownOpen2]);






    const toggleDropdown4 = () => {
        if (!isDropdownOpen4) {
            setIsDropdownOpen4(true);
            setIsDropdownOpen1(false);
            setIsDropdownOpen2(false);
            setIsDropdownOpen3(false);
            setIsDropdownOpen5(false);
            setIsDropdownOpen6(false);
            setIsDropdownOpen7(false);
            setIsDropdownOpen(false);
        } else {
            setIsDropdownOpen4(false);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isDropdownOpen4) {
                // Close the dropdown only if it's open
                setIsDropdownOpen4(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isDropdownOpen4]);


    const toggleDropdown5 = () => {
        if (!isDropdownOpen5) {
            setIsDropdownOpen5(true);
            setIsDropdownOpen1(false);
            setIsDropdownOpen2(false);
            setIsDropdownOpen3(false);
            setIsDropdownOpen4(false);
            setIsDropdownOpen6(false);
            setIsDropdownOpen7(false);
            setIsDropdownOpen(false);
        } else {
            setIsDropdownOpen5(false);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isDropdownOpen5) {
                // Close the dropdown only if it's open
                setIsDropdownOpen5(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isDropdownOpen5]);

    const toggleDropdown6 = () => {
        if (!isDropdownOpen6) {
            setIsDropdownOpen6(true);
            setIsDropdownOpen1(false);
            setIsDropdownOpen2(false);
            setIsDropdownOpen3(false);
            setIsDropdownOpen4(false);
            setIsDropdownOpen5(false);
            setIsDropdownOpen7(false);
            setIsDropdownOpen(false);
        } else {
            setIsDropdownOpen6(false);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isDropdownOpen6) {
                // Close the dropdown only if it's open
                setIsDropdownOpen6(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isDropdownOpen6]);


    const toggleDropdown7 = () => {
        if (!isDropdownOpen7) {
            setIsDropdownOpen7(true);
            setIsDropdownOpen1(false);
            setIsDropdownOpen2(false);
            setIsDropdownOpen3(false);
            setIsDropdownOpen4(false);
            setIsDropdownOpen5(false);
            setIsDropdownOpen6(false);
            setIsDropdownOpen(false);
        } else {
            setIsDropdownOpen7(false);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isDropdownOpen7) {
                // Close the dropdown only if it's open
                setIsDropdownOpen7(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isDropdownOpen7]);

    const linkStyle = {
        color: 'white',
        padding: '12px',
        paddingRight: '20px',
        margin: '2px -10px',
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s ease',
        textDecoration: 'none',
    };

    const hoverStyle = {
        // Change background color on hover
    };


    const mergedLinkStyle = { ...linkStyle, ...hoverStyle };
    const linkHoverStyle = {

    };
    const navBarStyle2 = {
        backgroundColor: 'DarkGreen',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '50px',
        color: 'white',

        '.panel.tabs-style-3': {
            marginBottom: '20px',
            color: 'white !important',
        }
    };

    const navBarStyle = {
        backgroundColor: 'DarkGreen',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        position: '',

        // overflowY: 'scroll',
        width: '100VW'

    };


    const openDropdownItemStyle = {
        color: 'black',
    };

    const responsiveDropdown = {

    };


    const responsiveNavItem = {


    };

    useEffect(() => {
        async function getEmp() {
            const apiUrl = process.env.REACT_APP_URL;
            const Token = localStorage.getItem("Token");

            let response = await fetch(`${apiUrl}/employee/employee`, {
                method: "POST",
                headers: {
                    Auth: `Bearer ${Token}`
                },
            })
            response = await response.json();

            if (response.status === "success") {
                const amit = response.data.userType
                setEmployee(amit);

            }
        }
        getEmp();
    }, [])


    return (
        <>
            {employee === '0' && (

                <div style={navBarStyle}>

                    <div className="nav-item dropdown" style={{ marginLeft: '18px', ...responsiveNavItem, ...responsiveDropdown, position: 'relative' }}>
                        <div className=" ">
                            <div className="panel tabs-style-3  p-0 tabs-style-3-0">
                                <div className="">
                                    <div className=" ">
                                        {/* Tabs */}
                                        <ul className="nav panel-tabs " >
                                            <li className="" >
                                                <Link
                                                    to="/newCom/New"
                                                    onClick={() => handleStatusChange('New')}
                                                >
                                                    <i className="fas fa-wallet me-2" style={{ color: 'white' }}></i>
                                                    <span style={{ color: activeStatus === '/newCom/New' ? 'white' : 'white' }}>
                                                        New

                                                    </span>
                                                </Link>

                                            </li>


                                            <li >
                                                <Link
                                                    to="/newCom/Assigned"

                                                    onClick={() => handleStatusChange('Assigned')}
                                                >
                                                    <i className="fas fa-wallet me-2" style={{ color: 'white' }}></i>
                                                    <span style={{ color: activeStatus === '/newCom/Assigned' ? 'white' : 'white' }}>
                                                        Assigned

                                                    </span>

                                                </Link>
                                            </li>

                                            <li>
                                                <Link
                                                    to="/newCom/Followup"
                                                    onClick={() => handleStatusChange('Followup')}
                                                >
                                                    <i className="fas fa-wallet me-2" style={{ color: 'white' }}></i>
                                                    <span style={{ color: activeStatus === '/newCom/Followup' ? 'white' : 'white' }}>
                                                        Followup

                                                    </span>

                                                </Link>
                                            </li>

                                            <li>
                                                <Link
                                                    to="/newCom/Quotations"

                                                    onClick={() => handleStatusChange('Quotations')}
                                                >
                                                    <i className="fas fa-wallet me-2" style={{ color: 'white' }}></i>
                                                    <span style={{ color: activeStatus === '/newCom/Quotations' ? 'white' : 'white' }}>
                                                        Quotation

                                                    </span>

                                                </Link>

                                            </li>
                                            <li>
                                                <Link
                                                    to="/newCom/Pre-proforma"

                                                    onClick={() => handleStatusChange('Pre-proforma')}
                                                >
                                                    <i className="fas fa-wallet me-2" style={{ color: 'white' }}></i>
                                                    <span style={{ color: activeStatus === '/newCom/Pre-proforma' ? 'white' : 'white' }}>
                                                        Proforma

                                                    </span>

                                                </Link>
                                            </li>

                                            <li>

                                                <Link to="/newCom/Convertedtosale"

                                                    onClick={() => handleStatusChange('Convertedtosale')}>
                                                    <i className="fas fa-wallet me-2" style={{ color: 'white' }}></i>
                                                    <span style={{ color: activeStatus === 'convertedtosale' ? 'white' : 'white' }}>
                                                        Convertedsale

                                                    </span>


                                                </Link>

                                            </li>

                                            <li>
                                                <Link
                                                    to="/newCom/LOST"

                                                    onClick={() => handleStatusChange('LOST')}
                                                >
                                                    <i className="fas fa-wallet me-2" style={{ color: 'white' }}></i>
                                                    <span style={{ color: activeStatus === 'Lost' ? 'white' : 'white' }}>
                                                        Lost

                                                    </span>

                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>

            )}

            {employee === '1' && (

                <div >

                    <div style={navBarStyle}>
                        <div className="nav-item dropdown" style={{ marginLeft: '18px', ...responsiveNavItem, ...responsiveDropdown, position: 'relative' }}>

                            <a
                                className={`nav-link dropdown-toggle ${isDropdownOpen ? 'show' : ''}`}
                                id="navbarDropdown"
                                role="button"
                                onClick={toggleDropdown}
                                aria-haspopup="true"
                                aria-expanded={isDropdownOpen}
                                style={linkStyle}
                            >
                                <i class="fas fa-wallet me-2"></i>

                                SETUP
                            </a>
                            <div className={`dropdown-menu ${isDropdownOpen ? 'show red-text' : ''}`} aria-labelledby="navbarDropdown" style={isDropdownOpen ? { backgroundColor: 'white', borderRadius: '10px', color: 'red' } : {}}>
                                <Link to="/Master" style={isDropdownOpen ? openDropdownItemStyle : linkHoverStyle}>


                                    Master
                                </Link>
                                <Link to="/MasterVal" style={isDropdownOpen ? openDropdownItemStyle : linkHoverStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>


                                    Master Value
                                </Link>
                            </div>
                        </div>
                        <a
                            className=""
                            id="navbarDropdown1"
                            role="button"
                            onClick={toggleDropdown1}
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen1}
                            style={linkStyle}
                        ></a>

                        <div className="nav-item dropdown" style={{ marginRight: '10px', ...responsiveNavItem, ...responsiveDropdown }}>
                            <a
                                className={`nav-link dropdown-toggle ${isDropdownOpen1 ? 'show' : ''}`}

                                id="navbarDropdown1"
                                role="button"
                                onClick={toggleDropdown1}
                                aria-haspopup="true"
                                aria-expanded={isDropdownOpen1}
                                style={linkStyle}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <i className="fas fa-wallet me-2"></i>
                                HRMS
                            </a>
                            <div className={`dropdown-menu ${isDropdownOpen1 ? 'show' : ''}`} style={isDropdownOpen1 ? { backgroundColor: 'white', borderRadius: '10px', color: 'red' } : {}}>

                                <Link to="/MainPage2" className="dropdown-item" style={isDropdownOpen1 ? openDropdownItemStyle : linkHoverStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}><i class="angle fe fe-chevron-right"></i>


                                    Manager Employee
                                </Link>


                            </div>

                        </div>


                        {/* Dropdown 2 */}
                        <a
                            className=""

                            id="navbarDropdown2"
                            role="button"
                            onClick={toggleDropdown2}
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen2}
                            style={linkStyle}
                        ></a>

                        <div className="nav-item dropdown" style={{ marginRight: '10px', ...responsiveNavItem, ...responsiveDropdown }}>
                            <a
                                className={`nav-link dropdown-toggle ${isDropdownOpen2 ? 'show' : ''}`}

                                id="navbarDropdown2"
                                role="button"
                                onClick={toggleDropdown2}
                                aria-haspopup="true"
                                aria-expanded={isDropdownOpen2}
                                style={linkStyle}
                            >
                                <i className="fas fa-wallet me-2"></i>
                                Sales CRM
                            </a>
                            <div className={`dropdown-menu ${isDropdownOpen2 ? 'show' : ''}`} aria-labelledby="navbarDropdown2" style={isDropdownOpen2 ? { backgroundColor: 'white', borderRadius: '10px' } : {}}>


                                <Link to="/newCom/New" className="" style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}>


                                    New
                                    <span onClick={() => handleStatusChange('New')}>

                                        <span style={{ color: activeStatus === '/newCom/New' ? 'white' : 'white' }}>


                                        </span>
                                    </span>
                                </Link>


                                <Link to="/newCom/Assigned" className="" style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}>

                                    Assigned
                                    <span onClick={() => handleStatusChange('Assigned')}>

                                        <span style={{ color: activeStatus === '/newCom/Assigned' ? 'white' : 'white' }}>


                                        </span>
                                    </span>
                                </Link>
                                <Link to="/newCom/Followup" className="" style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}>

                                    Followup
                                    <span onClick={() => handleStatusChange('Followup')}>

                                        <span style={{ color: activeStatus === '/newCom/Followup' ? 'white' : 'white' }}>


                                        </span>
                                    </span>
                                </Link>

                                <Link
                                    to="/newCom/Quotations"
                                    className=""
                                    style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                                >

                                    <span style={{ marginTop: '70px', }}>
                                        Quotations
                                        <span onClick={() => handleStatusChange('Quotations')}>

                                            <span style={{ color: activeStatus === '/newCom/Quotations' ? 'white' : 'white' }}>


                                            </span>
                                        </span>
                                    </span>
                                </Link>
                                <Link
                                    to="/newCom/Pre-proforma"
                                    className=""
                                    style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                                >

                                    <span style={{ marginTop: '70px', }}>
                                        Pre-proforma
                                        <span onClick={() => handleStatusChange('Pre-proforma')}>

                                            <span style={{ color: activeStatus === '/newCom/Pre-proforma' ? 'white' : 'white' }}>


                                            </span>
                                        </span>
                                    </span>
                                </Link>
                                <Link
                                    to="/newCom/Convertedtosale"
                                    className=""
                                    style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                                >

                                    <span style={{ marginTop: '70px', }}>
                                        Convert Sale
                                        <span onClick={() => handleStatusChange('Convertedtosale')}>

                                            <span style={{ color: activeStatus === '/newCom/Convertedtosale' ? 'white' : 'white' }}>


                                            </span>
                                        </span>
                                    </span>
                                </Link>
                                <Link
                                    to="/newCom/LOST"
                                    className=""
                                    style={isDropdownOpen2 ? openDropdownItemStyle : linkStyle}
                                >

                                    <span style={{ marginTop: '70px', }}>
                                        Lost
                                        <span onClick={() => handleStatusChange('LOST')}>

                                            <span style={{ color: activeStatus === '/newCom/LOST' ? 'white' : 'white' }}>


                                            </span>
                                        </span>
                                    </span>
                                </Link>
                            </div>

                        </div>


                        {/* Dropdown 4 */}
                        <a
                            className=""

                            id="navbarDropdown4"
                            role="button"
                            onClick={toggleDropdown4}
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen4}
                            style={linkStyle}
                        ></a>

                        <div className="nav-item dropdown" style={{ marginRight: '10px', ...responsiveNavItem, ...responsiveDropdown }}>
                            <a
                                className={`nav-link dropdown-toggle ${isDropdownOpen4 ? 'show' : ''}`}

                                id="navbarDropdown4"
                                role="button"
                                onClick={toggleDropdown4}
                                aria-haspopup="true"
                                aria-expanded={isDropdownOpen4}
                                style={linkStyle}
                            >
                                <i className="fas fa-wallet me-2"></i>
                                Buyers
                            </a>
                            <div className={`dropdown-menu ${isDropdownOpen4 ? 'show' : ''}`} aria-labelledby="navbarDropdown4" style={isDropdownOpen4 ? { backgroundColor: 'white', borderRadius: '10px' } : {}}>
                                <Link to='/BuyersList2' className="dropdown-item" href="#" style={isDropdownOpen4 ? openDropdownItemStyle : linkStyle}>


                                    Manage Buyers
                                </Link>

                            </div>
                        </div>

                        {/* Dropdown 5 */}
                        <a
                            className=""

                            id="navbarDropdown5"
                            role="button"
                            onClick={toggleDropdown5}
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen5}
                            style={linkStyle}
                        ></a>

                        <div className="nav-item dropdown" style={{ marginRight: '10px', ...responsiveNavItem, ...responsiveDropdown }}>
                            <a
                                className={`nav-link dropdown-toggle ${isDropdownOpen5 ? 'show' : ''}`}

                                id="navbarDropdown5"
                                role="button"
                                onClick={toggleDropdown5}
                                aria-haspopup="true"
                                aria-expanded={isDropdownOpen5}
                                style={linkStyle}
                            >
                                <i className="fas fa-wallet me-2"></i>
                                Millers
                            </a>
                            <div className={`dropdown-menu ${isDropdownOpen5 ? 'show' : ''}`} aria-labelledby="navbarDropdown5" style={isDropdownOpen5 ? { backgroundColor: 'white', borderRadius: '10px' } : {}}>
                                <Link to="/ManageMillers" className="dropdown-item" href="#" style={isDropdownOpen5 ? openDropdownItemStyle : linkStyle}>


                                    Manage Millers
                                </Link>

                            </div>

                        </div>

                        {/* Dropdown 6 */}
                        <a
                            className=""

                            id="navbarDropdown6"
                            role="button"
                            onClick={toggleDropdown6}
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen6}
                            style={linkStyle}
                        ></a>

                        <div className="nav-item dropdown" style={{ marginRight: '10px', ...responsiveNavItem, ...responsiveDropdown }}>
                            <a
                                className={`nav-link dropdown-toggle ${isDropdownOpen6 ? 'show' : ''}`}

                                id="navbarDropdown6"
                                role="button"
                                onClick={toggleDropdown6}
                                aria-haspopup="true"
                                aria-expanded={isDropdownOpen6}
                                style={linkStyle}
                            >
                                <i className="fas fa-wallet me-2"></i>
                                Exporters
                            </a>
                            <div className={`dropdown-menu ${isDropdownOpen6 ? 'show' : ''}`} aria-labelledby="navbarDropdown6" style={isDropdownOpen6 ? { backgroundColor: 'white', borderRadius: '10px' } : {}}>
                                <Link to="/ManageExporters" className="dropdown-item" href="#" style={isDropdownOpen6 ? openDropdownItemStyle : linkStyle}>


                                    Manage Exporters
                                </Link>

                            </div>

                        </div>

                        {/* Dropdown 7 */}
                        <a
                            className=""

                            id="navbarDropdown7"
                            role="button"
                            onClick={toggleDropdown7}
                            aria-haspopup="true"
                            aria-expanded={isDropdownOpen7}
                            style={linkStyle}
                        ></a>

                        <div className="nav-item dropdown" style={{ marginRight: '10px', ...responsiveNavItem, ...responsiveDropdown }}>
                            <a
                                className={`nav-link dropdown-toggle ${isDropdownOpen7 ? 'show' : ''}`}

                                id="navbarDropdown7"
                                role="button"
                                onClick={toggleDropdown7}
                                aria-haspopup="true"
                                aria-expanded={isDropdownOpen7}
                                style={linkStyle}
                            >
                                <i class="fas fa-wallet me-2"></i>
                                Vendors
                            </a>
                            <div className={`dropdown-menu ${isDropdownOpen7 ? 'show' : ''}`} aria-labelledby="navbarDropdown7" style={isDropdownOpen7 ? { backgroundColor: 'white', borderRadius: '10px' } : {}}>
                                <Link to="/ManageVendors" className="dropdown-item" href="#" style={isDropdownOpen7 ? openDropdownItemStyle : linkStyle}>


                                    Manage Vendors
                                </Link>

                            </div>

                        </div>

                        {/* Dropdown 8 */}
                        <Link to="/AllOrders" style={{
                            color: 'white',
                        }}
                        >
                            <i class="fas fa-wallet me-2"/>
                            Order
                        </Link>
                    </div>

                </div>
            )}



        </>
    );
}

export default Prince;
