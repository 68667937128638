import React, { useState, useEffect } from 'react'
import Prince from '../../Components/Prince'
import Navbar from '../Navbar'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../amit.css'


const NewLeads = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [options, setOptions] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [search, setSearch] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [genderOptions, setGenderOptions] = useState([]);
  const [genderOptions5, setGenderOptions5] = useState([]);
  const [genderOptions6, setGenderOptions6] = useState([]);
  

  const [refresh, setRefresh] = useState(false);

  const { status } = useParams();
 
  const navigate = useNavigate();

  const initialFormData = {
    fullName: '',
    email: '',
    mobileNo: '',
    companyName: '',
    companyEmail: '',
    companyPhone: '',
    companyWebsite: '',
    category: '',
    quality: '',
    quantity: '',
    dateOfDelivery: '',
    shipFrom: '',
    shipTo: '',
    rate: '',
  }
  const initialFormData2 = {
    id: "",
  }
  const [leadCounts, setLeadCounts] = useState({
    new: 0,
    followup: 0,
    assigned: 0,
    convertToSale: 0,
    preProforma: 0,
    quotations: 0,
    lost: 0
  });
  const [formData, setFormData] = useState(initialFormData);
  const [formData2, setFormData2] = useState(initialFormData2);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [activeStatus, setActiveStatus] = useState(localStorage.getItem('activeStatus') || '');
 
  const navigation = useNavigate();
  const apiUrl = process.env.REACT_APP_URL;
 

  const handleStatusChange = (status) => {
    setActiveStatus(`/newCom/${status}`);
    localStorage.setItem('activeStatus', `/newCom/${status}`);
    // setRefresh((prevRefresh) => !prevRefresh);

  };

  useEffect(() => {
    handleStatusChange(status);
  }, [status])

//popup open
  const handleOpenModal = () => {
    setModalOpen(true);
    document.body.classList.add('modal-open');
  };

//popup close
  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.classList.remove('modal-open');
  };


  //Boss a
  useEffect(() => {
    const Token = localStorage.getItem('Token');
   
    fetch(`${apiUrl}/employee/allEmpDesig`, {
      headers: {
        'Authorization': `Bearer ${Token}` // Add the token to the Authorization header
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setOptions(data.data);
          // const amit = data.id;
          // console.log(amit);
        } else {
          
        }
      })
      .catch(error => {
        
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData2({
      ...formData2,
      [name]: value,
    });
  };



  //Lost
  const Token = localStorage.getItem("Token");
  useEffect(() => {
    const Token = localStorage.getItem('Token');

    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions(data.data);
        } else {
         
        }
      })
      .catch(error => {
        
      });
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem('Token');


    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions5(data.data);
        } else {
          
        }
      })
      .catch(error => {
      
      });
  }, []);
  const currentDate = new Date().toISOString().split('T')[0];

  //Quality
  useEffect(() => {
    const Token = localStorage.getItem('Token');


    fetch(`${apiUrl}/master/getAllMasterData/9`, {
      headers: {
        Authorization: `Bearer ${Token}`

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions6(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

 


  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith('image/')) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData((prevData) => ({
          ...prevData,
          profilePhoto: file,


        }));
      } else if (file.type === 'application/pdf') {
        // Set aadhaarUpload for PDF files
        setFormData((prevData) => ({
          ...prevData,
          aadhaarUpload: file,
          panUpload: file,
          drivingLicence: file,
          cheque: file,
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };



// new leads submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
  
      const token = localStorage.getItem('Token');
      console.log('Token:', token);
  
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }
  
      const response = await fetch(`${apiUrl}/lead/add-Lead`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formDataToSend,
      });
  
      if (!response.ok) {
        const responseData = await response.json();
  
        if (responseData.status === 'error' && responseData.message) {
          alert(responseData.message);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
  
      const data = await response.json();
      console.log('Form submitted successfully!', data);
  
     
     setRefresh(!refresh);

      navigation(`/newCom/New`);
      handleCloseModal();
      setFormData(initialFormData);
      
    } catch (error) {
      console.error('Error:', error);
      // Handle specific errors or display appropriate messages
    }
  };
  
// form validation start 

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const newErrors = { ...errors };

    if (!value.trim()) {
     
    } else if (
      name === 'fullName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[name] = `Please enter a valid ${name} with only alphabetical characters`;
    } else if (
      name === 'companyName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[name] = `Please enter a valid Company Name with only alphabetical characters`;
    } else if (
      name === 'email' && (!value.trim() || !emailRegex.test(value))
    ) {
      newErrors[name] = 'Please enter a valid email address';
    }
    else if (
      name === 'companyEmail' && (!value.trim() || !emailRegex.test(value))
    ) {
      newErrors[name] = 'Please enter a valid Company Email address';
    } else if (
      name === 'mobileNo' && (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid 10-digit mobile number';
    }
    else if (
      name === 'companyPhone' && (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid 10-digit mobile number';
    } 
    
    else if (
      name === 'perHourCharges' && !/^\d{8}$/.test(value)
    ) {
      newErrors[name] = 'Please enter a valid 8-digit perHourCharges';
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
        // Handle case where the field is required
        newErrors[name] = `Full Name is required *`;
    } else if (name === 'fullName' && !/\s/.test(value.trim())) {
        // Check if there is at least one space after the last name

    } else {
        // Remove error if the field is valid
        delete newErrors[name];
    }

    setErrors(newErrors);
};

const handleInputBlur2 = (event) => {
  const { name, value } = event.target;
  const newErrors = { ...errors };

  if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `Email is required *`;
  } else if (name === 'email' && !/\s/.test(value.trim())) {
      // Check if there is at least one space after the last name

  } else {
      // Remove error if the field is valid
      delete newErrors[name];
  }

  setErrors(newErrors);
};
const handleInputBlur3 = (event) => {
  const { name, value } = event.target;
  const newErrors = { ...errors };

  if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `Mobile Number is required *`;
  } else if (name === 'mobileNo' && !/\s/.test(value.trim())) {
      // Check if there is at least one space after the last name

  } else {
      // Remove error if the field is valid
      delete newErrors[name];
  }

  setErrors(newErrors);
};

// form validation end


  // ledes list api  
  const fetchData = async () => {
    try {
      const Token = localStorage.getItem('Token');
      const response = await fetch(`${apiUrl}/lead/getAllLead`, {
        headers: {
          'Authorization': `Bearer ${Token}`
        }
      });

      const data = await response.json();
      setLeadCounts({
        new: data.New,
        followup: data.Followup,
        assigned: data.Assigned,
        convertToSale: data.convertToSale,
        preProforma: data.preProforma,
        quotations: data.Quotations,
        lost: data.LOST
      });

      if (data.status === 'success' && Array.isArray(data.data)) {
        setUsers(data.data);
        setRefresh(!refresh);

      } else {
        console.error('API request was not successful or data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);


  const filteredUsers = users.filter((user) => {
    return (
      (user.id.toString().includes(search) || user.fullName.includes(search)) &&
      (statusFilter === '' || user.status.toString() === statusFilter) &&
      (genderFilter === '' || user.gender === genderFilter || (genderFilter === 'male' && user.gender === 'Male') || (genderFilter === 'female' && user.gender === 'Female'))
    );
  });

  
  useEffect(() => {
    const token = localStorage.getItem('Token');

    if (!token) {
      console.log(token);
      navigate('/');
    }
  }, [navigate]);

  return (
    <>

      {/* Main Header*/}
      <div className="main-header side-header sticky">
        <Navbar />
        <Prince />

        {/* Main Content*/}
        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">All Leads</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">CRM </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      New Lead{" "}
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">

                    <a
                      href="#"
                      className="btn btn-primary my-2 btn-icon-text"
                      onClick={handleOpenModal}
                    >
                      <i className="fe fe-plus me-2" /> Add New Lead
                    </a>
                    <div
                      className={`modal ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }}
                      tabIndex="-1"
                      role="dialog"

                    >
                      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Add Lead</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={handleCloseModal}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>

                          <div className="modal-body" style={{ width: '100%' }} >
                            <div className="row row-sm-1">

                              <div className="col-lg-4">
                                <label className="form-label">
                                  Full Name: <span className="tx-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="fullName"
                                  value={formData.fullName}
                                  placeholder="Enter firstname"
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleInputBlur}
                                />
                                {errors.fullName && <p style={{ color: 'red' }}>{errors.fullName}</p>}
                              </div>
                              <div className="col-lg-4 form-group">
                                <label className="form-label">
                                  Email ID: <span className="tx-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="email"
                                  value={formData.email}

                                  onChange={handleChange}
                                  placeholder="Enter email"
                                  required=""
                                  type="text"
                                  onBlur={handleInputBlur2}
                                />
                                {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                              </div>
                              <div className="col-lg-4 form-group">
                                <label className="form-label">
                                  Mobile No: <span className="tx-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="mobileNo"
                                  value={formData.mobileNo}
                                  placeholder="Enter mobile number"
                                  required=""
                                  type="text"

                                  onChange={handleChange}
                                  onBlur={handleInputBlur3}
                                />
                                {errors.mobileNo && <p style={{ color: 'red' }}>{errors.mobileNo}</p>}
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Company Name</label>
                                <input
                                  className="form-control"
                                  name="companyName"
                                  required=""
                                  value={formData.companyName}
                                  type="text"
                                  onChange={handleChange}
                                 
                                />
                                {errors.companyName && <p style={{ color: 'red' }}>{errors.companyName}</p>}
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Company Email</label>
                                <input
                                  className="form-control"
                                  name="companyEmail"
                                  value={formData.companyEmail}
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                 
                                />
                                {errors.companyEmail && <p style={{ color: 'red' }}>{errors.companyEmail}</p>}
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Company Phone</label>
                                <input
                                  className="form-control"
                                  name="companyPhone"

                                  required=""
                                  type="text"
                                  value={formData.companyPhone}
                                  onChange={handleChange}
                                  
                                />
                                {errors.companyPhone && <p style={{ color: 'red' }}>{errors.companyPhone}</p>}
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Company Website</label>
                                <input
                                  className="form-control"
                                  name="companyWebsite"
                                  required=""
                                  type="text"
                                  value={formData.companyWebsite}
                                  onChange={handleChange}
                                />
                              </div>
                              {/* <div className="col-sm-4 form-group">
                                <label className="form-label">Category</label>
                                <select className="form-control select select2">
                                  <option>Select</option>
                                </select>
                              </div> */}
                              <div className="col-lg-12">
                                <hr className="my-2" />
                              </div>
                            </div>
                            <div className="row row-sm">
                              <div className="col-lg-12">
                                <h6 className="main-content-label mb-3 mt-2">Requirement</h6>
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Quality</label>
                                <select
                                  className="form-control select2"
                                  name="quality"
                                  value={formData.quality}
                                  onChange={handleChange}
                                >
                                  <option>Select</option>
                                  {genderOptions6.map((option, index) => (
                                    <option key={option.id} value={option.name}>{option.name}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label className="form-label">Quantity (in metric tons)</label>
                                  <input
                                    className="form-control"
                                    name="quantity"
                                    required=""
                                    type="text"
                                    value={formData.quantity}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label className="form-label">Date of delivery</label>
                                  <input
                                    className="form-control"
                                    name="dateOfDelivery"
                                    required=""
                                    type="date"
                                    value={formData.dateOfDelivery}
                                    onChange={handleChange}
                                     min={currentDate} 
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <label className="form-label">Ship From</label>
                                <div className="input-group mb-3">
                                  <span className="input-group-text border-end-0" id="basic-addon1">
                                    <i className="fa fa-map-marker-alt" />
                                  </span>
                                  <select
                                    className="form-control select2"
                                    name="shipFrom"
                                    value={formData.shipFrom}
                                    onChange={handleChange}
                                  >
                                    <option>Select</option>
                                    {genderOptions5.map((option, index) => (
                                      <option key={option.id} value={option.name}>{option.name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <label className="form-label">Ship To</label>
                                <div className="input-group mb-3">
                                  <span className="input-group-text border-end-0" id="basic-addon2">
                                    <i className="fa fa-map-marker-alt" />
                                  </span>
                                  <select
                                    className="form-control select2"
                                    name="shipTo"
                                    value={formData.shipTo}
                                    onChange={handleChange}
                                  >
                                    <option>Select</option>
                                    {genderOptions5.map((option, index) => (
                                      <option key={option.id} value={option.name}>{option.name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label className="form-label">Rate (in USD)</label>
                                  <input
                                    className="form-control"
                                    name="rate"
                                    required=""
                                    type="text"
                                    value={formData.rate}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <button className="btn ripple btn-primary" onClick={handleSubmit} type="button">
                                  Save
                                </button>
                                <ToastContainer />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                          Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => { handleSubmit(); handleCloseModal(); }}>
                          Save changes
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* End Page Header */}
      
        {/* Row */}

        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <div className="text-wrap">
                  <div className="panel panel-primary tabs-style-3 p-0 tabs-style-3-0">
                    <div className="tab-menu-heading">
                      <div className=" ">
                        {/* Tabs */}
                        <ul className="nav panel-tabs horizontal_tab">
                          <li className="">
                            <Link
                              to="/newCom/New"
                              className={activeStatus === '/newCom/New' ? 'active' : ''}
                              onClick={() => handleStatusChange('New')}
                            >
                              New ({leadCounts.new})
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/newCom/Assigned"
                              className={activeStatus === '/newCom/Assigned' ? 'active' : ''}
                              onClick={() => handleStatusChange('Assigned')}
                            >
                              Assigned ({leadCounts.assigned})
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/newCom/Followup"
                              className={activeStatus === '/newCom/Followup' ? 'active' : ''}
                              onClick={() => handleStatusChange('Followup')}
                            >
                              Followup ({leadCounts.followup})
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/newCom/Quotations"
                              className={activeStatus === '/newCom/Quotations' ? 'active' : ''}
                              onClick={() => handleStatusChange('Quotations')}
                            >
                              Quotations ({leadCounts.quotations})
                            </Link>

                          </li>
                          <li>
                            <Link
                              to="/newCom/Pre-proforma"
                              className={activeStatus === '/newCom/Pre-proforma' ? 'active' : ''}
                              onClick={() => handleStatusChange('Pre-proforma')}
                            >
                              preProforma ({leadCounts.preProforma})
                            </Link>
                          </li>

                          <li>

                            <Link to="/newCom/Convertedtosale"
                              className={activeStatus === '/newCom/Convertedtosale' ? 'active' : ''}
                              onClick={() => handleStatusChange('Convertedtosale')}>

                              Convertedtosale ({leadCounts.convertToSale})

                            </Link>

                          </li>

                          <li>
                            <Link
                              to="/newCom/LOST"
                              className={activeStatus === '/newCom/LOST' ? 'active' : ''}
                              onClick={() => handleStatusChange('LOST')}
                            >
                              LOST  ({leadCounts.lost})
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
       
      </div >
    </>

  )
}

export default NewLeads