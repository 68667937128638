import React, { useState, useEffect } from 'react'
import Content from '../Components/Content'
import Employee from '../Employee'
import MainPage3 from './MainPage'
import DropdownMenu from '../Components/DropdownMenu'
import Prince from './Prince'
import MainPag from './Date'
import Content2 from './Content2'
import Navbar from '../Pages/Navbar'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../assets/img/brand/logo.png'
function Master() {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [action, setAction] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [genderFilter, setGenderFilter] = useState('');
    const [showModal, setShowModal] = useState(false);

    const navigation = useNavigate();
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_URL;
    console.log(apiUrl);

    const handleEyeIconClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const loadcontent = (id) => {
        navigation(`/EditMaster/${id}`);
    };
    const deletecontent = (id) => {
        if (window.confirm("Do you want to delete")) {
            try {
                const Token = localStorage.getItem('Token');

                fetch(`${apiUrl}/master/deleteMaster/` + id, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${Token}`

                    }
                })
                    .then((res) => {
                        if (res.ok) {
                            alert('Record Deleted');
                            window.location.reload(false);
                        } else {
                            throw new Error('Failed to delete');
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        const Token = localStorage.getItem("Token");


        fetch(`${apiUrl}/master/getAllMaster`, {
            headers: {
                'Authorization': `Bearer ${Token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    if (Array.isArray(data.data)) {
                        setUsers(data.data);
                    } else {
                        console.error('API response does not contain users array:', data);
                    }
                } else {
                    console.error('API request was not successful:', data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    console.warn(users);

    const filteredUsers = users.filter((user) => {
        return (
            (user.id.toString().includes(search) || user.name.includes(search)) 
            
        );
    });

    useEffect(() => {
        const token = localStorage.getItem('Token');

        if (!token) {
            console.log(token);
            navigate('/');
        }
    }, [navigate]);
    return (
        <>
            {/* Main Header*/}
            <div className="page mb-1">

                <Navbar />
                <Prince />

                <div className="main-content  pt-0">
                    <div className="main-container container-fluid">
                        <div className="inner-body">
                            {/* Page Header */}
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">Master List</h2>

                                </div>
                                <div className="d-flex">
                                    <div className="justify-content-center">


                                        <Link to="/AddMaster" className="btn btn-primary my-2 btn-icon-text" type="button">
                                            {" "}
                                            <i className="fe fe-plus me-2" /> Add Master
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* End Page Header */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body py-3">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input
                                                        type="search"
                                                        className="form-control form-control"
                                                        placeholder="Search..."
                                                        aria-controls="example1"
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                    />
                                                </div>





                                            </div>{" "}
                                        </div>
                                    </div>
                                </div>{" "}
                            </div>
                            {/* Row */}
                            <div className="row row-sm">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered text-nowrap mb-0">

                                                    <thead>
                                                        <tr>

                                                            <th style={{ textAlign: 'center' }}>ID</th>
                                                            <th className='col-9' style={{ textAlign: 'center' }}>Name</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredUsers.map((user) => (
                                                            <tr key={user.id}>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    {user.id}
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>

                                                                    {user.name}
                                                                    <br />

                                                                    {/* Include your modal trigger button here */}
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => loadcontent(user.id)} className="btn ripple btn-info btn-xs w-100 my-1"
                                                                        style={{ backgroundColor: 'blue' }}>
                                                                        Edit Master
                                                                    </button>
                                                                    <br />

                                                                </td>

                                                            </tr>
                                                        ))}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Row */}
                        </div>
                    </div>
                </div>
                {/* End Main Content*/}
                {/* Main Footer*/}
                <div className="main-footer text-center">
                    <div className="container">
                        <div className="row row-sm">
                            <div className="col-md-12">
                                <span>
                                    Copyright © 2023 <a href="javascript:void(0)">PWIP</a>. Designed
                                    by <a href="http://webkype.com/">Webkype.com</a> All rights
                                    reserved.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/*End Footer*/}
            </div>



            <a href="#top" id="back-to-top">
                <i className="fe fe-arrow-up" />
            </a>
        </>

    )
}


export default Master