import React, { useState, useEffect } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import NewLeads from "../Pages/Sales CRM/NewLeads";
// import Img from '../assets/pngtree-profile-line-black-icon-png-image_691051.jpg'
import Logo from '../assets/img/brand/logo.png';

function NewCom() {

  const [allLeads, setAllLeads] = useState([])
  const [loaded, setLoaded] = useState([]);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [options, setOptions] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [search, setSearch] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [sale, setSale] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [genderOptions6, setGenderOptions6] = useState([]);
  const [genderOptions5, setGenderOptions5] = useState([]);
  const [errors, setErrors] = useState({});
  const [leadId, setLeadId] = useState();
  const [disableInput, setDisableInput] = useState(true);
  const initialFormData = {
    fullName: '',
    email: '',
    mobileNo: '',
    companyName: '',
    companyEmail: '',
    companyPhone: '',
    companyWebsite: '',
    category: '',
    quality: '',
    quantity: '',
    dateOfDelivery: '',
    shipFrom: '',
    shipTo: '',
    rate: '',
  }

  const [formData, setFormData] = useState(initialFormData);



  const { status } = useParams();
  const apiUrl = process.env.REACT_APP_URL;



  const Token = localStorage.getItem('Token');
  const currentDate = new Date().toISOString().split('T')[0];

  const navigate = useNavigate()

  const handleOpenModal5 = () => {
    setIsModalOpen5(true);
    document.body.classList.add('modal-open');
  };

  const handleCloseModal5 = () => {
    setIsModalOpen5(false);
    document.body.classList.remove('modal-open');
  };

  const handleInputChange = (e) => {
    if (!disableInput) {
      setFormData({ ...formData, mobileNo: e.target.value });
    }
  };
  useEffect(() => {
    const Token = localStorage.getItem('Token');


    fetch(`${apiUrl}/employee/allEmpDesig`, {
      headers: {
        'Authorization': `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setOptions(data.data);

        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);


  //MASTER
  useEffect(() => {
    const Token = localStorage.getItem('Token');


    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions5(data.data);
        } else {

        }
      })
      .catch(error => {

      });
  }, []);


  useEffect(() => {
    const Token = localStorage.getItem('Token');


    fetch(`${apiUrl}/master/getAllMasterData/9`, {
      headers: {
        Authorization: `Bearer ${Token}`

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions6(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  //Lost
  const handleSubmit11 = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/lead/lost/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Token}` // Add the token to the Authorization header
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);
      alert("LOST successfully...");
      setRefresh(!refresh);

    } catch (error) {
      console.error('There was an error with the fetch operation:', error);
    }
  };

  //sale
  const handleSubmit10 = async (id) => {
    try {

      const response = await fetch(`${apiUrl}/lead/convertToSale/${id}`, {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': `Bearer ${Token}`

        },

        body: JSON.stringify({

        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
      alert("Convert To Sale successfully...");
      setRefresh(!refresh);


    } catch (error) {

      console.error('There was an error with the fetch operation:', error);
    }
  };

  const loadcontent = (id) => {
    console.log(id)
      ; // Check the value of id
    navigate(`/DatelsLeads/${id}`);
  };


  //assignedLead
  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const Token = localStorage.getItem('Token');

    console.log('Token:', Token);
    setSale(selectedId); // Use setSale to update the state
    console.log('Sale:', selectedId);

    window.Token = Token;
  };

  const handleSubmit12 = (id) => {
    const Token = window.Token;

    fetch(`${apiUrl}/lead/assignedLead/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Token}`,
      },
      body: JSON.stringify({ id: sale }),
    })
      .then((response) => {
        console.log('Response status:', response.status);

        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then((data) => {
        console.log('Response from API:', data);
        handleCloseModal5();
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error.message);

      });
  };


// All leads fetch

  useEffect(() => {
    const fetchAllLead = () => {
      fetch(`${apiUrl}/lead/getAllLead`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${Token}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'success') {

            if (Array.isArray(data.data)) {

              let filtLeads = data.data.filter(item => item.status === status);

              // console.log(filtLeads);
              setAllLeads(filtLeads);
             

            } else {
              console.error('API response does not contain users array:', data);
            }
          } else {
            console.error('API request was not successful:', data);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });


    };

    fetchAllLead();
  }, [allLeads]);



  const filteredUsers = allLeads.filter((user) => {
    return (
      (user.id.toString().includes(search) || user.fullName.includes(search)) || user.email.includes(search)) || user.mobileNo.includes(search)


  });

// open popup 
  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove('modal-open');
  };

// close popup
  const handleOpenModal = (id) => {
    if (id) {
      setLeadId(id)
      setIsModalOpen(true);
      document.body.classList.add('modal-open');
    }

  };

  //fatch edit lead

  const fetchUser = async () => {
    try {
      const url = `${apiUrl}/lead/getLeadById/${leadId}`;

      let result = await fetch(url, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      });

      result = await result.json();
      const { data } = result;
      // console.log('Fetched Data:', data);
      const trimmedData = Object.keys(data).reduce((acc, key) => {
        if (typeof data[key] === 'string') {
          acc[key] = data[key].trim();
        } else {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      setFormData((prevFormData) => ({
        ...prevFormData,
        fullName: trimmedData.fullName,
        email: trimmedData.email,
        mobileNo: trimmedData.mobileNo,
        companyName: trimmedData.companyName,
        companyEmail: trimmedData.companyEmail,
        companyPhone: trimmedData.companyPhone,
        companyWebsite: trimmedData.companyWebsite,
        category: trimmedData.category,
        quality: trimmedData.quality,
        quantity: trimmedData.quantity,
        dateOfDelivery: trimmedData.dateOfDelivery,
        shipFrom: trimmedData.shipFrom,
        shipTo: trimmedData.shipTo,
        rate: trimmedData.rate,
      }));

      // Update the state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchUser();

  }, [leadId]);

// leads update

const handleUpdate = async () => {
  try {
    const formDataToSend = new FormData();

    for (const key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }

    const Token = localStorage.getItem('Token');
    const apiUrl = process.env.REACT_APP_URL;

    const response = await fetch(`${apiUrl}/lead/updateLead/${leadId}`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${Token}`,
      },
      body: formDataToSend,
    });

    if (!response.ok) {
      const responseData = await response.json();

      if (responseData.status === 'error' && responseData.message) {
        alert(responseData.message);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    }

    const data = await response.json();
    console.log('Form submitted successfully!', data);

    
    handleCloseModal();
    setRefresh(!refresh);
   

    setFormData(initialFormData);
 
   
  } catch (error) {
    console.error('Error:', error);
    // Handle errors, e.g., toast.error('An error occurred');
  } finally {
    // Assuming this block should always execute regardless of success or failure.
    console.log('Form Update successfully!');
   }
};

  // form validation start 
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const newErrors = { ...errors };

    if (!value.trim()) {

    } else if (
      name === 'fullName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[name] = `Please enter a valid Full Name with only alphabetical characters`;
    } else if (
      name === 'companyName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[name] = `Please enter a valid Company Name with only alphabetical characters`;
    } else if (
      name === 'email' && (!value.trim() || !emailRegex.test(value))
    ) {
      newErrors[name] = 'Please enter a valid email address';
    }
    else if (
      name === 'companyEmail' && (!value.trim() || !emailRegex.test(value))
    ) {
      newErrors[name] = 'Please enter a valid Company Email address';
    } else if (
      name === 'mobileNo' && (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid 10-digit mobile number';
    }
    else if (
      name === 'companyPhone' && (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid 10-digit mobile number';
    }


    else if (
      name === 'perHourCharges' && !/^\d{8}$/.test(value)
    ) {
      newErrors[name] = 'Please enter a valid 8-digit perHourCharges';
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `Full Name is required *`;
    } else if (name === 'fullName' && !/\s/.test(value.trim())) {
      // Check if there is at least one space after the last name

    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const handleInputBlur2 = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `Email is required *`;
    } else if (name === 'email' && !/\s/.test(value.trim())) {
      // Check if there is at least one space after the last name

    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };
  const handleInputBlur3 = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `Mobile Number is required *`;
    } else if (name === 'mobileNo' && !/\s/.test(value.trim())) {
      // Check if there is at least one space after the last name

    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

 // form validation end

  useEffect(() => {
    const token = localStorage.getItem('Token');

    if (!token) {
      console.log(token);
      navigate('/');
    }
  }, [navigate]);



  return (
    <>
      <div className="page ">
        < NewLeads />
      <div className="tab-pane actve row" style={{ marginTop: "220px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card custom-card">
                <div className="card-body py-3">
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search..."
                        aria-controls="example1"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive" style={{ backgroundColor: 'white' }}>

          <table className="table table-striped table-bordered text-nowrap mb-0">
            <thead>
              <tr>
                <th>
                  <label className="ckbox">
                    <input type="checkbox" defaultValue={5} />
                    <span />
                  </label>
                </th>
                <th>ID</th>
                <th>Photo</th>
                <th>Contact</th>
                <th>Company</th>
                <th>Requirement</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (

                <tr key={user.id}>
                  <td>
                    <label className="ckbox">
                      <input type="checkbox" defaultValue={5} />
                      <span />
                    </label>
                  </td>
                  <td>
                    <p className="mb-0">
                      Source : IVR Call
                      <br />
                      LLD-{user.id}

                    </p>
                  </td>
                  <td>
                    <img
                      alt="avatar"
                      className="rounded-circle me-3"
                      src={Logo}
                      style={{ width: 60 }}
                    />
                  </td>
                  <td>
                    <p className="mb-0">
                      {user.fullName}
                      <br />
                      Email: {user.email}
                      <br />
                      Phone: +91 {user.mobileNo}
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      Company Name: {user.companyName}

                      <br />
                      Company Email: {user.companyEmail}
                      <br />
                      Phone: +91 {user.companyPhone}
                      <br />
                      Company Website: {user.companyWebsite}
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">

                      Quality: {user.quality}
                      <br />
                      Quantity: {user.quantity}
                      <br />

                      Date of delivery : {user.dateOfDelivery}
                      <br />
                      Port of Loading : {user.shipFrom}
                      <br />
                      Port of Destination : {user.shipTo}
                      <br />
                      Rate: {user.rate}
                    </p>
                  </td>
                  <td>
                    <a
                      className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                      onClick={() => loadcontent(user.id)}
                    >
                      Details
                    </a>
                    <br />



                    <a
                      href="#"
                      className="btn ripple btn-success btn-xs w-100 mb-1"
                      onClick={() => {
                        console.log("Clicking with id:", user.id);
                        handleOpenModal(user.id);
                      }}

                    >
                      Edit Lead
                    </a>

                    <div
                      className={`modal ${isModalOpen ? 'show' : ''}`}
                      style={{ display: isModalOpen ? 'block' : 'none' }}
                      tabIndex="-1"
                      role="dialog"
                    >
                      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Update Lead</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={handleCloseModal}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>

                          <div className="modal-body" style={{ width: '100%' }} >
                            <div className="row row-sm-1">

                              <div className="col-lg-4">
                                <label className="form-label">
                                  Full Name: <span className="tx-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="fullName"
                                  value={formData.fullName}
                                  placeholder="Enter firstname"
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleInputBlur}
                                />
                                {errors.fullName && <p style={{ color: 'red' }}>{errors.fullName}</p>}
                              </div>
                              <div className="col-lg-4 form-group">
                                <label className="form-label">
                                  Email ID: <span className="tx-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="email"
                                  value={formData.email}

                                  onChange={handleChange}
                                  placeholder="Enter email"
                                  required=""
                                  type="text"
                                  onBlur={handleInputBlur2}
                                />
                                {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                              </div>
                              <div className="col-lg-4 form-group">
                                <label className="form-label">
                                  Mobile No: <span className="tx-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="mobileNo"
                                  value={formData.mobileNo}
                                  placeholder="Enter mobile number"
                                  required=""
                                  type="text"
                                  disabled={disableInput}

                                  onChange={handleChange}
                                  onBlur={handleInputBlur3}
                                />
                                {errors.mobileNo && <p style={{ color: 'red' }}>{errors.mobileNo}</p>}
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Company Name</label>
                                <input
                                  className="form-control"
                                  name="companyName"
                                  required=""
                                  value={formData.companyName}
                                  type="text"
                                  onChange={handleChange}

                                />
                                {errors.companyName && <p style={{ color: 'red' }}>{errors.companyName}</p>}
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Company Email</label>
                                <input
                                  className="form-control"
                                  name="companyEmail"
                                  value={formData.companyEmail}
                                  required=""
                                  type="text"
                                  onChange={handleChange}

                                />

                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Company Phone</label>
                                <input
                                  className="form-control"
                                  name="companyPhone"

                                  required=""
                                  type="text"
                                  value={formData.companyPhone}
                                  onChange={handleChange}

                                />
                                {errors.companyPhone && <p style={{ color: 'red' }}>{errors.companyPhone}</p>}
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Company Website</label>
                                <input
                                  className="form-control"
                                  name="companyWebsite"
                                  required=""
                                  type="text"
                                  value={formData.companyWebsite}
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="col-lg-12">
                                <hr className="my-2" />
                              </div>
                            </div>
                            <div className="row row-sm">
                              <div className="col-lg-12">
                                <h6 className="main-content-label mb-3 mt-2">Requirement</h6>
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">Quality</label>
                                <select
                                  className="form-control select2"
                                  name="quality"
                                  value={formData.quality}
                                  onChange={handleChange}
                                >
                                  <option>Select</option>
                                  {genderOptions6.map((option, index) => (
                                    <option key={option.id} value={option.name}>{option.name}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label className="form-label">Quantity (in metric tons)</label>
                                  <input
                                    className="form-control"
                                    name="quantity"
                                    required=""
                                    type="text"
                                    value={formData.quantity}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label className="form-label">Date of delivery</label>
                                  <input
                                    className="form-control"
                                    name="dateOfDelivery"
                                    required=""
                                    type="date"
                                    value={formData.dateOfDelivery}
                                    onChange={handleChange}
                                    min={currentDate}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <label className="form-label">Ship From</label>
                                <div className="input-group mb-3">
                                  <span className="input-group-text border-end-0" id="basic-addon1">
                                    <i className="fa fa-map-marker-alt" />
                                  </span>
                                  <select
                                    className="form-control select2"
                                    name="shipFrom"
                                    value={formData.shipFrom}
                                    onChange={handleChange}
                                  >
                                    <option>Select</option>
                                    {genderOptions5.map((option, index) => (
                                      <option key={option.id} value={option.name}>{option.name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <label className="form-label">Ship To</label>
                                <div className="input-group mb-3">
                                  <span className="input-group-text border-end-0" id="basic-addon2">
                                    <i className="fa fa-map-marker-alt" />
                                  </span>
                                  <select
                                    className="form-control select2"
                                    name="shipTo"
                                    value={formData.shipTo}
                                    onChange={handleChange}
                                  >
                                    <option>Select</option>
                                    {genderOptions5.map((option, index) => (
                                      <option key={option.id} value={option.name}>{option.name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label className="form-label">Rate (in USD)</label>
                                  <input
                                    className="form-control"
                                    name="rate"
                                    required=""
                                    type="text"
                                    value={formData.rate}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <button className="btn ripple btn-primary" onClick={() => handleUpdate(user.id)} type="button">
                                  Update
                                </button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <br />
                  
                    <a
                      className="btn ripple btn-success btn-xs w-100 mb-1 "
                      data-bs-target="#modaldemo-quotation-form"
                      data-bs-toggle="modal"
                      onClick={handleOpenModal5}
                    >
                      Assigned
                    </a>


                    <div
                      className={`modal fade ${isModalOpen5 ? 'show d-block' : ''}`}
                      id="modaldemo-callback-form"
                      tabIndex="-1"
                      style={{ display: isModalOpen5 ? 'block' : 'none' }}
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Assigned</h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={handleCloseModal5}
                              aria-lab
                              el="Close"
                            ></button>

                          </div>
                          <div className="modal-body">
                            <form>
                              <div className="row row-sm">
                                <div className="col-sm-4 form-group">
                                  <label className="form-label">Assigned Name</label>
                                  <select
                                    className="form-control select2"
                                    onChange={(event) => handleSelectChange(event)}
                                  >
                                    <option>Select</option>
                                    {options.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </form>

                          </div>
                          <div className="modal-footer">
                            <button className="btn ripple btn-primary" onClick={() => handleSubmit12(user.id)} type="button">
                              Submit
                            </button>

                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                    <a

                      className="btn ripple btn-success btn-xs w-100 mb-1"

                      onClick={() => handleSubmit11(user.id)}
                    >
                      Lost
                    </a>
                    <br />

                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
        <div className="main-footer text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <span>
                  Copyright © 2024 <a href="">PWIP</a>. Designed
                  by <a href="http://webkype.com/">Webkype.com</a> All rights
                  reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>

  )
}

export default NewCom