import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'react-toastify/dist/ReactToastify.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from '../Pages/Navbar';
import Prince from './Prince'



const Content2 = () => {

  const { empid } = useParams();
  const condition = true;
  const navigate = useNavigate();
  const initialFormData = {

    profilePic: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    mobileNumber: '',
    gender: '',
    dob: '',
    currentAddressCountry: '',
    currentAddressState: '',
    currentAddressCity: '',
    currentAddressArea: '',
    currentAddressLane: '',
    currentAddressPinCode: '',
    sameAsCurrentAddress: false,
    permanentAddressCountry: '',
    permanentAddressState: '',
    permanentAddressCity: '',
    permanentAddressArea: '',
    permanentAddressLane: '',
    permanentAddressPinCode: '',
    companyName: '',
    department: '',
    designation: '',
    dateOfJoin: "",
    reportingBossA: '',
    reportingBossB: '',
    permissions: '',
    adharNumber: '',
    panImage: '',
    userType: '',
    panNumber: '',
    accountNumber: '',
    accountName: '',
    adharImage: null,
    drivingLicenseImage: null,
    offerLetterUpload: null,
    bankName: '',
    ifsc: '',
    offeredPackage: '',
    offeredCTC: '',
    noticePeriod: '',
    workingShift: '',
    perHourCharges: '',
    perCasesCharges: '',
    perMonthCharges: '',
    consultingFees: '',

    // Add other fields as needed
  };

  const [formData, setFormData] = useState(initialFormData);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryId, setCountryId] = useState('');
  const [countrId, setCountrId] = useState('');
  const [stateId, setStateId] = useState('');
  const [statId, setStatId] = useState('');
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions8, setCountryOptions8] = useState([]);
  const [cityOptions8, setCityOptions8] = useState([]);
  const [countryId8, setCountryId8] = useState('');
  const [stateId8, setStateId8] = useState('');
  const [stateOptions8, setStateOptions8] = useState([]);
  const [genderOption, setGenderOption] = useState([]);
  const [genderOptions2, setGenderOptions2] = useState([]);
  const [genderOptions3, setGenderOptions3] = useState([]);
  const [options, setOptions] = useState([]);
  const [optio, setOptio] = useState([]);
  const [genderOptions4, setGenderOptions4] = useState([]);
  const [genderOptions5, setGenderOptions5] = useState([]);
  const [profilePic, setProfilePic] = useState(null);
  const [disableInput, setDisableInput] = useState(true);
  const [disableInput2, setDisableInput2] = useState(true);
  const [errors, setErrors] = useState({});
  const apiUrl = process.env.REACT_APP_URL;


  const getCurrentDate = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  //Gender
  useEffect(() => {
    const Token = localStorage.getItem('Token');
    fetch(`${apiUrl}/master/getAllMasterData/14`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOption(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  const handleInputChange = (e) => {
    if (!disableInput) {
      setFormData({ ...formData, mobileNumber: e.target.value });
    }
  };
  const handleInputChange2 = (e) => {
    if (!disableInput2) {
      setFormData({ ...formData, email: e.target.value });
    }
  };

  const handlePrint = () => {
    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Get the content to be printed (in this case, the entire component)
    const content = document.getElementById('printableContent');

    // Use html2canvas to capture the content as an image
    html2canvas(content).then((canvas) => {
      // Convert the canvas image to a data URL
      const imgData = canvas.toDataURL('image/png');

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust dimensions as needed

      // Save or open the PDF
      pdf.save('form_data.pdf');
      // Alternatively, you can open the print dialog
      // pdf.autoPrint();
      // window.open(pdf.output('bloburl'), '_blank');
    });
  };

  let oldSelectedState = [];
  let oldSelectedCity = [];
  let oldSelectedState2 = [];
  let oldSelectedCity2 = [];
  useEffect(() => {
    const fetchUser = async () => {
      const Token = localStorage.getItem('Token');
      try {
        const url = `${apiUrl}/employee/employee/${empid}`;
        let result = await fetch(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        });

        result = await result.json();
        const { data } = result;
        // console.log('Fetched Data:', data);
        const trimmedData = Object.keys(data).reduce((acc, key) => {
          if (typeof data[key] === 'string') {
            acc[key] = data[key].trim();
          } else {
            acc[key] = data[key];
          }
          return acc;
        }, {});
        // console.log('Fetched Data:', trimmedData);
        const oldSelectedStateResponse = await fetch(`${apiUrl}/employee/allStates/${trimmedData.currentAddressCountry}`);
        oldSelectedState = await oldSelectedStateResponse.json();
        const oldSelectedCityResponse = await fetch(`${apiUrl}/employee/allcities/${trimmedData.currentAddressState}`);
        oldSelectedCity = await oldSelectedCityResponse.json();
        // PERMANENT
        const oldSelectedStateResponse2 = await fetch(`${apiUrl}/employee/allStates/${trimmedData.permanentAddressCountry}`);
        oldSelectedState2 = await oldSelectedStateResponse2.json();
        const oldSelectedCityResponse2 = await fetch(`${apiUrl}/employee/allcities/${trimmedData.permanentAddressState}`);
        oldSelectedCity2 = await oldSelectedCityResponse2.json();
        // console.log(oldSelectedState2, oldSelectedCity2, '<------');
        // console.log(oldSelectedState2.data.find(oldState2 => oldState2.id === +trimmedData.permanentAddressCountry));
        // console.log(oldSelectedCity.data.find(oldCity2 => oldCity2.id === +trimmedData.permanentAddressCountry));


        setCityOptions(oldSelectedCity.data);
        setStateOptions(oldSelectedState.data);

        // PERMANENT
        setCityOptions8(oldSelectedCity2.data);
        setStateOptions8(oldSelectedState2.data);

        setFormData((prevFormData) => ({
          ...prevFormData,

          firstName: trimmedData.firstName,
          lastName: trimmedData.lastName,
          email: trimmedData.email,
          password: trimmedData.password,
          mobileNumber: trimmedData.mobileNumber,
          gender: trimmedData.gender,
          dob: trimmedData.dob,
          currentAddressCountry: trimmedData.currentAddressCountry,
          currentAddressState: trimmedData.currentAddressState,
          currentAddressCity: data.currentAddressCity,
          currentAddressArea: trimmedData.currentAddressArea,
          currentAddressLane: trimmedData.currentAddressLane,
          currentAddressPinCode: trimmedData.currentAddressPinCode,
          // sameAsCurrentAddress: false,
          permanentAddressCountry: trimmedData.permanentAddressCountry,
          permanentAddressState: data.permanentAddressState,
          permanentAddressCity: data.permanentAddressCity,
          permanentAddressArea: trimmedData.permanentAddressArea,
          permanentAddressLane: trimmedData.permanentAddressLane,
          permanentAddressPinCode: trimmedData.permanentAddressPinCode,
          companyName: trimmedData.companyName,
          department: trimmedData.department,
          userType: trimmedData.userType,
          designation: trimmedData.designation,
          dateOfJoin: trimmedData.dateOfJoin,
          reportingBossA: trimmedData.reportingBossA,
          reportingBossB: trimmedData.reportingBossB,
          permissions: trimmedData.permissions,
          adharNumber: trimmedData.adharNumber,
          panNumber: trimmedData.panNumber,
          accountNumber: trimmedData.accountNumber,
          accountName: trimmedData.accountName,
          bankName: trimmedData.bankName,
          ifsc: trimmedData.ifsc,
          offeredPackage: trimmedData.offeredPackage,
          offeredCTC: trimmedData.offeredCTC,
          noticePeriod: trimmedData.noticePeriod,
          workingShift: trimmedData.workingShift,
          perHourCharges: trimmedData.perHourCharges,
          perCasesCharges: trimmedData.perCasesCharges,
          perMonthCharges: trimmedData.perMonthCharges,
          consultingFees: trimmedData.consultingFees,


        }));

        // Update the state with fetched data

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUser();
  }, []);

  const handleFileChange = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith('image/')) {
        const imageUrl = URL.createObjectURL(profilePicFile); // Convert file to URL
        setProfilePic(imageUrl); // Set URL as profilePic
        setFormData((prevData) => ({
          ...prevData,
          profilePic: profilePicFile,
        }));
      } else if (profilePicFile.type === 'application/pdf') {
        // Assuming other file inputs exist for these file types
        setFormData((prevData) => ({
          ...prevData,
          adharImage: profilePicFile,
          drivingLicenseImage: profilePicFile,
          drivingLicence: profilePicFile,
          offerLetterUpload: profilePicFile,
        }));


      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };
  const handleFileChange2 = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith('image/')) {
        const imageUrl = URL.createObjectURL(profilePicFile); // Convert file to URL
        setProfilePic(imageUrl); // Set URL as profilePic
        setFormData((prevData) => ({
          ...prevData,
          profilePic: profilePicFile,
        }));
      } else if (profilePicFile.type === 'application/pdf') {
        // Assuming other file inputs exist for these file types
        setFormData((prevData) => ({
          ...prevData,
          
          panImage: profilePicFile,
         
        }));


      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };

  const handleFileChange3 = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith('image/')) {
        const imageUrl = URL.createObjectURL(profilePicFile); // Convert file to URL
        setProfilePic(imageUrl); // Set URL as profilePic
        setFormData((prevData) => ({
          ...prevData,
          profilePic: profilePicFile,
        }));
      } else if (profilePicFile.type === 'application/pdf') {
        // Assuming other file inputs exist for these file types
        setFormData((prevData) => ({
          ...prevData,
          
          drivingLicenseImage: profilePicFile,
         
        }));


      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };
  const handleFileChange4 = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith('image/')) {
        const imageUrl = URL.createObjectURL(profilePicFile); // Convert file to URL
        setProfilePic(imageUrl); // Set URL as profilePic
        setFormData((prevData) => ({
          ...prevData,
          profilePic: profilePicFile,
        }));
      } else if (profilePicFile.type === 'application/pdf') {
        // Assuming other file inputs exist for these file types
        setFormData((prevData) => ({
          ...prevData,
          
          offerLetterUpload: profilePicFile,
         
        }));


      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };

  useEffect(() => {
    // console.log('Updated FormData:', formData);
    // Any other logic that depends on the updated state
  }, [formData]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const handleInputChange13 = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

// validation form
    const newErrors = { ...errors };
    if (!value.trim()) {

    } else if (
      name === 'firstName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[name] = `Please enter a valid First Name with only alphabetical characters`;
     
    } else if (
      name === 'lastName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[name] = `Please enter a valid Last Name with only alphabetical characters`;
    } else if (
      name === 'email' && (!value.trim() || !emailRegex.test(value))
    ) {
      newErrors[name] = 'Please enter a valid email address';
    } else if (
      name === 'mobileNumber' && (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid 10-digit mobile number';
    } else if (
      name === 'adharNumber' && (!value.trim() || !/^\d{12}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid 12-digit Aadhaar number';
    } else if (
      name === 'panNumber' && (!value.trim() || !/^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid PAN number in the format ABCDE1234F';


    } else if (name === 'accountNumber' && (value.length < 0 || value.length > 16 || !/^[A-Z0-9]{0,16}$/i.test(value))) {
      newErrors[name] = 'Please enter a valid account number with 0 to 16 characters, including numbers and capital letters';

    } else if (
      name === 'accountName' && !/^[A-Za-z\s]+$/.test(value)
    ) {
      newErrors[name] = 'Please enter a valid account name with only alphabetical characters';
    } else if (
      name === 'bankName' && !/^[A-Za-z\s]+$/.test(value)
    ) {
      newErrors[name] = 'Please enter a valid bank name with only alphabetical characters';
    } else if (name === 'ifsc' && (value.length < 0 || value.length > 11 || !/^[A-Z0-9]{0,11}$/.test(value))) {
      newErrors[name] = 'Please enter a valid IFSC code with 0 to 11 characters, including numbers and capital letters';

    }

    else if (
      name === 'currentAddressPinCode' && !/^\d{6}$/.test(value)
    ) {
      newErrors[name] = 'Please enter a valid 6-digit pin code';
    }
    

    else if (name === 'offeredPackage') {
      const floatValue = parseFloat(value.replace(',', '.'));

      if (isNaN(floatValue) || !/^\d+(\.\d*)?$/.test(value)) {
        newErrors[name] = 'Please enter a valid numeric value for offeredPackage';
      }
    }


    else if (name === 'offeredCTC' && (value.length === 1 || value.length > 8 || !/^\d{1,8}$/.test(value))) {
      newErrors[name] = 'Please enter a valid offeredPackage between 1 to 8 digits';
    }


    else if (name === 'perMonthCharges' && (value.length === 1 || value.length > 8 || !/^\d{1,8}$/.test(value))) {
      newErrors[name] = 'Please enter a valid offeredPackage between 1 to 8 digits';

    } else {
      delete newErrors[name]; // Remove error if field is valid
    }

    setErrors(newErrors);
  };
  const handleInputBlur2 = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors[name] = `Last Name is required *`;
    } else if (name === 'lastName' && !/\s/.test(value.trim())) {


    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };
 
 
 
  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      
      newErrors[name] = `First Name is required *`;
    } else if (name === 'firstName' && !/\s/.test(value.trim())) {

    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };


// Hrms from update

 
  const handleUpdate = async (e) => {
    e.preventDefault();
    const Token = localStorage.getItem('Token');
    try {
      const formDataToSend = new FormData();

      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }

      const apiUrl = process.env.REACT_APP_URL;
      console.log(apiUrl);

      const response = await fetch(`${apiUrl}/employee/employeeUpdate/${empid}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      console.log(response);

      if (!response.ok) {
        const responseData = await response.json();

        if (responseData && responseData.status === 'error' && responseData.message) {
          alert(responseData.message);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      console.log('Form submitted successfully!', data);

      // toast.success('Form submitted successfully');
      navigate('/MainPage2');

      setFormData(initialFormData);
    } catch (error) {
      console.error('Error:', error);
      // toast.error('Please check your API!');
    }
  };

  //country api 
  useEffect(() => {
    fetch(`${apiUrl}/employee/allCountries`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCountryOptions(data.data);
        } else {
          console.error('API response is not in the expected format for countries.');
        }

      })
      .catch((error) => {
        console.error('Error fetching country data:', error);
      });
  }, []);


// state api
  const fetchStates = (countryId) => {
    fetch(`${apiUrl}/employee/allStates/${countryId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setStateOptions(data.data);
        } else {
          console.error('API response is not in the expected format for states.');
        }
      })
      .catch((error) => {
        console.error('Error fetching state data:', error);
      });
  };
// city api 
  const fetchCities = (stateId) => {
    fetch(`${apiUrl}/employee/allcities/${stateId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCityOptions(data.data);
        } else {
          console.error('API response is not in the expected format for cities.');
        }
      })
      .catch((error) => {
        console.error('Error fetching city data:', error);
      });
  };

  useEffect(() => {
    if (countryId) {

      fetchStates(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId) {

      fetchCities(stateId);
    }
  }, [stateId]);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountryId(selectedCountry);
    setFormData({
      ...formData,
      currentAddressCountry: selectedCountry,
    });
    setStateOptions([]); // Clear state options
    setCityOptions([]); // Clear city options
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setStateId(selectedState);
    setFormData({
      ...formData,
      currentAddressState: selectedState,
    });
    setCityOptions([]); // Clear city options
  };
  //per

  useEffect(() => {
    fetch(`${apiUrl}/employee/allCountries`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCountryOptions8(data.data);
        } else {
          console.error('API response is not in the expected format for countries.');
        }

      })
      .catch((error) => {
        console.error('Error fetching country data:', error);
      });
  }, []);



  const fetchState = (countrId) => {
    fetch(`${apiUrl}/employee/allStates/${countrId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setStateOptions8(data.data);
        } else {
          console.error('API response is not in the expected format for states.');
        }
      })
      .catch((error) => {
        console.error('Error fetching state data:', error);
      });
  };

  const fetchCitie = (statId) => {
    fetch(`${apiUrl}/employee/allcities/${statId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCityOptions8(data.data);
        } else {
          console.error('API response is not in the expected format for cities.');
        }
      })
      .catch((error) => {
        console.error('Error fetching city data:', error);
      });
  };

  useEffect(() => {
    if (countrId) {

      fetchState(countrId);
    }
  }, [countrId]);

  useEffect(() => {
    if (statId) {

      fetchCitie(statId);
    }
  }, [statId]);

  const handleCountryChang = (event) => {
    const selectedCountry = event.target.value;
    setCountrId(selectedCountry);
    setFormData({
      ...formData,
      permanentAddressCountry: selectedCountry,
    });
    setStateOptions8([]); // Clear state options
    setCityOptions8([]); // Clear city options
  };

  const handleStateChang = (event) => {
    const selectedState = event.target.value;
    setStatId(selectedState);
    setFormData({
      ...formData,
      permanentAddressState: selectedState,
    });
    setCityOptions8([]); // Clear city options
  };

  const handleInputChang = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const handleInputChan = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }


  //departments
  useEffect(() => {
    const Token = localStorage.getItem('Token');
    fetch(`${apiUrl}/master/getAllMasterData/7`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions2(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);


  // Designation
  useEffect(() => {
    const Token = localStorage.getItem('Token');
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions3(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);


  //Boss a

  useEffect(() => {
    const Token = localStorage.getItem('Token');

    fetch(`${apiUrl}/employee/allEmpDesig`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Token}`,
        'Content-Type': 'application/json',

      },
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  //Boss b
  useEffect(() => {
    const Token = localStorage.getItem('Token');


    fetch(`${apiUrl}/employee/allEmpDesig`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Token}`,
        'Content-Type': 'application/json',

      },
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setOptio(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);


  const getCurrentDate2 = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const Token = localStorage.getItem('Token');


    fetch(`${apiUrl}/master/getAllMasterData/12`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions4(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);


  //working shift
  useEffect(() => {
    const Token = localStorage.getItem('Token');

    fetch(`${apiUrl}/master/getAllMasterData/13`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions5(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  const copyCurrentAddress = () => {
    setFormData({
      ...formData,
      sameAsCurrentAddress: true,
      permanentAddressCountry: formData.currentAddressCountry,
      permanentAddressState: formData.currentAddressState,
      permanentAddressCity: formData.currentAddressCity,
      permanentAddressArea: formData.currentAddressArea,
      permanentAddressLane: formData.currentAddressLane,
      permanentAddressPinCode: formData.currentAddressPinCode,
    });
  };


  useEffect(() => {
    const token = localStorage.getItem('Token');
    if (!token) {
      console.log(token);
      navigate('/');
    }
  }, [navigate]);


  return (
    <>
      <div className="page">
        <Navbar />
        <Prince />
        {/* Main Content*/}
        <div id="printableContent" className="main-content pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">Update Employee</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">

                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary my-2 btn-icon-text"
                      onClick={handlePrint}
                    >
                      <i className="fe fe-download-cloud me-2" /> Print
                    </button>
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-4">PROFILE PHOTO</h6>
                      </div>

                      <div className="">
                        <div className="row row-sm">
                          <div className="col-sm-12 col-md-12">
                            <input
                              type="file"
                              className="dropify"
                              data-height={200}
                              accept="image/jpeg, image/png, image/jpg"
                              name="profilePic"
                              onChange={handleFileChange}
                            />

                            {profilePic && (
                              <img src={profilePic} alt="Selected File" style={{ maxWidth: '400px' }} />
                            )}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">BASIC INFO</h6>
                      </div>
                      <form action="form-validation.html" data-parsley-validate="">
                        <div className="">
                          <div className="row row-sm">
                            <div className="col-lg-6 form-group">
                              <label className="form-label">
                                Firstname: <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange13}
                                placeholder="Enter firstname"
                                type="text"
                                onBlur={handleInputBlur}
                              />
                              {errors.firstName && <p style={{ color: 'red' }} className="error-message">{errors.firstName}</p>}
                            </div>
                            <div className="col-lg-6 form-group">
                              <label className="form-label">
                                Lastname: <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange13}

                                placeholder="Enter lastname"
                                required=""
                                type="text"
                                onBlur={handleInputBlur2}

                              />
                              {errors.lastName && <p style={{ color: 'red' }}>{errors.lastName}</p>}
                            </div>
                            <div className="col-lg-6 form-group">
                              <label className="form-label">
                                Email ID: <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}

                                placeholder="Enter email"
                                required=""
                                type="text"
                                disabled={disableInput2}

                              />
                            </div>
                            <div className="col-lg-6 form-group">
                              <label className="form-label">
                                Mobile No: <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter mobile number"
                                required=""
                                type="text"
                                onChange={handleInputChange}
                                value={formData.mobileNumber}
                                disabled={disableInput}

                              />
                            </div>
                            <div className="col-lg-6">
                              <label className="form-label" >
                                Gender: <span className="tx-danger">*</span>
                              </label>
                              <select
                                className="form-control select2"

                                value={formData.gender}
                                onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                              >
                                <option>Select</option>
                                {genderOption.map((option, index) => (
                                  <option key={option.id} value={option.name}>{option.name}</option>
                                ))}
                              </select>

                            </div>
                            {/* col-4 */}
                            <div className="col-lg-6">
                              <label className="form-label">
                                Date of Birth: <span className="tx-danger">*</span>
                              </label>
                              <div className="input-group">

                                <input
                                  className="form-control select2"
                                  type="date"
                                  value={formData.dob}
                                  onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                                  max={getCurrentDate()}

                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">Current Address</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">Country</label>
                          <select
                            className="form-control select select2"
                            name="currentAddressCountry"
                            onChange={(e) => handleCountryChange(e)}
                          >
                            <option value="">Select a country</option>
                            {countryOptions.map((country) => (
                              <option selected={country.id === +formData.currentAddressCountry} key={country.id} value={(country.id)}>
                                {`${country.name}`}
                              </option>
                            ))}
                          </select>

                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">State</label>
                          <select
                            className="form-control select select2"
                            name="currentAddressState"

                            onChange={(e) => handleStateChange(e)}
                          >
                            <option value="">Select a State</option>
                            {stateOptions.map((state) => (
                              <option selected={state.id === +formData.currentAddressState} key={state.id} value={(state.id)}>
                                {`${state.name}`}
                              </option>
                            ))}
                          </select>

                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">City</label>
                          <select
                            className="form-control select select2"
                            name="currentAddressCity"
                            // value={formData.currentAddressCity}
                            onChange={handleInputChan}

                          >
                            <option value="">Select a city</option>
                            {cityOptions.map((city, index) => (
                              <option selected={city.id === +formData.currentAddressCity} key={city.id} value={(city.id)}>
                                {`${city.name}`}
                              </option>
                            ))}
                          </select>

                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Area</label>
                            <input className="form-control" required="" type="text"
                              value={formData.currentAddressArea}
                              onChange={(e) => setFormData({ ...formData, currentAddressArea: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Address lane</label>
                            <input className="form-control" required="" type="text"
                              value={formData.currentAddressLane}
                              onChange={(e) => setFormData({ ...formData, currentAddressLane: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Pin Code</label>
                            <input className="form-control" required="" type="text"
                              name='currentAddressPinCode'
                              value={formData.currentAddressPinCode}
                              onChange={handleInputChange13}
                            />
                            {errors.currentAddressPinCode && <p style={{ color: 'red' }}>{errors.currentAddressPinCode}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">
                          Permanent Address{" "}
                          <label className="ckbox" style={{ float: "right" }}>
                            <input
                              name="sameAsCurrentAddress"
                              type="checkbox"
                              checked={formData.sameAsCurrentAddress}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  copyCurrentAddress();
                                } else {
                                  setFormData({
                                    ...formData,
                                    sameAsCurrentAddress: false,
                                  });
                                }
                              }}
                            />
                            <span className="tx-12">Same as Current Address</span>
                          </label>
                        </h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">Country</label>
                          <select
                            className="form-control select select2"
                            name="permanentAddressCountry"

                            onChange={(e) => handleCountryChang(e)}
                            disabled={formData.sameAsCurrentAddress}
                          >
                            <option value="">Select a country</option>
                            {countryOptions8.map((country) => (
                              <option selected={country.id === +formData.permanentAddressCountry} key={country.id} value={(country.id)}>
                                {`${country.name}`}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">State</label>
                          <select
                            className="form-control"
                            name="permanentAddressState"
                            value={formData.permanentAddressState}
                            onChange={(e) => handleStateChang(e)}
                            disabled={formData.sameAsCurrentAddress}
                          >
                            <option value="">Select a State</option>
                            {stateOptions8.map((state) => (
                              <option selected={state.id === +formData.permanentAddressState} key={state.id} value={(state.id)}>
                                {`${state.name}`}
                              </option>
                            ))}
                          </select>

                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">City</label>
                          <select
                            className="form-control select select2"
                            name="permanentAddressCity"
                            onChange={handleInputChang}
                            disabled={formData.sameAsCurrentAddress}
                          >
                            <option value="">Select a city</option>
                            {cityOptions8.map((city) => (
                              <option selected={city.id === +formData.permanentAddressCity} key={city.id} value={(city.id)}>
                                {`${city.name}`}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Area</label>
                            <input className="form-control" required=""
                              value={formData.permanentAddressArea}
                              onChange={(e) => setFormData({ ...formData, permanentAddressArea: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Address lane</label>
                            <input className="form-control" required="" type="text"
                              value={formData.permanentAddressLane}
                              onChange={(e) => setFormData({ ...formData, permanentAddressLane: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Pin Code</label>
                            <input
                              className="form-control"
                              required=""
                              type="text"
                              value={formData.permanentAddressPinCode}
                              onChange={(e) => setFormData({ ...formData, permanentAddressPinCode: e.target.value })}


                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">ONBOARDING</h6>
                      </div>
                      <div className="row row-sm">

                        <div className="col-sm-4  form-group">
                          <label className="form-label">Department</label>

                          <select
                            className="form-control select2"
                            value={formData.department}
                            onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                          >
                            <option>Select</option>
                            {genderOptions2.map((option, index) => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>

                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Designation</label>
                          <select
                            className="form-control select2"
                            value={formData.designation}
                            onChange={(e) => setFormData({ ...formData, designation: e.target.value })}
                          >
                            <option>Select</option>
                            {genderOptions3.map((option, index) => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>

                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Types of user</label>
                          <select
                            className="form-control select2"
                            name="userType"
                            value={formData.userType}
                            onChange={(e) => setFormData({ ...formData, userType: e.target.value })}
                          >
                            <option>Select</option>
                            <option>User</option>
                            <option>Admin</option>
                          </select>

                        </div>

                        <div className="col-lg-4 form-group">
                          <label className="form-label">
                            Date of Joining: <span className="tx-danger">*</span>
                          </label>
                          <div className="input-group">

                            <input
                              className="form-control select2"
                              type="date"
                              value={formData.dateOfJoin}
                              onChange={(e) => setFormData({ ...formData, dateOfJoin: e.target.value })}

                              max={getCurrentDate2()}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Reporting Boss-A</label>
                          <select
                            className="form-control select2"
                            value={formData.reportingBossA}
                            onChange={(e) => setFormData({ ...formData, reportingBossA: e.target.value })}
                          >
                            <option>Select</option>
                            {options.map((option, index) => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Reporting Boss-B</label>
                          <select
                            className="form-control select2"

                            value={formData.reportingBossB}
                            onChange={(e) => setFormData({ ...formData, reportingBossB: e.target.value })}
                          >
                            <option>Select</option>
                            {optio.map((option, index) => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label  mb-3">KYC</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-3 ">
                          <div className="form-group">
                            <label className="form-label">Aadhaar Upload (Upload pdf)</label>
                            <input
                              className="form-control"
                              type="file"
                              name="adharImage"

                              accept="/pdf"
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label className="form-label">Aadhaar No</label>
                            <input className="form-control" required="" type="text"
                              name='adharNumber'
                              value={formData.adharNumber}
                              onChange={handleInputChange13}
                            />
                            {errors.adharNumber && <p style={{ color: 'red' }}>{errors.adharNumber}</p>}
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label className="form-label">PAN Upload (Upload pdf)</label>
                            <input
                              className="form-control"
                              type="file"
                              name="panImage"
                              onChange={handleFileChange2}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label className="form-label">PAN No</label>
                            <input className="form-control" required="" type="text"
                              name='panNumber'
                              value={formData.panNumber}
                              onChange={handleInputChange13}
                            />
                            {errors.panNumber && <p style={{ color: 'red' }}>{errors.panNumber}</p>}
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group mb-0">
                            <label className="form-label">Driving Licence (Upload pdf)</label>
                            <input
                              type="file"
                              className="dropify"
                              name="drivingLicenseImage"
                              onChange={handleFileChange3}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          {/* <div className="form-group mb-0">
                            <label className="form-label">Cheque</label>
                            <input className="form-control" required="" type="file" />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label  mb-3">Account Details</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-3 ">
                          <div className="form-group mb-0">
                            <label className="form-label">Account No.</label>
                            <input className="form-control" required="" type="text"
                              name='accountNumber'
                              value={formData.accountNumber}
                              onChange={handleInputChange13}
                            />
                            {errors.accountNumber && <p style={{ color: 'red' }}>{errors.accountNumber}</p>}
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group mb-0">
                            <label className="form-label">Account Name</label>
                            <input className="form-control" required="" type="text"
                              name='accountName'
                              value={formData.accountName}
                              onChange={handleInputChange13}
                            />
                            {errors.accountName && <p style={{ color: 'red' }}>{errors.accountName}</p>}
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group mb-0">
                            <label className="form-label">Bank Name</label>
                            <input className="form-control" required="" type="text"
                              name='bankName'
                              value={formData.bankName}
                              onChange={handleInputChange13}
                            />
                            {errors.bankName && <p style={{ color: 'red' }}>{errors.bankName}</p>}
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group mb-0">
                            <label className="form-label">IFSC</label>
                            <input className="form-control" required="" type="text"
                              value={formData.ifsc}
                              onChange={(e) => setFormData({ ...formData, ifsc: e.target.value })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">OFFER</h6>
                      </div>
                      <div className="row row-sm ">
                        <div className="col-sm-8">
                          <div className="row">
                            <div className="col-sm-6 form-group ">
                              <label className="form-label">Offered Package</label>
                              <input className="form-control" required="" type="text"
                                name='offeredPackage'
                                value={formData.offeredPackage}
                                onChange={handleInputChange13}
                              />
                              {errors.offeredPackage && <p style={{ color: 'red' }}>{errors.offeredPackage}</p>}
                            </div>
                            <div className="col-sm-6 form-group">
                              <label className="form-label">Offered CTC</label>
                              <input className="form-control" required="" type="text"
                                name='offeredCTC'
                                value={formData.offeredCTC}
                                onChange={handleInputChange13}
                              />
                              {errors.offeredCTC && <p style={{ color: 'red' }}>{errors.offeredCTC}</p>}
                            </div>
                            <div className="col-lg-6 form-group">
                              <p className="form-label">Notice Period</p>
                              <select
                                className="form-control select2"
                                name="noticePeriod"
                                value={formData.noticePeriod}
                                onChange={(e) => setFormData({ ...formData, noticePeriod: e.target.value })}
                              >
                                <option>Select</option>
                                {genderOptions4.map((option, index) => (
                                  <option key={option.id} value={option.name}>{option.name}</option>
                                ))}
                              </select>
                            </div>
                            {/* col-4 */}
                            <div className="col-lg-6 form-group">
                              <p className="form-label">Working Shift </p>
                              <select
                                className="form-control select2"
                                name="workingShift"
                                value={formData.workingShift}
                                onChange={(e) => setFormData({ ...formData, workingShift: e.target.value })}
                              >
                                <option>Select</option>
                                {genderOptions5.map((option, index) => (
                                  <option key={option.id} value={option.name}>{option.name}</option>
                                ))}
                              </select>

                            </div>
                            <div className="col-sm-6 form-group">
                              <label className="form-label">Per Month Salary</label>
                              <input className="form-control" required="" type="text"
                                name='perMonthCharges'
                                value={formData.perMonthCharges}
                                onChange={handleInputChange13}
                              />
                              {errors.perMonthCharges && <p style={{ color: 'red' }}>{errors.perMonthCharges}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Upload Offer Letter</label>
                          <input
                            type="file"
                            className="dropify"
                            name="offerLetterUpload"
                            onChange={handleFileChange4}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              <div className="row row-sm">
                <div className="col-12 mb-3">

                  <button className="btn btn-primary" onClick={handleUpdate}>Update</button>

                </div>

              </div>
            </div>
          </div>
        </div>
      

        {/* End Main Content*/}
        {/* Main Footer*/}
        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2023 <a href="javascript:void(0)">PWIP</a>. Designed
                  by <a href="http://webkype.com/">Webkype.com</a> All rights
                  reserved.
                </span>
              </div>
            </div>
          </div>
        </div>

      </div >

    </>

  )
}

export default Content2