import React, { useState, useEffect } from 'react';
import { Maximize, Minimize } from 'react-feather'; // Import Feather Icons
import screenfull from 'screenfull';
import { Link, useParams, useNavigate } from 'react-router-dom';

import FullScreenToggle from '../Components/FullScreenToggle';
import MainPage from '../Components/MainPage';
import Logo from '../assets/img/brand/logo.png';
import '../nabBar2.css'
import '../assets/plugins/pickr-master/themes/classic.min.css';
import '../assets/css/style.css';
import '../Components/UserProfileDropdown.css';


const Navbar = () => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const [employee, setEmployee] = useState({})
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        if (!isDropdownOpen) {
            setIsDropdownOpen(true);
        } else {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isDropdownOpen && !event.target.closest('.your-dropdown-class')) {
                // Close the dropdown only if it's open and the click is outside the dropdown
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isDropdownOpen]);

    // console.log(isDropdownOpen);

    function signOut() {

        navigate('/')
    }
    const apiUrl = process.env.REACT_APP_URL;

    useEffect(() => {
        async function getEmp() {

            const Token = localStorage.getItem("Token");
            let response = await fetch(`${apiUrl}/employee/employee`, {
                method: "POST",
                headers: {
                    Auth: `Bearer ${Token}`
                },
            })
            response = await response.json();

            if (response.status === "success") {
                setEmployee(response.data);
            }
        }
        getEmp();
    }, [])

    useEffect(() => {
        // Check if fullscreen is supported by the browser
        if (screenfull.isEnabled) {
            // Add a listener for fullscreen change event
            screenfull.on('change', () => {
                setIsFullscreen(screenfull.isFullscreen);
            });
        }
    }, []);

    const toggleFullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
        }
    };
    useEffect(() => {
        const token = localStorage.getItem('Token');

        if (!token) {
            console.log(token);
            navigate('/');
        }
    }, [navigate]);
    return (
        <div className="navbar-container">
            <div className="navbar-logo mb-1" style={{ marginLeft: '10px', marginRight: '0' }}>
                <Link to="/Firstpage">
                    <img src={Logo} alt="Logo" style={{ width: '70px', height: '50px', marginRight: '28px' }} />
                </Link>
            </div>

            <div className="navbar-main">
                <div className="icons-container">
                    <span className="dark-layout">
                        <i className="fe fe-sun header-icons"></i>
                    </span>
                    <span className="light-layout">
                        <i className="fe fe-moon header-icons"></i>
                    </span>
                </div>
                <a className="nav-link icon" href="" style={{ position: 'relative' }}>

                    <i className="fe fe-bell header-icons" />
                </a>
                <div className="dropdown">
                    <a className="nav-link icon full-screen-link" onClick={toggleFullScreen}>
                        {isFullscreen ? (
                            <Minimize className="fullscreen-button header-icons" />
                        ) : (
                            <Maximize className="fullscreen-button header-icons" />
                        )}
                    </a>
                </div>
                <div className="dropdown main-profile-menu" style={{ marginLeft: '8px' }}>
                    <button className="main-img-user" onClick={toggleDropdown}>
                        <img alt="avatar" src={employee.profilePic} />
                    </button>
                    <div className="dropdown-menu show-dropdown" style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                        {/* Dropdown content */}
                        <div className="header-navheading">
                            <h6 className="main-notification-title">{employee.firstName}</h6>
                            <p className="main-notification-text">Web Designer</p>
                        </div>
                        {/* Dropdown items */}
                        <div className="dropdown-item border-top">
                            <i className="fe fe-user" /> My Profile
                        </div>

                        <div className="dropdown-item" onClick={signOut}>
                            <i className="fe fe-power"></i> Sign Out
                        </div>
                    </div>
                </div>
            </div>



        </div>

    );
};

export default Navbar;
