import React, { useState, useEffect } from 'react'
import MainPage from '../../Components/MainPage'
import Prince from '../../Components/Prince'
import DropdownMenu from '../../Components/DropdownMenu'
import Navbar from '../Navbar'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Sales CRM/new.css'
import './page/amit.css'
import { Link, useParams, useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/brand/logo.png'
import Img from '../../assets/img/svgs/sales_quotation.png'
import Img2 from '../../assets/img/users/user-img2.jpg'
import Img3 from '../../assets/img/svgs/callback.png'
import Img4 from '../../assets/img/svgs/4029413.png'
import Img5 from '../../assets/img/truck-driver.jpg'


const DatelsLeads = () => {
  const initialFormData = {
    comment: '',
    attachment: '',


  }
  const initialFormData2 = {

    callBack: false,
    callBackDate: '',
    callBackTime: '',
    lastCallSummary: '',
    remindeMe: false,

  }
  const initialFormData3 = {

    meetingDate: '',
    meetingTime: '',
    meetingType: '',
    lastCallSummary: '',
    status: '',
    meetingVenue: '',
    remindeMe: false,


  }
  const initialFormData8 = {

    exporterName: '',
    typeOfRice: '',
    brandName: '',
    totalTons: '',
    noOfTonsFcl: '',
    pricePerTon: '',
    totalBags: '',
    packingSize: '',
    paymentTerms: '',
    portOfLoading: '',
    eta: '',
    portOfDestination: '',
    consignee: '',
    notify1: '',
    notify2: '',


  }
  const initialFormData5 = {
    buyer: '',
    buyerAddress: '',
    notifyParty: '',
    notifyPartyAddress: '',
    consignee: '',
    consigneeAddress: '',
    riceType: '',
    broken:'',
    swarnaParboiled: '',
    chalky: '',
    dd: '',
    whiteNess: '',
    moisture: '',
    grainLength: '',
    paymentTerms: '',
    portOfLoading: '',
    portOfDestination: '',
    etd: '',
    eta: '',
    cif: '',
    bagMark: '',
    size: '',
    totalNoOfBags: '',
    type: '',
    emptyBags: '',
    bagMarkDesign: '',
    fcl: '',
    tons: '',
    lotSize: '',



  }
  const initialFormData6 = {

    followUpId: '',

  }
  const [formData8, setFormData8] = useState(initialFormData8);
  const [formData, setFormData] = useState(initialFormData);
  const [formData2, setFormData2] = useState(initialFormData2);
  const [formData3, setFormData3] = useState(initialFormData3);

  const [formData5, setFormData5] = useState(initialFormData5);
  const [formData6, setFormData6] = useState(initialFormData6);

  const [errors, setErrors] = useState({});
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [genderOptions, setGenderOptions] = useState([]);
  const [genderOptions2, setGenderOptions2] = useState([]);
  const [genderOptions3, setGenderOptions3] = useState([]);
  const [genderOptions4, setGenderOptions4] = useState([]);
  const [genderOptions5, setGenderOptions5] = useState([]);
  const [genderOptions6, setGenderOptions6] = useState([]);
  const [genderOptions7, setGenderOptions7] = useState([]);
  const [proforma, setProforma] = useState([]);


  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [search, setSearch] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [leadData, setLeadData] = useState(null);
  const [leadDetails, setLeadDetails] = useState(/* initial value */);

  const [leadDetailsfollowups, setLeadDetailsfollowups] = useState([]);
  const [leadDetails2, setLeadDetails2] = useState(null);
  const [leadData2, setLeadData2] = useState(null);
  const [id, setId] = useState('');
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [callBackDateError, setCallBackDateError] = useState(false);
  const [meetingDateError, setMeetingDateError] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [formattedDate, setFormattedDate] = useState('');



  const { empid } = useParams();

  const token = localStorage.getItem('Token');
  const apiUrl = process.env.REACT_APP_URL;
  


  const handleOpenModal = () => {
    setModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.classList.remove('modal-open');
  };
  const handleOpenModal2 = () => {
    setIsModalOpen2(true);
    document.body.classList.add('modal-open');
  };
  const handleCloseModal2 = () => {
    setIsModalOpen2(false);

    document.body.classList.remove('modal-open');
  };


  const handleOpenModal3 = () => {
    setIsModalOpen3(true);
    document.body.classList.add('modal-open');
  };

  const handleCloseModal3 = () => {
    setIsModalOpen3(false);
    document.body.classList.remove('modal-open');
  };
  const handleOpenModal4 = () => {
    setIsModalOpen4(true);
    document.body.classList.add('modal-open');
  };

  const handleCloseModal4 = () => {
    setIsModalOpen4(false);
    document.body.classList.remove('modal-open');
  };
  const handleOpenModal5 = () => {
    setIsModalOpen5(true);
    document.body.classList.add('modal-open');
  };

  const handleCloseModal5 = () => {

    setIsModalOpen5(false);
    document.body.classList.remove('modal-open');
  };


  useEffect(() => {
    // Current date
    const currentDate = new Date();


    const formattedDate = currentDate.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    });

    setFormattedDate(formattedDate);
  }, []); // Empty 

  //Types of rice


  useEffect(() => {
    const Token = localStorage.getItem('Token');
   

    fetch(`${apiUrl}/master/getAllMasterData/9`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions6(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);
  //Bag narks

  useEffect(() => {
    const Token = localStorage.getItem('Token');
   

    fetch(`${apiUrl}/master/getAllMasterData/15`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions7(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem('Token');
   

    fetch(`${apiUrl}/master/getAllMasterData/9`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem('Token');
    

    fetch(`${apiUrl}/master/getAllMasterData/2`, {
      headers: {
        'Authorization': `Bearer ${Token}`,
        'Content-Type': 'application/json' // Adjust this based on your API's requirements
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions2(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  useEffect(() => {

    const Token = localStorage.getItem('Token');
   

    fetch(`${apiUrl}/master/getAllMasterData/3`, {
      headers: {
        'Authorization': `Bearer ${Token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions3(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem('Token');
   

    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        'Authorization': `Bearer ${Token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions4(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);


  useEffect(() => {
    const Token = localStorage.getItem('Token');
   


    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        'Authorization': `Bearer ${Token}`,
        'Content-Type': 'application/json' // Adjust content type if needed
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions5(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);



  const handleSubmit11 = async (id) => {
    try {
      console.log("id", id);

      const response = await fetch(`${apiUrl}/lead/convertToSale/${empid}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          proformaId: id,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      console.log(data);
      alert("Convert To Sale successfully...");
      setRefresh(!refresh);

      // Hide the button permanently


    } catch (error) {
      console.error('There was an error with the fetch operation:', error);
    }
  };

  const handleSubmit10 = async (num) => {
    const Token = localStorage.getItem("Token");
    try {
      console.log(num);
      const response = await fetch(`${apiUrl}/lead/sendEmailProforma/${num}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Token}` // Add the token to the Authorization header
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);
      alert("Proforma sent by email successfully...");

    } catch (error) {
      console.error('There was an error with the fetch operation:', error);
    }
  };


  //pre Per
  const handleFileChange9 = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith('image/')) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData5((prevData) => ({
          ...prevData,
          bagMarkDesign: file,


        }));
      } else if (file.type === 'application/pdf') {
        // Set aadhaarUpload for PDF files
        setFormData5((prevData) => ({
          ...prevData,
          bagMarkDesign: file,
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };

  const handleChange9 = (event) => {
    const { name, value } = event.target;
    setFormData5((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };

  const handleSubmit9 = async (event) => {
    event.preventDefault();

    const Token = localStorage.getItem('Token');
    console.log('Token:', Token);

    try {
      const formDataToSend = new FormData();
      for (const key in formData5) {
        if (formData5[key] !== null) {
          formDataToSend.append(key, formData5[key]);
        }
      }
      const url = `${apiUrl}/lead/createProforma/${empid}`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      if (!response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.status === 'error' && responseData.message) {
          alert(responseData.message);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

      }

      const data = await response.json();

      console.log('Server response:', data);
      handleCloseModal5();
      setFormData5(initialFormData5);


    } catch (error) {
      console.error('Error:', error);
      // Handle errors here or display appropriate messages
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith('image/')) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData((prevData) => ({
          ...prevData,
          attachment: file,


        }));
      } else if (file.type === 'application/pdf') {
        // Set aadhaarUpload for PDF files
        setFormData((prevData) => ({
          ...prevData,
          attachment: file,
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };
  // useEffect(() => {
  //   setFormData();
  // }, formData);

  const handleSubmit = async (event) => {


    const Token = localStorage.getItem('Token');
    console.log('Token:', Token);

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }
      const url = `${apiUrl}/lead/postComment/${empid}`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      if (!response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.status === 'error' && responseData.message) {

          alert(responseData.message);

        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      // toast.success('Form submitted successfully');
      console.log('Server response:', data);


      handleCloseModal();
      setFormData(initialFormData);




      // Close the modal after successful submission
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here or display appropriate messages
    }
  };

  //Call update


  const handleFileChange2 = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith('image/')) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData2((prevData) => ({
          ...prevData,
          profilePhoto: file,


        }));
      } else if (file.type === 'application/pdf') {
        // Set aadhaarUpload for PDF files
        setFormData2((prevData) => ({
          ...prevData,
          attachment: file,
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };
  const handleChange2 = (event) => {
    const { name, value } = event.target;

    setFormData2((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  }

  const handleSubmit2 = async (event) => {
    event.preventDefault();

    const Token = localStorage.getItem('Token');
    console.log('Token:', Token);

    try {



      const formDataToSend = new FormData();
      for (const key in formData2) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData2[key]);
        }
      }
      const url = `${apiUrl}/lead/callUpdate/${empid}`


      const response = await fetch(url, {

        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      // console.log(response)

      if (!response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.status === 'error' && responseData.message) {
          alert(responseData.message);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }


      const data = await response.json();
      // alert("Form submitted successfully")
      // toast.success('Form submitted successfully');
      console.log('Server response:', data);

      handleCloseModal2()
      setFormData(initialFormData2);

    } catch (error) {
      console.error('Error:', error);

      // Handle specific errors or display appropriate messages
    }
  };
  const currentDate = new Date().toISOString().split('T')[0];
  const currentDate2 = new Date().toISOString().split('T')[0];
  const currentDate3 = new Date().toISOString().split('T')[0];
  //Mitting update
  const handleFileChange3 = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith('image/')) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData3((prevData) => ({
          ...prevData,
          profilePhoto: file,


        }));
      } else if (file.type === 'application/pdf') {
        // Set aadhaarUpload for PDF files
        setFormData3((prevData) => ({
          ...prevData,
          attachment: file,
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };

  const handleChange3 = (event) => {
    const { name, value } = event.target;

    setFormData3((prevState) => ({
      ...prevState,
      [name]: value,
    }));


  }
  const handleSubmit3 = async (event) => {
    event.preventDefault();

    const Token = localStorage.getItem('Token');
    console.log('Token:', Token);

    try {
      const formDataToSend = new FormData();
      for (const key in formData3) {
        if (formData3[key] !== null) {
          formDataToSend.append(key, formData3[key]);
        }
      }
      const url = `${apiUrl}/lead/meetingUpdate/${empid}`
      const response = await fetch(url, {

        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      console.log(response)

      if (!response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.status === 'error' && responseData.message) {
          alert(responseData.message);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      // toast.success('Form submitted successfully');
      console.log('Server response:', data);

      handleCloseModal3()
      setFormData3(initialFormData);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  //Quotation update

  const handleFileChange8 = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith('image/')) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData8((prevData) => ({
          ...prevData,
          profilePhoto: file,


        }));
      } else if (file.type === 'application/pdf') {
        // Set aadhaarUpload for PDF files
        setFormData8((prevData) => ({
          ...prevData,
          attachment: file,
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };

  const handleChange4 = (event) => {
    const { name, value } = event.target;
    setFormData8((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };
  const handleSubmit4 = async (event) => {
    event.preventDefault();

    const Token = localStorage.getItem('Token');
    console.log('Token:', Token);

    try {


      const formDataToSend = new FormData();
      for (const key in formData8) {
        if (formData8[key] !== null) {
          formDataToSend.append(key, formData8[key]);
        }
      }
      const url = `${apiUrl}/lead/addQuatation/${empid}`
      const response = await fetch(url, {

        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      console.log(response)

      if (!response.ok) {
        const responseData = await response.json();
        if (responseData && responseData.status === 'error' && responseData.message) {
          alert(responseData.message);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      // toast.success('Form submitted successfully');
      console.log('Server response:', data);

      handleCloseModal4()
      setFormData8(initialFormData8);


    } catch (error) {
      console.error('Error:', error);
    }
  };


  // fllop
  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const Token = localStorage.getItem('Token');
        console.log('Token:', Token);

        const url = `${apiUrl}/lead/getLeadById/${empid}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch lead data');
        }

        const data = await response.json();
        if (data && data.status === 'success' && data.data) {
          const fetchedLeadDetails = data.data;
          setLeadDetails(fetchedLeadDetails);
          // setRefresh(!refresh);
        } else {
          console.error('Lead data not found in the API response');
        }
      } catch (error) {
        console.error('Error fetching lead data:', error);
      }
    };

    fetchLeadData();
  }, [refresh]);



  const Token = localStorage.getItem('Token');
 




  useEffect(() => {
    const fetchLeadData = async () => {

      try {
        const url = `${apiUrl}/lead/leadFollowUP/${empid}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch lead data');
        }

        const responseData = await response.json();

        if (responseData.status === 'success' && responseData.data) {
          const followups = responseData.data;
          // console.log(followups);
          setLeadDetailsfollowups(followups);
        } else {
          console.error('Lead data not found in the API response');
        }
      } catch (error) {
        console.error('Error fetching lead data:', error);
      } finally {

      }
    };

    fetchLeadData();
    // console.log(fetchLeadData);
  }, [leadDetailsfollowups]);

  useEffect(() => {
    const storedLeadDetails = localStorage.getItem('leadDetails');
    if (storedLeadDetails) {
      setLeadDetails(JSON.parse(storedLeadDetails));
    }
  }, []);

  // Effect to save leadDetails to localStorage whenever it changes
  useEffect(() => {
    if (leadDetails) {
      localStorage.setItem('leadDetails', JSON.stringify(leadDetails));
    }
  }, [leadDetails])

  //refresh

  useEffect(() => {
    const token = localStorage.getItem('Token');

    if (!token) {
      console.log(token);
      navigate('/');
    }
  }, [navigate]);
  return (
    <>
      <div className="page">
        <Navbar />
        <Prince />

        {/* Main Content*/}
        <div className="main-content pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Lead ID : {empid}
                  </h2>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card main-content-body-profile">
                    <div className="card-body pb-0">
                      <div className="profile-tab tab-menu-heading">
                        <nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                          <a
                            className="nav-link  active"
                            data-bs-toggle="tab"
                            href="#about"
                          >
                            Followup
                          </a>
                         
                        </nav>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div
                        className="main-content-body tab-pane p-3 border-top-0 active"
                        id="about"
                      >
                        <div className="row row-sm">
                          <div className="col-xl-12">
                            <div className="card custom-card border mb-3">

                              <div className="card-header pb-2">
                                <h6 className="mb-0">
                                  {" "}
                                  {/* Sales followup History : 10 Comments | 2 Meetings
                                  | 3 Attachment */}
                                  <div>

                                    {leadDetails?.status !== 'Convertedtosale' && (
                                      <button
                                        className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1"
                                        onClick={handleOpenModal}
                                      >
                                        Post a Comment
                                      </button>
                                    )}

                                    <div
                                      className={`modal ${isModalOpen ? 'show' : ''}`}
                                      style={{ display: isModalOpen ? 'block' : 'none' }}
                                      tabIndex="-1"
                                      role="dialog"
                                    >
                                      <div className="modal-dialog modal-dialog-centered modal-sl" role="document">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5 className="modal-title">Followup</h5>
                                            <button
                                              type="button"
                                              className="close"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                              onClick={handleCloseModal}
                                            >
                                              <span aria-hidden="true">&times;</span>

                                            </button>

                                          </div>

                                          <div className="modal-body">
                                            <form>
                                              <div className="row row-sm">
                                                <div className="col-sm-12 form-group">
                                                  <label className="form-label">Comment</label>
                                                  <textarea
                                                    className="form-control"
                                                    style={{ height: 150 }}
                                                    defaultValue={""}
                                                    name="comment"
                                                    value={formData.comment}
                                                    onChange={handleChange}
                                                  />
                                                </div>
                                                <div className="col-sm-12 form-group">
                                                  <label className="form-label">Attachment</label>
                                                  <input type="file" className="form-control"
                                                    name="attachment"
                                                    onChange={handleFileChange} />
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="modal-footer">
                                            <button className="btn ripple btn-primary" fetchLeadData onClick={handleSubmit} type="button">
                                              Post Update
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div>

                                    {leadDetails?.status !== 'Convertedtosale' && (
                                      <button
                                        className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1 me-1"
                                        data-bs-target="#modaldemo-callback-form"
                                        data-bs-toggle="modal"
                                        onClick={handleOpenModal2}
                                      >
                                        Call Update
                                      </button>
                                    )}

                                    <div
                                      className={`modal fade ${isModalOpen2 ? 'show d-block' : ''}`}
                                      id="modaldemo-callback-form"
                                      tabIndex="-1"
                                      style={{ display: isModalOpen2 ? 'block' : 'none' }}
                                    >
                                      <div className="modal-dialog modal-md">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5 className="modal-title">Call Update</h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              onClick={handleCloseModal2}
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <ToastContainer />
                                          <div className="modal-body">
                                            <form>
                                              <div className="row row-sm">
                                                <div className="col-sm-4 form-group mb-3">
                                                  <label className="ckbox">
                                                    <input type="checkbox"
                                                      name="callBack"
                                                      value={formData2.callBack}
                                                      onChange={handleChange2} />
                                                    <span>Call Back</span>
                                                  </label>
                                                </div>
                                                <div className="col-sm-12" />
                                                <div className="col-sm-6 form-group">
                                                  <label className="form-label">Callback Date</label>
                                                  <input type="date" className="form-control"
                                                    name="callBackDate"
                                                    value={formData2.callBackDate}
                                                    onChange={handleChange2}
                                                    min={currentDate3}
                                                  />
                                                </div>
                                                <div className="col-sm-6 form-group">
                                                  <label className="form-label">Callback Time</label>
                                                  <input type="time" className="form-control"
                                                    name="callBackTime"
                                                    value={formData2.callBackTime}
                                                    onChange={handleChange2}
                                                  />
                                                </div>
                                                <div className="col-sm-12 form-group">
                                                  <label className="form-label">Last Call Summary</label>
                                                  <textarea
                                                    className="form-control"
                                                    style={{ height: 80 }}
                                                    defaultValue={""}
                                                    name="lastCallSummary"
                                                    value={formData2.lastCallSummary}
                                                    onChange={handleChange2}
                                                  />
                                                </div>
                                                <div className="col-sm-12 ">
                                                  <label className="ckbox">
                                                    <input type="checkbox" name="remindeMe"
                                                      value={formData2.remindeMe}
                                                      onChange={handleChange2} />
                                                    <span>Reminder Me</span>
                                                  </label>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="modal-footer">
                                            <button className="btn ripple btn-primary" onClick={handleSubmit2} type="button">
                                              Update Call
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>

                                    {leadDetails?.status !== 'Convertedtosale' && (
                                      <button
                                        className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1 me-1"
                                        data-bs-target="#modaldemo-meeting-form"
                                        data-bs-toggle="modal"
                                        onClick={handleOpenModal3}
                                      >
                                        Meeting Update
                                      </button>
                                    )}

                                    <div
                                      className={`modal fade ${isModalOpen3 ? 'show d-block' : ''}`}
                                      id="modaldemo-callback-form"
                                      tabIndex="-1"
                                      style={{ display: isModalOpen3 ? 'block' : 'none' }}
                                    >
                                      <div className="modal-dialog modal-md">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5 className="modal-title">Meeting Update</h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              onClick={handleCloseModal3}
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <ToastContainer />
                                          <div className="modal-body">
                                            <form>
                                              <div className="row row-sm">
                                                <div className="col-sm-6 form-group">
                                                  <label className="form-label">Meeting Date</label>
                                                  <input type="date" className="form-control"
                                                    name="meetingDate"
                                                    value={formData3.meetingDate}
                                                    onChange={handleChange3}
                                                    
                                                    min={currentDate2}
                                                  />
                                                </div>
                                                <div className="col-sm-6 form-group">
                                                  <label className="form-label">Meeting Time</label>
                                                  <input type="time" className="form-control"
                                                    name="meetingTime"
                                                    value={formData3.meetingTime}
                                                    onChange={handleChange3}

                                                  />
                                                </div>
                                                <div className="col-sm-6 form-group">
                                                  <label className="form-label"> Meeting Type</label>
                                                  <select className="form-control select select2"
                                                    name="meetingType"
                                                    value={formData3.meetingType}
                                                    onChange={handleChange3}

                                                  >
                                                    <option>Select</option>
                                                    <option>Online</option>
                                                    <option>Offline</option>

                                                  </select>
                                                </div>
                                                <div className="col-sm-6 form-group">
                                                  <label className="form-label"> Status</label>
                                                  <select className="form-control select select2"
                                                    name="status"
                                                    value={formData3.status}
                                                    onChange={handleChange3}>
                                                    <option>Select</option>
                                                    <option>Scheduled</option>
                                                  </select>
                                                </div>
                                                <div className="col-sm-12 form-group">
                                                  <label className="form-label">Meeting Venue</label>
                                                  <input type="text" className="form-control"
                                                    name="meetingVenue"
                                                    value={formData3.meetingVenue}
                                                    onChange={handleChange3} />
                                                </div>
                                                <div className="col-sm-12 form-group">
                                                  <label className="form-label">Last Call Summary</label>
                                                  <textarea
                                                    className="form-control"
                                                    style={{ height: 60 }}
                                                    defaultValue={""}
                                                    name="lastCallSummary"
                                                    value={formData3.lastCallSummary}
                                                    onChange={handleChange3}
                                                  />
                                                </div>
                                                <div className="col-sm-12 ">
                                                  <label className="ckbox">
                                                    <input type="checkbox"
                                                      name="remindeMe"
                                                      value={formData3.remindeMe}
                                                      onChange={handleChange3} />
                                                    <span>Reminder Me</span>
                                                  </label>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="modal-footer">
                                            <button className="btn ripple btn-primary" onClick={handleSubmit3} type="button">
                                              Update Meeting
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div>

                                    {leadDetails?.status !== 'Convertedtosale' && (
                                      <button
                                        className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1 me-1"
                                        data-bs-target="#modaldemo-quotation-form"
                                        data-bs-toggle="modal"
                                        onClick={handleOpenModal4}
                                      >
                                        Quotation
                                      </button>
                                    )}
                                    <div
                                      className={`modal fade ${isModalOpen4 ? 'show d-block' : ''}`}
                                      id="modaldemo-callback-form"
                                      // tabIndex="-1"
                                      style={{ display: isModalOpen4 ? 'block' : 'none' }}
                                    >
                                      <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5 className="modal-title">Quotation Update</h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              onClick={handleCloseModal4}
                                              aria-lab
                                              el="Close"
                                            ></button>

                                          </div>
                                          <div className="modal-body">
                                            <form>
                                              <div className="row row-sm">
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label"> Exporter Name</label>
                                                  <input type="text" className="form-control"
                                                    name="exporterName"
                                                    value={formData8.exporterName}
                                                    onChange={handleChange4} />
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Type of Rice</label>
                                                  <select
                                                    className="form-control select2"
                                                    name="typeOfRice"
                                                    value={formData8.typeOfRice}
                                                    onChange={handleChange4}
                                                  >
                                                    <option>Select</option>
                                                    {genderOptions.map((option, index) => (
                                                      <option key={option.id} value={option.name}>{option.name}</option>
                                                    ))}
                                                  </select>
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Brand Name</label>
                                                  <select
                                                    className="form-control select2"
                                                    name="brandName"
                                                    value={formData8.brandName}
                                                    onChange={handleChange4}
                                                  >
                                                    <option>Select</option>
                                                    {genderOptions2.map((option, index) => (
                                                      <option key={option.id} value={option.name}>{option.name}</option>
                                                    ))}
                                                  </select>
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Total tons</label>
                                                  <input type="text" className="form-control"
                                                    name="totalTons"
                                                    value={formData8.totalTons}
                                                    onChange={handleChange4} />
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">No Of Tons Fcl</label>
                                                  <input type="text" className="form-control"
                                                    name="noOfTonsFcl"
                                                    value={formData8.noOfTonsFcl}
                                                    onChange={handleChange4} />
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Price per ton($)</label>
                                                  <input type="text" className="form-control"
                                                    name="pricePerTon"
                                                    value={formData8.pricePerTon}
                                                    onChange={handleChange4} />
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Total bags</label>
                                                  <input type="text" className="form-control"
                                                    name="totalBags"
                                                    value={formData8.totalBags}
                                                    onChange={handleChange4} />
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">
                                                    Packing size(kg)</label>
                                                  <input type="text" className="form-control"
                                                    name="packingSize"
                                                    value={formData8.packingSize}
                                                    onChange={handleChange4} />
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Payment terms</label>
                                                  <select
                                                    className="form-control select2"
                                                    name="paymentTerms"
                                                    value={formData8.paymentTerms}
                                                    onChange={handleChange4}
                                                  >
                                                    <option>Select</option>
                                                    {genderOptions3.map((option, index) => (
                                                      <option key={option.id} value={option.name}>{option.name}</option>
                                                    ))}
                                                  </select>
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Port of Loading</label>
                                                  <select
                                                    className="form-control select2"
                                                    name="portOfLoading"
                                                    value={formData8.portOfLoading}
                                                    onChange={handleChange4}
                                                  >
                                                    <option>Select</option>
                                                    {genderOptions4.map((option, index) => (
                                                      <option key={option.id} value={option.name}>{option.name}</option>
                                                    ))}
                                                  </select>
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">ETA</label>
                                                  <input type="date" className="form-control"
                                                    name="eta"
                                                    value={formData8.eta}
                                                    onChange={handleChange4}
                                                    min={currentDate}  />
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Port of Destination</label>
                                                  <select
                                                    className="form-control select2"
                                                    name="portOfDestination"
                                                    value={formData8.portOfDestination}
                                                    onChange={handleChange4}
                                                  >
                                                    <option>Select</option>
                                                    {genderOptions5.map((option, index) => (
                                                      <option key={option.id} value={option.name}>{option.name}</option>
                                                    ))}
                                                  </select>
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Consignee</label>
                                                  <select className="form-control select select2"
                                                    name="consignee"
                                                    value={formData8.consignee}
                                                    onChange={handleChange4}>
                                                    <option>Select</option>
                                                    <option>Virago</option>
                                                  </select>
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Notify - 1</label>
                                                  <select className="form-control select select2"
                                                    name="notify1"
                                                    value={formData8.notify1}
                                                    onChange={handleChange4}>
                                                    <option>Select</option>
                                                    <option>Virago</option>
                                                  </select>
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                  <label className="form-label">Notify - 2</label>
                                                  <select className="form-control select select2"
                                                    name="notify2"
                                                    value={formData8.notify2}
                                                    onChange={handleChange4}>
                                                    <option>Select</option>
                                                    <option>PWIP</option>
                                                  </select>
                                                </div>
                                              </div>
                                            </form>


                                          </div>
                                          <div className="modal-footer">
                                            <button className="btn ripple btn-primary" onClick={handleSubmit4} type="button">
                                              Update Quotation
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  <div>
                                    {leadDetails?.status !== 'Convertedtosale' && (
                                      <button
                                        className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1 me-1"
                                        data-bs-target="#modaldemo-quotation-form"
                                        data-bs-toggle="modal"
                                        onClick={handleOpenModal5}
                                      >
                                        Pre-proforma
                                      </button>
                                    )}




                                    <div
                                      className={`modal fade ${isModalOpen5 ? 'show d-block' : ''}`}
                                      id="modaldemo-callback-form"
                                      tabIndex="-1"

                                    >
                                      <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5 className="modal-title">Pre-proforma</h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              onClick={handleCloseModal5}
                                              aria-lab
                                              el="Close"
                                            ></button>
                                            <ToastContainer />
                                          </div>
                                          <div className="modal-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                            <form>

                                              {/* Main Content*/}


                                              <table className="">
                                                <tbody>
                                                  <tr>
                                                    <td width="10%">
                                                      <h5>PRE-PROFORMA CONTRACT </h5>
                                                      <h6>
                                                        <b>Contract Number :</b> PWIP/PPC/010
                                                      </h6>
                                                      <h6>
                                                        <b>Date :</b> {formattedDate}
                                                      </h6>
                                                      <br />
                                                      <br />
                                                      <br />
                                                      <h5>PWIP FOODTECH PVT LTD </h5>
                                                      <h6>
                                                        #No. 78/9, Vaishnavi Signature, Outer
                                                        <br />
                                                        Ring Road, Bellandur Village
                                                        <br />
                                                        Varthur Hobli, Bengaluru, Bengaluru
                                                        <br />
                                                        Urban, Karnataka, 560103
                                                      </h6>
                                                    </td>
                                                    <td
                                                      width="30%"
                                                      align="right"
                                                      style={{ verticalAlign: "top" }}
                                                    >
                                                      <img
                                                        src={Img}
                                                        style={{ width: 100 }}
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <table className="table table-invoice table-borderless">
                                                <tbody>
                                                  <tr>
                                                    <td align="left" style={{ border: "0 !important" }}>
                                                      <h5>NOTIFY PARTY 1: BUYER</h5>
                                                      <input type="text" className="form-control"
                                                      placeholder='Enter name'
                                                        name="buyer"
                                                        value={formData5.buyer}
                                                        onChange={handleChange9} />

                                                      <textarea
                                                        className="form-control mt-3"
                                                        placeholder='Enter address'
                                                        style={{ height: "60px !important" }}
                                                        defaultValue={""}
                                                        name="buyerAddress"
                                                        value={formData5.buyerAddress}
                                                        onChange={handleChange9}
                                                      />
                                                    </td>
                                                    <td align="left" style={{ border: "0 !important" }}>
                                                      <h5>NOTIFY PARTY 2</h5>
                                                      <input type="text" className="form-control"
                                                       placeholder='Enter name'
                                                        name="notifyParty"
                                                        value={formData5.notifyParty}
                                                        onChange={handleChange9} />

                                                      <textarea
                                                        className="form-control mt-3"
                                                        placeholder='Enter address'
                                                        style={{ height: "60px !important" }}
                                                        defaultValue={""}
                                                        name="notifyPartyAddress"
                                                        value={formData5.notifyPartyAddress}
                                                        onChange={handleChange9}
                                                      />
                                                    </td>
                                                    <td align="left" style={{ border: "0 !important" }}>
                                                      <h5>CONSIGNEE</h5>
                                                      <input type="text" className="form-control"
                                                       placeholder='Enter name'
                                                        name="consignee"
                                                        value={formData5.consignee}
                                                        onChange={handleChange9} />

                                                      <textarea
                                                        className="form-control mt-3"
                                                        placeholder='Enter address'
                                                        style={{ height: "60px" }}
                                                        defaultValue={""}
                                                        name="consigneeAddress"
                                                        value={formData5.consigneeAddress}
                                                        onChange={handleChange9}
                                                      />

                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <div className="col-sm-12">
                                                <div className="row">
                                                  <div className="col-sm-6">
                                                    <table className="table table-bordered mb-0">
                                                      <thead>
                                                        <tr>
                                                          <th
                                                            className="tx-center agreed-rice-parameters"
                                                            colSpan={2}

                                                          >
                                                            <h6 className="mb-0">
                                                              AGREED RICE PARAMETERS
                                                            </h6>
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td className="tx-center">
                                                            <b>RICE TYPE</b>{" "}
                                                          </td>
                                                          <td className="tx-center">
                                                          <select className="form-control select select2"
                                                              name="riceType"
                                                              value={formData5.riceType}
                                                              onChange={handleChange9}>

                                                              <option>Select</option>
                                                              {genderOptions6.map((option, index) => (
                                                                <option key={option.id} value={option.name}>{option.name}</option>
                                                              ))}
                                                            </select>{" "}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">Swarna Parboiled </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="swarnaParboiled"
                                                            
                                                              value={formData5.swarnaParboiled}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">Broken </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="broken"
                                                            
                                                              value={formData5.broken}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">CHALKY </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="chalky"
                                                              value={formData5.chalky}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">DD</td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="dd"
                                                              value={formData5.dd}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">WHITENESS </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="whiteNess"
                                                              value={formData5.whiteNess}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>

                                                          <td className="tx-center">MOISTURE </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="moisture"
                                                              value={formData5.moisture}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">GRAIN LENGTH </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="grainLength"
                                                              value={formData5.grainLength}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr className="table-light">
                                                          <td className="tx-center">PAYMENT TERMS: </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="paymentTerms"
                                                              value={formData5.paymentTerms}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr className="table-light">
                                                          <td className="tx-center">PORT OF LOADING: </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="portOfLoading"
                                                              value={formData5.portOfLoading}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr className="table-light">
                                                          <td className="tx-center">
                                                            PORT OF DESTINATION:{" "}
                                                          </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="portOfDestination"
                                                              value={formData5.portOfDestination}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr className="table-light">
                                                          <td className="tx-center">ETD: </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="etd"
                                                              value={formData5.etd}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr className="table-light">
                                                          <td className="tx-center">ETA: </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="eta"
                                                              value={formData5.eta}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr className="table-light">
                                                          <td className="tx-center">
                                                            <b>CIF: </b>
                                                          </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="cif"
                                                              value={formData5.cif}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                  <div className="col-sm-6">
                                                    <table className="table table-bordered mb-0">
                                                      <thead>
                                                        <tr>
                                                          <th
                                                            className="tx-center agreed-rice-parameters"
                                                            colSpan={2}

                                                          >
                                                            <h6 className="mb-0">
                                                              BRAND &amp; PACKAGING
                                                            </h6>
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td className="tx-center">
                                                            <b>BAG MARK </b>
                                                          </td>
                                                          <td className="tx-center">
                                                          <select className="form-control select select2"
                                                              name="bagMark"
                                                              value={formData5.bagMark}
                                                              onChange={handleChange9}>

                                                              <option>Select</option>
                                                              {genderOptions7.map((option, index) => (
                                                                <option key={option.id} value={option.name}>{option.name}</option>
                                                              ))}
                                                            </select>{" "}
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">SIZE </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="size"
                                                              value={formData5.size}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">
                                                            TOTAL NO. OF BAGS{" "}
                                                          </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="totalNoOfBags"
                                                              value={formData5.totalNoOfBags}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">TYPE </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="type"
                                                              value={formData5.type}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">
                                                            EMPTY BAGS (IF ANY)
                                                          </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="emptyBags"
                                                              value={formData5.emptyBags}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="tx-center">BAG MARK DESIGN</td>
                                                          <td className="tx-center">
                                                            <input
                                                              className="form-control"
                                                              type="file"
                                                              name="bagMarkDesign"

                                                              accept="/pdf"
                                                              onChange={handleFileChange9}
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr className="table-light">
                                                          <td className="tx-left" colSpan={2}>
                                                            <h6 className="mb-0">QUANTITY</h6>{" "}
                                                          </td>

                                                        </tr>
                                                        <tr className="table-light">
                                                          <td className="tx-center">
                                                            <b>FCL </b>
                                                          </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="fcl"
                                                              value={formData5.fcl}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        <tr className="table-light">
                                                          <td className="tx-center">
                                                            <b>Tons per container: </b>{" "}
                                                          </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="tons"
                                                              value={formData5.tons}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                        {/* <tr className="table-light">
                                                          <td className="tx-center">
                                                            <b>Tons per container: </b>{" "}
                                                          </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="tons"
                                                              value={formData5.tons}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr> */}
                                                        <tr className="table-light">
                                                          <td className="tx-center">
                                                            <b>Lot Size: </b>
                                                          </td>
                                                          <td className="tx-center">
                                                            <input type="text" className="form-control"
                                                              name="lotSize"
                                                              value={formData5.lotSize}
                                                              onChange={handleChange9} />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                                <div className="row mg-t-100">
                                                  <div className="col-sm-7">
                                                    <h6>SIGNATURE</h6>
                                                  </div>
                                                  <div className="col-sm-5" align="right">
                                                    <h6>APPROVED SIGNATURE</h6>
                                                  </div>
                                                  <div className="col-sm-12">
                                                    <p>
                                                      <b>Please Note:</b> This contract is a communication
                                                      for confirmation on the above order. Original
                                                      proforma contract with respective exporter details
                                                      will be shared at the start of the shipment.
                                                    </p>
                                                  </div>


                                                  <div className="card-footer text-end">
                                                    <a
                                                      href="view-pre-proforma.html"
                                                      className="btn ripple btn-info mb-1"
                                                      onClick={handleSubmit9}
                                                    >
                                                      Submit
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>




                                              {/* End Main Content*/}

                                            </form>

                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <Link
                                    to="/View"
                                    className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1 me-1"
                                  >
                                    View Invoice
                                  </Link> */}
                                </h6>
                              </div>


                              <div className="card-body">
                                <div className="col-12">
                                  <ul className="notification mb-0">

                                    {leadDetailsfollowups.length > 0 ? (

                                      leadDetailsfollowups.map(comment => {
                                        if (comment.type === 'leadComment') {
                                          return (
                                            <li>
                                              <div className="notification-time">
                                                <p className="mb-0 ">
                                                  {comment.createdAtDate}
                                                  <br />
                                                  {comment.createdAtTime}
                                                </p>
                                              </div>
                                              <div className="notification-icon">
                                                <a href="javascript:void(0);" />
                                              </div>
                                              <div className="notification-time-date mb-2 d-block d-md-none">
                                                <p className="mb-0">
                                                  {comment.createdAt}<br />
                                                </p>
                                              </div>
                                              <div className="notification-body">
                                                <div className="media mt-0">

                                                  <div className="media-body ms-2 d-flex">
                                                    {/* <div className="me-5 pt-4 pb-4">
                                                      <img
                                                        src={Img2}
                                                        style={{ width: 40 }}
                                                      />
                                                    </div> */}
                                                    <div className="">
                                                      <p className="tx-14 text-dark mb-0 tx-semibold">
                                                        {comment.createdBy}, Posted an update.
                                                      </p>
                                                      <p className="tx-14 text-dark mb-0">
                                                        {comment.comment}{" "}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" /> Attachment : Click {<Link to={comment.attachment} target='black'>Here</Link>} to see

                                                      </p>
                                                    </div>
                                                    <div className="notify-time">
                                                      <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                        Comment
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>


                                          );

                                        } else if (comment.type === 'Pre-proforma') {
                                          return (
                                            <li>
                                              <div className="notification-time">
                                                <p className="mb-0 ">
                                                  {comment.createdAtDate}
                                                  <br />
                                                  {comment.createdAtTime}
                                                </p>
                                              </div>
                                              <div className="notification-icon">
                                                <a href="javascript:void(0);" />
                                              </div>
                                              <div className="notification-time-date mb-2 d-block d-md-none">
                                                <p className="mb-0">
                                                  10 May 2023 <br /> 12:00 PM
                                                </p>
                                              </div>
                                              <div className="notification-body">
                                                <div className="media mt-0">
                                                  <div className=" avatar-md online">
                                                    {/* <img
                                                      alt="avatar"
                                                      className="rounded-6"
                                                      src="https://p.webkype.com/assets/img/brand/logo.png"
                                                    /> */}
                                                  </div>
                                                  <div className="media-body ms-2 d-flex">
                                                    <div className="me-5 pt-4">
                                                      <img
                                                        src={Img}
                                                        style={{ width: 110 }}
                                                      />
                                                    </div>
                                                    <div className="">
                                                    <p className="tx-14 text-dark mb-0 tx-semibold">
                                                        {comment.proformas.createdBy}, Posted an update.
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">

                                                        AGREED RICE PARAMETERS
                                                      </p>


                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Rice Type:{comment.proformas.riceType}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Swarna Parboiled:{comment.proformas.swarnaParboiled}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Broken:{comment.proformas.broken}
                                                      </p>
                                                      
                                                     
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Chalky:{comment.proformas.chalky}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        DD: {comment.proformas.dd}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        WhiteNess:{comment.proformas.whiteNess}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Moisture:{comment.proformas.moisture}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Moisture:{comment.proformas.grainLength}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Payment Terms:{comment.proformas.paymentTerms}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        PortOf Loading:{comment.proformas.portOfLoading}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Port Of Destination:{comment.proformas.portOfDestination}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        ETD:{comment.proformas.etd}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        ETA:{comment.proformas.eta}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        CIF:{comment.proformas.cif}
                                                      </p>
                                                      <hr />
                                                      <p className="mb-0 tx-13 text-dark">

                                                        BRAND & PACKAGING
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Bag mark: {comment.proformas.bagMark}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Size:{comment.proformas.size}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Total No Of Bags:{comment.proformas.totalNoOfBags}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Type:{comment.proformas.type}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Empty Bags:{comment.proformas.emptyBags}
                                                      </p>
                                                      <br />
                                                      <p className="mb-0 tx-13 text-dark">

                                                        QUANTITY
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        FCL: {comment.proformas.fcl}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Tons per container: {comment.proformas.tons}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Total: {comment.proformas.tons*comment.proformas.fcl} tons
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Lot Size: {comment.proformas.lotSize} FCL/Lot
                                                      </p>

                                                      <p className="mb-0 tx-13 text-dark">
                                                        <br />
                                                        <Link
                                                          to={comment.proformas.proforma}
                                                          className="custom-button mb-1 me-1" target='blanck'
                                                        >
                                                          View Pre-proforma
                                                        </Link>
                                                        <Link

                                                          className="custom-button mb-1 me-1"


                                                          onClick={() => { handleSubmit10(comment.proformas.followUpId) }}
                                                        >
                                                          Send email Pre-proforma
                                                        </Link>
                                                        {leadDetails?.status !== 'Convertedtosale' && (
                                                          <Link
                                                            id="convertToSaleButton"
                                                            className="custom-button mb-1 me-1"
                                                            onClick={() => { handleSubmit11(comment.proformas.id) }}
                                                          >
                                                            Convert To Sale
                                                          </Link>
                                                        )}


                                                      </p>
                                                    </div>
                                                    <div className="notify-time">
                                                      <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                        Pre-proforma
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                            </li>

                                          );

                                        } else if (comment.type === 'quotationUpdate') {
                                          return (
                                            <li>
                                              <div className="notification-time">
                                                <p className="mb-0 ">
                                                  {comment.createdAtDate}
                                                  <br />
                                                  {comment.createdAtTime}
                                                </p>
                                              </div>
                                              <div className="notification-icon">
                                                <a href="javascript:void(0);" />
                                              </div>
                                              <div className="notification-time-date mb-2 d-block d-md-none">
                                                <p className="mb-0">
                                                  10 May 2023 <br /> 12:00 PM
                                                </p>
                                              </div>
                                              <div className="notification-body">
                                                <div className="media mt-0">
                                                  {/* <div className=" avatar-md online">
                                                    <img
                                                      alt="avatar"
                                                      className="rounded-6"
                                                      src="https://p.webkype.com/assets/img/brand/logo.png"
                                                    />
                                                  </div> */}
                                                  <div className="media-body ms-2 d-flex">
                                                    <div className="me-5 pt-4">
                                                      <img
                                                        src={Img}
                                                        style={{ width: 110 }}
                                                      />
                                                    </div>
                                                    <div className="">
                                                    <p className="tx-14 text-dark mb-0 tx-semibold">
                                                        {comment.quotations[0].createdBy}, Posted an update.
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Exporter Name : {comment.quotations[0].exporterName}

                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Type of Rice : {comment.quotations[0].typeOfRice}

                                                      </p>
                                                      
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Brand Name : {comment.quotations[0].brandName}
                                                      </p>

                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        No of tons FCL : {comment.quotations[0].noOfTonsFcl}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Price per ton($) : {comment.quotations[0].pricePerTon}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Total bags : {comment.quotations[0].totalBags}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Packing size(kg) : {comment.quotations[0].packingSize}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Payment terms : {comment.quotations[0].paymentTerms}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Port of Loading : {comment.quotations[0].portOfLoading}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        ETA : {comment.quotations[0].eta}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Port of Destination : {comment.quotations[0].portOfDestination}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Consignee : {comment.quotations[0].consignee}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Notify - 1 : {comment.quotations[0].notify1}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Notify - 2 : {comment.quotations[0].notify2}
                                                      </p>
                                                    </div>
                                                    <div className="notify-time">
                                                      <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                        Quotation
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>

                                          );
                                        } else if (comment.type === 'meetingUpdate') {
                                          return (
                                            <li>
                                              <div className="notification-time">
                                                <p className="mb-0 ">
                                                  {comment.createdAtDate}
                                                  <br />
                                                  {comment.createdAtTime}
                                                </p>
                                              </div>
                                              <div className="notification-icon">
                                                <a href="javascript:void(0);" />
                                              </div>
                                              <div className="notification-time-date mb-2 d-block d-md-none">
                                                <p className="mb-0">
                                                  {comment.createdAt}
                                                </p>
                                              </div>
                                              <div className="notification-body">
                                                <div className="media mt-0">

                                                  <div className="media-body ms-2 d-flex">
                                                    {/* <div className="me-5 pt-4 pb-4">
                                                      <img
                                                        src="https://p.webkype.com/assets/img/brand/logo.png"
                                                        style={{ width: 40 }}
                                                      />
                                                    </div> */}
                                                    <div className="me-5 pt-4 pb-4">
                                                      <img
                                                        src={Img4}
                                                        style={{ width: 100 }}
                                                      />
                                                    </div>
                                                    <div className="">
                                                      <p className="tx-14 text-dark mb-0 tx-semibold">
                                                        {comment.createdBy}, Posted an update.
                                                      </p>
                                                      <p className="tx-14 text-dark mb-0">
                                                        {comment.lastCallSummary}
                                                        {" "}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />{" "}
                                                        Meeting Venue : {comment.meetingVenue}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Meeting Date : {comment.meetingDate}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Meeting Time : {comment.meetingTime}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Meeting Type : {comment.meetingType}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Meeting Status:{comment.status}
                                                        <span className="mb-0 text-muted tx-11 badge bg-pill bg-success-light">

                                                        </span>
                                                      </p>
                                                    </div>
                                                    <div className="notify-time">
                                                      <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                        Meeting
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>

                                          );
                                        } else if (comment.type === 'callUpdate') {
                                          return (
                                            <li>
                                              <div className="notification-time">
                                                <p className="mb-0 ">
                                                  {comment.createdAtDate}
                                                  <br />
                                                  {comment.createdAtTime}
                                                </p>
                                              </div>
                                              <div className="notification-icon">
                                                <a href="javascript:void(0);" />
                                              </div>
                                              <div className="notification-time-date mb-2 d-block d-md-none">
                                                <p className="mb-0">
                                                  {comment.createdAt}
                                                </p>
                                              </div>
                                              <div className="notification-body">
                                                <div className="media mt-0">

                                                  <div className="media-body ms-2 d-flex">
                                                    {/* <div className="me-5 pt-4 pb-4">
                                                      <img
                                                        src={Img2}
                                                        style={{ width: 40 }}
                                                      />
                                                    </div> */}


                                                    <div className="me-5 pt-4 pb-4">
                                                      <img
                                                        src={Img3}
                                                        style={{ width: 80 }}
                                                      />
                                                    </div>
                                                    <div className="">
                                                      <p className="tx-14 text-dark mb-0 tx-semibold">
                                                        {comment.createdBy}, Posted an update.
                                                      </p>
                                                      <p className="tx-14 text-dark mb-0">
                                                        {comment.lastCallSummary}
                                                        {" "}
                                                      </p>

                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Meeting Date : {comment.callBackDate}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Meeting Time : {comment.callBackTime}
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Meeting Status:{" "}
                                                        <span className="mb-0 text-muted tx-11 badge bg-pill bg-success-light">
                                                          Scheduled.
                                                        </span>
                                                      </p>
                                                    </div>
                                                    <div className="notify-time">
                                                      <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                        Callback
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        } else {
                                          return null; // Handle other types or add a default case as needed
                                        }



                                      })


                                    ) : (
                                      <>
                                        <p>No follow-ups available</p>
                                      </>


                                    )}
                                    <li>
                                      {leadDetails ? (
                                        <div>
                                          <div className="notification-time">
                                            <p className="mb-0">
                                              {leadDetails.createdAtDate}<br /> {leadDetails.createdAtTime}
                                            </p>
                                          </div>
                                          <div>
                                            <div className="notification-icon">
                                              <a href="javascript:void(0);" />
                                            </div>
                                            <div className="notification-time-date mb-2 d-block d-md-none">
                                              <p className="mb-0">
                                                10 May 2023 <br /> 12:00 PM
                                              </p>
                                            </div>
                                            <div className="notification-body">
                                              <div className="media mt-0">

                                                <div className="media-body ms-2 d-flex">
                                                  <div className="me-3">
                                                    <img
                                                      src="https://p.webkype.com/assets/img/brand/logo.png"
                                                      style={{ width: 40 }}
                                                      alt="truck driver"
                                                    />
                                                  </div>
                                                 
                                                  <div>
                                                    <p className="tx-14 text-dark mb-0 tx-semibold">
                                                    {leadDetails.createdBy} Added a New Sales lead.
                                                    </p>
                                                    <p className="mb-0 tx-13 text-dark">
                                                      <i className="fe fe-chevrons-right me-1" />
                                                      Full Name:  {leadDetails.fullName}
                                                    </p>
                                                    <p className="mb-0 tx-13 text-dark">
                                                      <i className="fe fe-chevrons-right me-1" />
                                                      Email: {leadDetails.email}
                                                    </p>
                                                    <p className="mb-0 tx-13 text-dark">
                                                      <i className="fe fe-chevrons-right me-1" />
                                                      Phone: +91 {leadDetails.mobileNo}
                                                    </p>
                                                    <p className="mb-0 tx-13 text-dark">
                                                      <i className="fe fe-chevrons-right me-1" />
                                                      Company: {leadDetails.companyName}
                                                    </p>

                                                    {/* Add more static details as needed */}
                                                  </div>
                                                  <div className="notify-time">
                                                    <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                      New Lead
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <p></p>
                                      )}

                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
            </div>
          </div>
        </div >
        {/* End Main Content*/}
        {/* Main Footer*/}
        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2023 <a href="javascript:void(0)">PWIP</a>. Designed
                  by <a href="http://webkype.com/">Webkype.com</a> All rights
                  reserved.
                </span>
              </div>
            </div>
          </div>
        </div>



        <a href="#top" id="back-to-top">
          <i className="fe fe-arrow-up" />
        </a>

      </div >
    </>

  )
}

export default DatelsLeads