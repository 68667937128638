import React, { useState, useEffect } from 'react'
import MainPage from '../../Components/MainPage'
import Navbar from '../Navbar'
import Prince from '../../Components/Prince'
import DropdownMenu from '../../Components/DropdownMenu'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/brand/logo.png'

const ManageExporters = () => {
  const [users, setUsers] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [search, setSearch] = useState('');
  const [genderFilter, setGenderFilter] = useState('');

  const navigation = useNavigate();
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_URL;
  console.log(apiUrl);

  const loadcontent = (id) => {
    console.log(id); // Check the value of id
    navigation(`/ExportersEdit/${id}`);
};


const deletecontent = (id, token) => {
  const Token = localStorage.getItem("Token");
    console.log("Token " + Token);

  if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/exporter/deleteExporter/` + id, {
          method: "DELETE",
          headers: {
              "Authorization": `Bearer ${Token}`,
              "Content-Type": "application/json" // Adjust content type if needed
          }
      })
      .then((res) => {
          if (res.ok) {
              alert('Record Deleted');
              window.location.reload(false);
          } else {
              throw new Error('Failed to delete');
          }
      })
      .catch((err) => {
          console.log(err);
          // Handle error appropriately, maybe show an error message
      });
  }
};

  
  useEffect(() => {
    const Token = localStorage.getItem("Token");
    
    console.log("Token " + Token);

    fetch(`${apiUrl}/exporter/exporters`, {
      headers: {
        'Authorization': `Bearer ${Token}` // Include the Token in the Authorization header
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          if (Array.isArray(data.data)) {
            setUsers(data.data); // Update the users state with the fetched data
          } else {
            console.error('API response does not contain users array:', data);
          }
        } else {
          console.error('API request was not successful:', data);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, []);



  const filteredUsers = users.filter((user) => {
    return (
        (user.id.toString().includes(search) || user.fullName.includes(search) || user.email.includes(search) || user.mobileNumber.includes(search)|| user.companyName.includes(search) || user.companyEmail.includes(search))
    );
});

  useEffect(() => {
    const token = localStorage.getItem('Token');
    
    if (!token) {
      console.log(token);
      navigate('/');
    }
  }, [navigate]);
  return (
    <>


      {/* Main Header*/}
      <div className="page">
        <Navbar />
        <Prince />
        {/* Main Content*/}
        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Exporters List
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Millers </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Exporters List{" "}
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <Link
                      to="/AddExpor"
                      type="button"
                      className="btn btn-primary my-2 btn-icon-text"
                    >
                      {" "}
                      <i className="fe fe-plus me-2" /> Add Exporters
                    </Link>
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="card custom-card">
                    <div className="card-body py-3">
                      <div className="row">
                        <div className="col-sm-12">
                          <input
                            type="search"
                            className="form-control form-control"
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            aria-controls="example1"
                          />
                        </div>
                       
                      </div>{" "}
                    </div>
                  </div>
                </div>{" "}
              </div>
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="text-wrap">
                        <div className="panel panel-primary tabs-style-3 p-0 tabs-style-3-0">
                          <div className="tab-content ">
                            <div className="tab-pane active" id="tab11">
                              <div className="table-responsive">
                                <table className="table table-striped table-bordered text-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>
                                        <label className="ckbox">
                                          <input type="checkbox" defaultValue={5} />
                                          <span />
                                        </label>
                                      </th>
                                      <th>Source</th>
                                      <th>Photo</th>
                                      <th>Contact Person</th>
                                      <th>Company</th>
                                      <th>Bank Details</th>
                                      <th>Type &amp; Category</th>
                                      <th>Business</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {filteredUsers.map((user) => (
                                      <tr key={user.id}>
                                        <td>
                                          <label className="ckbox">
                                            <input type="checkbox" defaultValue={5} />
                                            <span />
                                          </label>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            ID: {user.id}
                                            <br />
                                            Source: By Exporter
                                            <br />
                                            
                                            <br />
                                           
                                          </p>
                                        </td>
                                        <td>
                                          <img
                                            alt="avatar"
                                            className="rounded-circle me-3"
                                            src={user.profilePhoto}
                                            style={{ width: 60 }}
                                          />
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            {user.fullName}
                                            <br />
                                            Email id: {user.email}
                                            <br />
                                            Ph no: +91 {user.mobileNumber}
                                          </p>
                                        </td>
                                        <td>
                                        <p className="mb-0">
                                            Company name: {user.companyName}
                                            <br />
                                            Address: {user.companyAddress}
      
                                            <br />
                                            Email:{user.companyEmail}
                                            <br />
                                            Website: {user.companyWebsite}
                                            <br />
                                            GST: {user.companyGstNo}
                                          </p>
                                        </td>
                                        <td>
                                        <p className="mb-0">
                                            Account Name {user.accountName}
                                            <br />
                                            Account no: {user.accountNo}
                                            <br />
                                            {user.mobileNumber}
                                            <br />
                                            Bank name: {user.bankName}
                                            <br />
                                            IFSC: {user.ifsc}
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            Category: Exporter
                                            <br />
                                            Rice ( All Type){" "}
                                          </p>{" "}
                                        </td>
                                        <td>
                                          PO : <a href="">1</a>
                                          <br />
                                          Invoice: <a href="">3</a>
                                          <br />
                                          Order Placed: <a href="">10</a>
                                          <br />
                                          In Process Order <a href="">5</a>
                                          <br />
                                          Delivered: <a href="">3</a>
                                        </td>
                                        <td>
                                         
                                          <a onClick={() => loadcontent(user.id)} className="btn ripple btn-info btn-xs w-100">

                                            Edit Profile
                                          </a>
                                          <br />
                                          <a href="#" className="btn ripple btn-info btn-xs w-100 my-1"
                                            onClick={() => deletecontent(user.id)}
                                          >
                                            Delete

                                          </a>
                                         
                                         
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
            </div>
          </div>
        </div>
        {/* End Main Content*/}
        {/* Main Footer*/}
        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2023 <a href="javascript:void(0)">PWIP</a>. Designed
                  by <a href="http://webkype.com/">Webkype.com</a> All rights
                  reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*End Footer*/}
      </div>
    
     
    
     
      
      

    </>

  )
}

export default ManageExporters