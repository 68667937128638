import React, { useState, useEffect } from 'react'
import FullScreenToggle from '../Components/FullScreenToggle';
import MainPage from './MainPage'
import Prince from './Prince';
import DropdownMenu from './DropdownMenu'

import { Link, useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/img/brand/logo.png'

const AddMaster = () => {
  const { empid } = useParams();
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_URL;
  console.log(apiUrl);

  

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const Token = localStorage.getItem('Token');
        const url = `${apiUrl}/master/getMasterById/${empid}`;
        let result = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${Token}` 
           
          }
        });
        result = await result.json();
  
        const { data } = result;
        setId(data.id);
        setName(data.name);
      } catch (e) {
        console.log(e);
      }
    };
  
    fetchUser(); 
  
  }, [apiUrl, empid]); 
  

  const handleUpdate = async () => {
    try {
      const Token = localStorage.getItem('Token');
  
      const empdata = {
        name,
        // other properties you might need to update
      };
  
      const url = `${apiUrl}/master/updateMaster/${empid}`;
      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(empdata),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Token}` 
         
        }
      });
  
      response = await response.json();
  
      if (response.status === 'success') {
        console.log('Data updated successfully');
        navigate('/Master');
       
      } else {
        console.log('Data not updated');
       
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    const token = localStorage.getItem('Token');
    
    if (!token) {
      console.log(token);
      navigate('/');
    }
  }, [navigate]);
  return (
    <>
      {/* Main Header*/}
      <div className="main-header side-header sticky">
        <div className="main-container container-fluid">
          <div className="main-header-left">
            <Link to="/Firstpage">
              <img src={Logo} alt="Logo" style={{ width: '100px', height: '50px' }} />
            </Link>
            <div className="hor-logo">
              <a className="main-logo" href="index.html">
                <img
                  src="../assets/img/brand/logo.png"
                  className="header-brand-img desktop-logo"
                  alt="logo"
                />
                <img
                  src="../assets/img/brand/logo-light.png"
                  className="header-brand-img desktop-logo-dark"
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <div className="main-header-center">
            <div className="responsive-logo">
              <a href="index.html">
                <img
                  src="../assets/img/brand/logo.png"
                  className="mobile-logo"
                  alt="logo"
                />
              </a>
              <a href="index.html">
                <img
                  src="../assets/img/brand/logo-light.png"
                  className="mobile-logo-dark"
                  alt="logo"
                />
              </a>
            </div>
           
          </div>
          <div className="main-header-right">
            <button
              className="navbar-toggler navresponsive-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent-4"
              aria-controls="navbarSupportedContent-4"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fe fe-more-vertical header-icons navbar-toggler-icon" />
            </button>
            {/* Navresponsive closed */}
            <div className="navbar navbar-expand-lg  nav nav-item  navbar-nav-right responsive-navbar navbar-dark  ">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2 ms-auto">
                  {/* Search */}
                  <div className="dropdown header-search">
                    <a className="nav-link icon header-search">
                      <i className="fe fe-search header-icons" />
                    </a>
                    <div className="dropdown-menu">
                      <div className="main-form-search p-2">
                        <div className="input-group">
                          <div className="input-group-btn search-panel">
                            <select className="form-control select2">
                              <option label="All categories"> </option>
                              <option>Staff/Agent</option>
                              <option>Sales Lead</option>
                              <option>Subscriber</option>
                              <option>Cases/Incident</option>
                              <option>Lawyer</option>
                            </select>
                          </div>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search for anything..."
                          />
                          <button className="btn search-btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={20}
                              height={20}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-search"
                            >
                              <circle cx={11} cy={11} r={8} />
                              <line x1={21} y1={21} x2="16.65" y2="16.65" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Search */}
                  {/* Theme-Layout */}
                  <div className="dropdown d-flex main-header-theme">
                    <a className="nav-link icon layout-setting">
                      <span className="dark-layout">
                        <i className="fe fe-sun header-icons" />
                      </span>
                      <span className="light-layout">
                        <i className="fe fe-moon header-icons" />
                      </span>
                    </a>
                  </div>
                  {/* Theme-Layout */}
                  {/* Full screen */}
                  <div className="dropdown ">
                  <i class="bi bi-fullscreen">
                        <FullScreenToggle />
                      </i>
                  </div>
                  {/* Full screen */}
                  {/* Notification */}
                  <div className="dropdown main-header-notification">
                    <a className="nav-link icon" href="">
                      <i className="fe fe-bell header-icons" />
                      <span className="badge bg-danger nav-link-badge">4</span>
                    </a>
                    <div className="dropdown-menu">
                      <div className="header-navheading">
                        <p className="main-notification-text">
                          You have 1 unread notification
                          <span className="badge bg-pill bg-primary ms-3">
                            View all
                          </span>
                        </p>
                      </div>
                      <div className="main-notification-list">
                        <div className="media new">
                          <div className="main-img-user online">
                            <img alt="avatar" src="../assets/img/users/5.jpg" />
                          </div>
                          <div className="media-body">
                            <p>
                              Congratulate <strong>Olivia James</strong> for New
                              template start
                            </p>
                            <span>Oct 15 12:32pm</span>
                          </div>
                        </div>
                        <div className="media">
                          <div className="main-img-user">
                            <img alt="avatar" src="../assets/img/users/2.jpg" />
                          </div>
                          <div className="media-body">
                            <p>
                              <strong>Joshua Gray</strong> New Message Received
                            </p>
                            <span>Oct 13 02:56am</span>
                          </div>
                        </div>
                        <div className="media">
                          <div className="main-img-user online">
                            <img alt="avatar" src="../assets/img/users/3.jpg" />
                          </div>
                          <div className="media-body">
                            <p>
                              <strong>Elizabeth Lewis</strong> added new schedule
                              realease
                            </p>
                            <span>Oct 12 10:40pm</span>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown-footer">
                        <a href="javascript:void(0)">View All Notifications</a>
                      </div>
                    </div>
                  </div>
                  {/* Notification */}
                  {/* Messages */}
                 
                  {/* Messages */}
                  {/* Profile */}
                  <MainPage />
                  {/* Profile */}
                  {/* Sidebar */}
                
                  {/* Sidebar */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Main Header*/}
        {/* Sidemenu */}
        <Prince />
        {/* End Sidemenu */}
        {/* Main Content*/}
        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">Add Master</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Internal Staff </a>
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    
                  </div>
                </div>
              </div>
          
              <div className="row row-sm">
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3"></h6>
                      </div>
                      <div className="row row-sm">

                        <div className="col-sm-4  form-group">
                          <label className="form-label">Name</label>
                          <input type="text" className="form-control"
                            name="name"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}

                          />
                        </div>

                        <div className="col-lg-4 form-group">

                          <div className="input-group">

                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}

              {/* End Row */}
              <div className="row row-sm">
                <div className="col-12 mb-3">
                  <button

                    className="btn btn-primary"
                    type="submit"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
        {/* End Sidebar */}
      </div>

    </>

  )
}

export default AddMaster