import React, { useState, useEffect } from "react";
import { json, useNavigate } from "react-router-dom";
import NewLeads from "../Pages/Sales CRM/NewLeads";

function Assigned() {

  const [allLeads, setAllLeads] = useState([])

  const apiUrl = process.env.REACT_APP_URL;
  console.log(apiUrl);


  const Token = localStorage.getItem('Token');
  console.log('Token:', Token);

  const navigate = useNavigate()

  const loadcontent = (id) => {
    console.log(id)
      ; // Check the value of id
    navigate(`/DatelsLeads/${id}`);
  };
  const fetchAllLead = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Token}`,
        'Content-Type': 'application/json'
      },
    };

    fetch(`${apiUrl}/lead/getAllLead`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          if (Array.isArray(data.data)) {
            // Filter leads based on the "New" status
            const newLeadsData = data.data.filter((lead) => lead.status === 'Assigned');
            setAllLeads(newLeadsData);

           
          } else {
            console.error('API response does not contain leads array:', data);
          }
        } else {
          console.error('API request was not successful:', data);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchAllLead();
  }, []);



  return (
    <div>
      <div className="row">
        < NewLeads />
      </div>
      <div className="tab-pane actve row" style={{ marginTop: "350px" }}>
        <div className="table-responsive">
        {allLeads.length > 0 ? (
          <table className="table table-striped table-bordered text-nowrap mb-0">
            <thead>
              <tr>
                <th>
                  <label className="ckbox">
                    <input type="checkbox" defaultValue={5} />
                    <span />
                  </label>
                </th>
                <th>ID</th>
                <th>Photo</th>
                <th>Contact</th>
                <th>Company</th>
                <th>Requirement</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allLeads.map((user) => (
                
               
                 <tr key={user.id}>


                 <td>
                   <label className="ckbox">
                     <input type="checkbox" defaultValue={5} />
                     <span />
                   </label>
                 </td>
                 <td>
                   <p className="mb-0">
                     Source : IVR Call
                     <br />
                     LLD-{user.id}
                    
                   </p>
                 </td>
                 <td>
                   <img
                     alt="avatar"
                     className="rounded-circle me-3"
                     src="../assets/img/users/5.jpg"
                     style={{ width: 60 }}
                   />
                 </td>
                 <td>
                   <p className="mb-0">
                     {user.fullName}
                     <br />
                     Email: {user.email}
                     <br />
                     Phone: +91 {user.mobileNo}
                   </p>
                 </td>
                 <td>
                   <p className="mb-0">
                     Company Name: {user.companyName}

                     <br />
                     Company Email: {user.companyEmail}
                     <br />
                     Phone: +91 {user.companyPhone}
                     <br />
                     Company Website: {user.companyWebsite}
                   </p>
                 </td>
                 <td>
                   <p className="mb-0">

                     Quality: {user.quality}
                     <br />
                     Quantity: {user.quantity}
                     <br />

                     Date of delivery : {user.dateOfDelivery}
                     <br />
                     Port of Loading : {user.shipFrom}
                     <br />
                     Port of Destination : {user.shipTo}
                     <br />
                     Rate: {user.rate}
                   </p>
                 </td>
                 <td>
                   <a className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                     onClick={() => loadcontent(user.id)} >
                     Details
                   </a>
                   <br />
                   <a
                     // href=""
                     className="btn ripple btn-success btn-xs w-100 mb-1"
                   >
                     Edit Lead
                   </a>
                   <br />
                   <a
                     // href=""
                     className="btn ripple btn-info btn-xs w-100 mb-1"
                   >
                     Followup
                   </a>
                   <br />
                   
                   <a
                     // href=""
                     className="btn ripple btn-info btn-xs w-100 mb-1"
                   >
                     Assign
                   </a>

                   <br />
                   <a
                     // href=""
                     className="btn ripple btn-success btn-xs w-100 mb-1"
                   >
                     Lost
                   </a>

                   <br />
                   <a
                     // href=""
                     className="btn ripple btn-info btn-xs w-100"
                   >
                     Conveted to Sale
                   </a>
                 </td>
               </tr>
              ))}



            </tbody>
          </table>
            ) : (
              <p>No leads found.</p>
            )}
        </div>
      </div>
    </div>

  )
}

export default Assigned