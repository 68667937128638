
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginForm from './Components/LoginForm';
import NewCom from './Components/newCom';
import Assigned from './Components/Assigned';
import Firstpage from './Pages/Firstpage';
import Employee from './Employee';
import MainPage2 from './Components/MainPage2';
import Master from './Components/Master';
import AddMaster from './Components/AddMaster';
import MasterValue from './Components/MasterValue';
import MasterVal from './Components/MasterVal';
import DropdownMenu from './Components/DropdownMenu';
import Create from './Pages/From/Create'
import View from './Pages/From/View'
import AnimatedIcons from './Components/AnimatedIcons';
import Content2 from './Components/Content2';
import EditMaster from './Components/EditMaster'
import Content3 from './Components/Content3';
import AttendanceLogs from './Pages/Attendance Logs';
import EmployeeReportsList from './Pages/EmployeeReportsList';
import AllOrders from './Pages/Orders/AllOrders';
import BuyersList2 from './Pages/Buyers/BuyersList2';
import ManageMillers from './Pages/Millers/ManageMillers';
import ManageExporters from './Pages/Exporters/ManageExporters';
import ManageVendors from './Pages/Vendors/ManageVendors';
import VendorsEdit from './Pages/Vendors/VendorsEdit';
import EditMillers from './Pages/Millers/EditMillers';
import ExportersEdit from './Pages/Exporters/ExportersEdit';
import NewLeads from './Pages/Sales CRM/NewLeads';
import Reports from './Pages/Sales CRM/Reports';
import HrDashboard from './Hr View/Pages/HrDashboard';
import He from './Pages/Sales CRM/page/he';
import AddBuyers from './Pages/Buyers/AddBuyers/AddBuyers';
import DatelsLeads from './Pages/Sales CRM/DatelsLeads';
import EditBuyers from './Pages/Buyers/AddBuyers/EditBuyers';
import AddVendors from './Pages/Vendors/AddVendors';
import AddExpor2 from './Pages/Exporters/AddExpor2';
import AddExpor from './Pages/Exporters/AddExpor';
import AddMillers from './Pages/Millers/AddMillers';



function AppRoutes() {
  
  return (
    <Routes>
      <Route path="/" element={<LoginForm />} />
      <Route path="/NewCom/:status" element={<NewCom />} />
      {/* <Route path="/Assigned/:status" element={<Assigned />} /> */}
      <Route path="/Firstpage" element={<Firstpage />} />
      <Route path="/employee" element={<Employee />} />
      <Route path="/Create/:empid" element={<Create />} />
      <Route path="/View" element={<View />} />
      <Route path="/MainPage2" element={<MainPage2 />} />
      <Route path="/Master" element={<Master />} />
      <Route path="/MasterValue" element={<MasterValue />} />
      <Route path="/MasterVal" element={<MasterVal />} />
      <Route path="/AddMaster" element={<AddMaster />} />
      <Route path="/dropdown" element={<DropdownMenu />} />
      <Route path="/icons" element={<AnimatedIcons />} />
      <Route path="/Content2/:empid" element={<Content2 />} />
      <Route path="/EditMaster/:empid" element={<EditMaster />} />
      <Route path="/Content3/:empid" element={<Content3 />} />
      <Route path="/DatelsLeads/:empid" element={<DatelsLeads />} />
      <Route path="/EditMillers/:empid" element={<EditMillers />} />
      <Route path="/VendorsEdit/:empid" element={<VendorsEdit />} />
      <Route path="/ExportersEdit/:empid" element={<ExportersEdit />} />
      <Route path="/AttendanceLogs" element={<AttendanceLogs />} />
      <Route path="/EmployeeReportsList" element={<EmployeeReportsList />} />
      <Route path="/AllOrders" element={<AllOrders />} />
      <Route path="/BuyersList2" element={<BuyersList2 />} />
      <Route path="/ManageMillers" element={<ManageMillers />} />
      <Route path="/ManageExporters" element={<ManageExporters />} />
      <Route path="/ManageVendors" element={<ManageVendors />} />
      <Route path="/NewLeads" element={<NewLeads />} />
      <Route path="/Reports" element={<Reports />} />
      <Route path="/HrDashboard" element={<HrDashboard />} />
      <Route path="/he" element={<He />} />
      <Route path="/AddBuyers" element={<AddBuyers />} />
      <Route path="/EditBuyers/:empid " element={<EditBuyers  />} />
      <Route path="/AddVendors" element={<AddVendors />} />
      <Route path="/AddExpor2" element={<AddExpor2 />} />
      <Route path="/AddExpor" element={<AddExpor />} />
      <Route path="/AddMillers " element={<AddMillers  />} />
      
    </Routes>
  );
}

export default AppRoutes;
