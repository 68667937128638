import React, { useState, useEffect } from 'react';
import FullScreenToggle from '../Components/FullScreenToggle';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MainPage from '../Components/MainPage'
import Prince from '../Components/Prince'
import Logo from '../assets/img/brand/logo.png'
import Navbar from './Navbar';

const Firstpage = () => {

  const [startDate, setStartDate] = useState(new Date());
  const [userType, setUserType] = useState(1);
  const [employee, setEmployee] = useState('')
  const [employee2, setEmployee2] = useState({})
  const [employee3, setEmployee3] = useState({})
  const [employee4, setEmployee4] = useState({})
  const [employee5, setEmployee5] = useState({})
  const [employee6, setEmployee6] = useState({})
  const [employee7, setEmployee7] = useState({})
  const [employee8, setEmployee8] = useState({})
  const [employee9, setEmployee9] = useState({})
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  const { empid } = useParams();
  const handleDateChange = (date) => {
    setStartDate(date);
  };


  useEffect(() => {
    async function getEmpppp() {

      const url = `${apiUrl}/employee/employee`;


      const Token = localStorage.getItem("Token");
      console.log("Token " + Token);

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Auth: `Bearer ${Token}`
        },
      })
      response = await response.json();

      if (response.status === "success") {
        setEmployee5(response.data.leadDetails[0]);
        setEmployee4(response.data.leadDetails[1]);
        setEmployee6(response.data.leadDetails[2]);
        setEmployee7(response.data.leadDetails[3]);
        setEmployee8(response.data.leadDetails[4]);
        setEmployee9(response.data.leadDetails[5]);

        setRefresh(!refresh);

      }
    }
    getEmpppp();
  }, [refresh])
  // console.log(employee3);

  //get 
  useEffect(() => {
    async function getEmppp() {

      const url = `${apiUrl}/employee/employee`;


      const Token = localStorage.getItem("Token");
      console.log("Token " + Token);

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Auth: `Bearer ${Token}`
        },
      })
      response = await response.json();

      if (response.status === "success") {
        setEmployee3(response.data);


        // setRefresh(!refresh);

      }
    }
    getEmppp();
  }, [])
  console.log(employee3);

  //get Admin
  const apiUrl = process.env.REACT_APP_URL;
  console.log(apiUrl);
  useEffect(() => {
    async function getEmpp() {

      const url = `${apiUrl}/employee/employee`;


      const Token = localStorage.getItem("Token");
      console.log("Token " + Token);

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Auth: `Bearer ${Token}`
        },
      })
      response = await response.json();

      if (response.status === "success") {
        setEmployee2(response.data);
        setRefresh(!refresh);
      }
    }
    getEmpp();
  }, [refresh])
  console.log(employee2);

  useEffect(() => {
    async function getEmp() {

      const apiUrl = process.env.REACT_APP_URL;


      const Token = localStorage.getItem("Token");
      console.log("Token " + Token);

      let response = await fetch(`${apiUrl}/employee/employee`, {
        method: "POST",
        headers: {
          Auth: `Bearer ${Token}`
        },
      })
      response = await response.json();

      if (response.status === "success") {
        const amit = response.data.userType
        const amit2 = response.data.staffCount

        setEmployee(amit);
        setEmployee2(amit2)
        console.log(amit2)
      }
    }
    getEmp();
  }, [])

  console.log(employee);


  useEffect(() => {
    const token = localStorage.getItem('Token');

    if (!token) {
      console.log(token);
      navigate('/');
    }
  }, [navigate]);
  return (
    <>

      <div className="page ">
        {/* Main Header*/}
        <Navbar />

        <Prince />


        <div className="main-content  pt-0">

          <div className="main-container container-fluid ">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header" style={{ overflowY: 'auto' }}>
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Pwip Business Dashboard
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="">Home</a>
                    </li>
                    <li className="breadcrumb-item " >
                      ERP Dashboard
                    </li>
                  </ol>
                </div>
                {/* <div className="col-sm-6">
                  <div className="justify-content-center">
                    <div className="row">
                      <div className="col-md-4">
                        <div className=" mg-b-30">
                          <div className="input-group">
                            <div className="input-group-text border-end-0">
                              <i className="fe fe-calendar lh--9 op-6" />
                              <DatePicker
                                showYearDropdown
                                selected={startDate}
                                onChange={handleDateChange}
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mg-b-30">
                          <div className="input-group">
                            <div className="input-group-text border-end-0">
                              <i className="fe fe-calendar lh--9 op-6" />
                            </div>
                            <input
                              className="form-control"
                              placeholder="Month Range"
                              type="text"
                              id="datepicker-month"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className=" mg-b-30">
                          <div className="input-group">
                            <div className="input-group-text border-end-0">
                              <i className="fe fe-calendar lh--9 op-6" />
                            </div>
                            <input
                              className="form-control"
                              placeholder="Year Range"
                              type="text"
                              id="datepicker-year"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* End Page Header */}
              {/*Row*/}
              <div className="row row-sm">
                <div className="col-sm-12 col-lg-12 col-xl-8">
                  {/*Row*/}
                  <div className="row row-sm  mt-lg-4">
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                      <div className="card bg-primary custom-card card-box">
                        <div className="card-body p-4">
                          <div className="row align-items-center">
                            <div className="offset-xl-3 offset-sm-6 col-xl-8 col-sm-6 col-12 ">
                              <h4 className="d-flex  mb-1">
                                <span className="font-weight-bold text-white ">
                                  Hello {employee3.firstName}
                                </span>
                              </h4>
                              <p className="tx-white-7 mb-1">
                                You are logged in as super admin with full read
                                &amp; write permissions.
                              </p>
                            </div>
                            <img
                              src={employee3.profilePic}
                              alt="user-img"
                              style={{ width: 170, borderRadius: "100%", left: 26 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Row */}
                  {/*Row*/}
                  {employee === '1' && (
                    <div className="row ">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="card custom-card">
                          <div className="card-body">
                            <div className="card-item">
                              <div className="card-item-icon card-icon">
                                <svg
                                  className="text-primary"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enableBackground="new 0 0 24 24"
                                  height={24}
                                  viewBox="0 0 24 24"
                                  width={24}
                                >
                                  <g>
                                    <rect
                                      height={14}
                                      opacity=".3"
                                      width={14}
                                      x={5}
                                      y={5}
                                    />
                                    <g>
                                      <rect fill="none" height={24} width={24} />
                                      <g>
                                        <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                        <rect height={5} width={2} x={7} y={12} />
                                        <rect height={10} width={2} x={15} y={7} />
                                        <rect height={3} width={2} x={11} y={14} />
                                        <rect height={2} width={2} x={11} y={10} />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="card-item-title mb-2">
                                <label className="main-content-label tx-13 font-weight-bold mb-1">
                                  Total Staff
                                </label>
                              </div>
                              <div className="card-item-body">
                                <div className="card-item-stat">
                                  <h4 className="font-weight-light">{employee2.staffCount}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="card custom-card">
                          <div className="card-body">
                            <div className="card-item">
                              <div className="card-item-icon card-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height={24}
                                  viewBox="0 0 24 24"
                                  width={24}
                                >
                                  <path d="M0 0h24v24H0V0z" fill="none" />
                                  <path
                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33C19.38 15.49 20 13.82 20 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"
                                    opacity=".3"
                                  />
                                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
                                </svg>
                              </div>
                              <div className="card-item-title mb-2">
                                <label className="main-content-label tx-13 font-weight-bold mb-1">
                                  Total Millers
                                </label>
                              </div>
                              <div className="card-item-body">
                                <div className="card-item-stat">
                                  <h4 className="font-weight-light">{employee2.millersCount}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                        <div className="card custom-card">
                          <div className="card-body">
                            <div className="card-item">
                              <div className="card-item-icon card-icon">
                                <svg
                                  className="text-primary"
                                  xmlns="http://www.w3.org/2000/svg"
                                  height={24}
                                  viewBox="0 0 24 24"
                                  width={24}
                                >
                                  <path d="M0 0h24v24H0V0z" fill="none" />
                                  <path
                                    d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z"
                                    opacity=".3"
                                  />
                                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z" />
                                </svg>
                              </div>
                              <div className="card-item-title  mb-2">
                                <label className="main-content-label tx-13 font-weight-bold mb-1">
                                  Total Exporters
                                </label>
                              </div>
                              <div className="card-item-body">
                                <div className="card-item-stat">
                                  <h4 className="font-weight-light">{employee2.exporterCount}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="card custom-card">
                          <div className="card-body">
                            <div className="card-item">
                              <div className="card-item-icon card-icon">
                                <svg
                                  className="text-primary"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enableBackground="new 0 0 24 24"
                                  height={24}
                                  viewBox="0 0 24 24"
                                  width={24}
                                >
                                  <g>
                                    <rect
                                      height={14}
                                      opacity=".3"
                                      width={14}
                                      x={5}
                                      y={5}
                                    />
                                    <g>
                                      <rect fill="none" height={24} width={24} />
                                      <g>
                                        <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                        <rect height={5} width={2} x={7} y={12} />
                                        <rect height={10} width={2} x={15} y={7} />
                                        <rect height={3} width={2} x={11} y={14} />
                                        <rect height={2} width={2} x={11} y={10} />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="card-item-title mb-2">
                                <label className="main-content-label tx-13 font-weight-bold mb-1">
                                  Total Buyers
                                </label>
                              </div>
                              <div className="card-item-body">
                                <div className="card-item-stat">
                                  <h4 className="font-weight-light">{employee2.buyerCount}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="card custom-card">
                          <div className="card-body">
                            <div className="card-item">
                              <div className="card-item-icon card-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height={24}
                                  viewBox="0 0 24 24"
                                  width={24}
                                >
                                  <path d="M0 0h24v24H0V0z" fill="none" />
                                  <path
                                    d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33C19.38 15.49 20 13.82 20 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"
                                    opacity=".3"
                                  />
                                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
                                </svg>
                              </div>
                              <div className="card-item-title mb-2">
                                <label className="main-content-label tx-13 font-weight-bold mb-1">
                                  Total Vendors
                                </label>
                              </div>
                              <div className="card-item-body">
                                <div className="card-item-stat">
                                  <h4 className="font-weight-light">{employee2.vendorCount}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/*End row*/}
                  {/*row*/}
                  <div className="row row-sm">
                    <div className="col-lg-12">
                      <div className="card custom-card mg-b-20">
                        <div className="card-body">
                          <div className="card-header border-bottom-0 pt-0 ps-0 pe-0 ">
                            <div className="row">

                              <Link to="/newCom/New" className="col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-2">
                                <div className="card custom-card bg-light mb-0">
                                  <div className="card-body">
                                    <div className="card-item">
                                      <div className="card-item-icon card-icon">
                                        <svg
                                          className="text-primary"
                                          xmlns="http://www.w3.org/2000/svg"
                                          enableBackground="new 0 0 24 24"
                                          height={24}
                                          viewBox="0 0 24 24"
                                          width={24}
                                        >
                                          <g>
                                            <rect
                                              height={14}
                                              opacity=".3"
                                              width={14}
                                              x={5}
                                              y={5}
                                            />
                                            <g>
                                              <rect
                                                fill="none"
                                                height={24}
                                                width={24}
                                              />
                                              <g>
                                                <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                                <rect
                                                  height={5}
                                                  width={2}
                                                  x={7}
                                                  y={12}
                                                />
                                                <rect
                                                  height={10}
                                                  width={2}
                                                  x={15}
                                                  y={7}
                                                />
                                                <rect
                                                  height={3}
                                                  width={2}
                                                  x={11}
                                                  y={14}
                                                />
                                                <rect
                                                  height={2}
                                                  width={2}
                                                  x={11}
                                                  y={10}
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      </div>
                                      <div className="card-item-title mb-2">
                                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                                          Total Leads
                                        </label>
                                      </div>
                                      <div className="card-item-body">
                                        <div className="card-item-stat">
                                          <h4 className="font-weight-light">
                                            {employee3.leadCount}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </Link>
                              <Link to="/newCom/New" className="col-sm-12 col-md-6 col-lg-6 col-xl-3 ">
                                <div className="card custom-card bg-light mb-0">
                                  <div className="card-body">
                                    <div className="card-item">
                                      <div className="card-item-icon card-icon">
                                        <svg
                                          className="text-primary"
                                          xmlns="http://www.w3.org/2000/svg"
                                          enableBackground="new 0 0 24 24"
                                          height={24}
                                          viewBox="0 0 24 24"
                                          width={24}
                                        >
                                          <g>
                                            <rect
                                              height={14}
                                              opacity=".3"
                                              width={14}
                                              x={5}
                                              y={5}
                                            />
                                            <g>
                                              <rect
                                                fill="none"
                                                height={24}
                                                width={24}
                                              />
                                              <g>
                                                <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                                <rect
                                                  height={5}
                                                  width={2}
                                                  x={7}
                                                  y={12}
                                                />
                                                <rect
                                                  height={10}
                                                  width={2}
                                                  x={15}
                                                  y={7}
                                                />
                                                <rect
                                                  height={3}
                                                  width={2}
                                                  x={11}
                                                  y={14}
                                                />
                                                <rect
                                                  height={2}
                                                  width={2}
                                                  x={11}
                                                  y={10}
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      </div>
                                      <div className="card-item-title mb-2">
                                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                                          New Leads
                                        </label>
                                      </div>
                                      <div className="card-item-body">
                                        <div className="card-item-stat">
                                          <h4 className="font-weight-light">
                                            {employee3.newCount}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </Link>
                              <Link to='/newCom/Followup' className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                <div className="card custom-card  bg-light mb-0">
                                  <div className="card-body">
                                    <div className="card-item">
                                      <div className="card-item-icon card-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height={24}
                                          viewBox="0 0 24 24"
                                          width={24}
                                        >
                                          <path d="M0 0h24v24H0V0z" fill="none" />
                                          <path
                                            d="M12 4c-4.41 0-8 3.59-8 8 0 1.82.62 3.49 1.64 4.83 1.43-1.74 4.9-2.33 6.36-2.33s4.93.59 6.36 2.33C19.38 15.49 20 13.82 20 12c0-4.41-3.59-8-8-8zm0 9c-1.94 0-3.5-1.56-3.5-3.5S10.06 6 12 6s3.5 1.56 3.5 3.5S13.94 13 12 13z"
                                            opacity=".3"
                                          />
                                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
                                        </svg>
                                      </div>
                                      <div className="card-item-title mb-2">
                                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                                          Total lead FollowUp
                                        </label>
                                      </div>
                                      <div className="card-item-body">
                                        <div className="card-item-stat">
                                          <h4 className="font-weight-light">{employee3.followUpCount}</h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <Link to='/newCom/Quotations' className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                <div className="card custom-card  bg-light mb-0">
                                  <div className="card-body">
                                    <div className="card-item">
                                      <div className="card-item-icon card-icon">
                                        <svg
                                          className="text-primary"
                                          xmlns="http://www.w3.org/2000/svg"
                                          height={24}
                                          viewBox="0 0 24 24"
                                          width={24}
                                        >
                                          <path d="M0 0h24v24H0V0z" fill="none" />
                                          <path
                                            d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z"
                                            opacity=".3"
                                          />
                                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z" />
                                        </svg>
                                      </div>
                                      <div className="card-item-title  mb-2">
                                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                                          {/* Total In Process */}
                                          Quotation Count
                                        </label>
                                      </div>
                                      <div className="card-item-body">
                                        <div className="card-item-stat">
                                          <h4 className="font-weight-light">{employee3.quotationCount}</h4>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </Link>

                              <Link to='/newCom/Pre-proforma' className="col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-3">
                                <div className="card custom-card  bg-light mb-0">
                                  <div className="card-body">
                                    <div className="card-item">
                                      <div className="card-item-icon card-icon">
                                        <svg
                                          className="text-primary"
                                          xmlns="http://www.w3.org/2000/svg"
                                          enableBackground="new 0 0 24 24"
                                          height={24}
                                          viewBox="0 0 24 24"
                                          width={24}
                                        >
                                          <g>
                                            <rect
                                              height={14}
                                              opacity=".3"
                                              width={14}
                                              x={5}
                                              y={5}
                                            />
                                            <g>
                                              <rect
                                                fill="none"
                                                height={24}
                                                width={24}
                                              />
                                              <g>
                                                <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                                <rect
                                                  height={5}
                                                  width={2}
                                                  x={7}
                                                  y={12}
                                                />
                                                <rect
                                                  height={10}
                                                  width={2}
                                                  x={15}
                                                  y={7}
                                                />
                                                <rect
                                                  height={3}
                                                  width={2}
                                                  x={11}
                                                  y={14}
                                                />
                                                <rect
                                                  height={2}
                                                  width={2}
                                                  x={11}
                                                  y={10}
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      </div>
                                      <div className="card-item-title mb-2">
                                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                                          Proforma Count

                                        </label>
                                      </div>
                                      <div className="card-item-body">
                                        <div className="card-item-stat">
                                          <h4 className="font-weight-light">{employee3.proformaCount}</h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <Link to='/newCom/Assigned' className="col-sm-12 col-md-12 col-lg-12 col-xl-3 mb-3">
                                <div className="card custom-card  bg-light mb-0">
                                  <div className="card-body">
                                    <div className="card-item">
                                      <div className="card-item-icon card-icon">
                                        <svg
                                          className="text-primary"
                                          xmlns="http://www.w3.org/2000/svg"
                                          height={24}
                                          viewBox="0 0 24 24"
                                          width={24}
                                        >
                                          <path d="M0 0h24v24H0V0z" fill="none" />
                                          <path
                                            d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z"
                                            opacity=".3"
                                          />
                                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z" />
                                        </svg>
                                      </div>
                                      <div className="card-item-title  mb-2">
                                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                                          {/* Total In Process */}
                                          Assigned Count
                                        </label>
                                      </div>
                                      <div className="card-item-body">
                                        <div className="card-item-stat">
                                          <h4 className="font-weight-light">{employee3.assignedCount}</h4>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <Link to='/newCom/Convertedtosale' className="col-sm-12 col-md-12 col-lg-12 col-xl-3 mb-3">
                                <div className="card custom-card  bg-light mb-0">
                                  <div className="card-body">
                                    <div className="card-item">
                                      <div className="card-item-icon card-icon">
                                        <svg
                                          className="text-primary"
                                          xmlns="http://www.w3.org/2000/svg"
                                          height={24}
                                          viewBox="0 0 24 24"
                                          width={24}
                                        >
                                          <path d="M0 0h24v24H0V0z" fill="none" />
                                          <path
                                            d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z"
                                            opacity=".3"
                                          />
                                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z" />
                                        </svg>
                                      </div>
                                      <div className="card-item-title  mb-2">
                                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                                          {/* Total In Process */}
                                          Coverted To Sale
                                        </label>
                                      </div>
                                      <div className="card-item-body">
                                        <div className="card-item-stat">
                                          <h4 className="font-weight-light">{employee3.covertedToSaleCount}</h4>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <Link to='/newCom/LOST' className="col-sm-12 col-md-12 col-lg-12 col-xl-3 mb-3">
                                <div className="card custom-card  bg-light mb-0">
                                  <div className="card-body">
                                    <div className="card-item">
                                      <div className="card-item-icon card-icon">
                                        <svg
                                          className="text-primary"
                                          xmlns="http://www.w3.org/2000/svg"
                                          height={24}
                                          viewBox="0 0 24 24"
                                          width={24}
                                        >
                                          <path d="M0 0h24v24H0V0z" fill="none" />
                                          <path
                                            d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1.23 13.33V19H10.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V5h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77z"
                                            opacity=".3"
                                          />
                                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z" />
                                        </svg>
                                      </div>
                                      <div className="card-item-title  mb-2">
                                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                                          {/* Total In Process */}
                                          Lost Count
                                        </label>
                                      </div>
                                      <div className="card-item-body">
                                        <div className="card-item-stat">
                                          <h4 className="font-weight-light">{employee3.lostCount}</h4>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* col end */}
                  </div>
                  {/* Row end */}
                  {/*row*/}


                </div>
                {/* col end */}

                <div className="col-sm-12 col-lg-12 col-xl-4 mt-xl-4">
                  <div className="card custom-card card-dashboard-calendar pb-0">
                    <label className="main-content-label mb-2 pt-1">
                      PWIP Updates
                    </label>
                    <span className="d-block tx-12 mb-2 text-muted">
                      What's new today in PWIP
                    </span>
                    <table className="table table-hover m-b-0 transcations mt-2">
                      <tbody>
                        <tr>
                          <td className="wd-5p">
                            <div className="main-img-user avatar-md">

                              <Link to='/newCom/Convertedtosale'>
                                <svg
                                  className="text-primary"
                                  xmlns="http://www.w3.org/2000/svg"
                                  enableBackground="new 0 0 24 24"
                                  height={24}
                                  viewBox="0 0 24 24"
                                  width={24}
                                >
                                  <g>
                                    <rect
                                      height={14}
                                      opacity=".3"
                                      width={14}
                                      x={5}
                                      y={5}
                                    />
                                    <g>
                                      <rect
                                        fill="none"
                                        height={24}
                                        width={24}
                                      />
                                      <g>
                                        <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                        <rect
                                          height={5}
                                          width={2}
                                          x={7}
                                          y={12}
                                        />
                                        <rect
                                          height={10}
                                          width={2}
                                          x={15}
                                          y={7}
                                        />
                                        <rect
                                          height={3}
                                          width={2}
                                          x={11}
                                          y={14}
                                        />
                                        <rect
                                          height={2}
                                          width={2}
                                          x={11}
                                          y={10}
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </Link>


                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-middle ms-3">
                              <div className="d-inline-block">
                                <Link to={`/newCom/Convertedtosale`}>
                                  <h6 className="mb-1">
                                    {employee5?.fullName ? employee5.fullName : 'hello'}
                                  </h6>
                                </Link>


                                <p className="mb-0 tx-13 text-muted">
                                  <Link to={`/newCom/Convertedtosale`}>
                                    {employee5?.mobileNo ? employee5.mobileNo : '1234567'}
                                  </Link>
                                </p>

                              </div>
                            </div>
                          </td>
                          <td className="text-end">
                            <div className="d-inline-block">

                              <p className="mb-0 tx-11 text-muted">
                                <Link to={`/newCom/Convertedtosale`}>
                                  {employee5?.status ? employee5.status : 'sale'}
                                </Link>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="wd-5p">
                            <div className="main-img-user avatar-md">
                              <Link to='/newCom/Assigned'>
                              <svg
                                className="text-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                enableBackground="new 0 0 24 24"
                                height={24}
                                viewBox="0 0 24 24"
                                width={24}
                              >
                                <g>
                                  <rect
                                    height={14}
                                    opacity=".3"
                                    width={14}
                                    x={5}
                                    y={5}
                                  />
                                  <g>
                                    <rect
                                      fill="none"
                                      height={24}
                                      width={24}
                                    />
                                    <g>
                                      <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                      <rect
                                        height={5}
                                        width={2}
                                        x={7}
                                        y={12}
                                      />
                                      <rect
                                        height={10}
                                        width={2}
                                        x={15}
                                        y={7}
                                      />
                                      <rect
                                        height={3}
                                        width={2}
                                        x={11}
                                        y={14}
                                      />
                                      <rect
                                        height={2}
                                        width={2}
                                        x={11}
                                        y={10}
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-middle ms-3">
                              <div className="d-inline-block">
                                <h6 className="mb-1">
                                  <Link to={`/newCom/Assigned`}>
                                    {employee4?.fullName ? employee4.fullName : 'hello'}
                                  </Link>
                                </h6>
                                <p className="mb-0 tx-13 text-muted">
                                  <Link to={`/newCom/Assigned`}>
                                    {employee4?.mobileNo ? employee4.mobileNo : '1234567'}
                                  </Link>
                                </p>

                              </div>
                            </div>
                          </td>
                          <td className="text-end">
                            <div className="d-inline-block">
                              {/* <h6 className="mb-2 tx-15 font-weight-semibold">
                                40
                                <i className="fas fa-level-up-alt ms-2 text-success m-l-10" />
                              </h6> */}
                              <p className="mb-0 tx-11 text-muted">
                                <Link to={`/newCom/Assigned`}>
                                  {employee4?.status ? employee4.status : 'sale'}
                                </Link>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="wd-5p">
                            <div className="main-img-user avatar-md">
                              <Link to='/newCom/Assigned'>
                              <svg
                                className="text-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                enableBackground="new 0 0 24 24"
                                height={24}
                                viewBox="0 0 24 24"
                                width={24}
                              >
                                <g>
                                  <rect
                                    height={14}
                                    opacity=".3"
                                    width={14}
                                    x={5}
                                    y={5}
                                  />
                                  <g>
                                    <rect
                                      fill="none"
                                      height={24}
                                      width={24}
                                    />
                                    <g>
                                      <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                      <rect
                                        height={5}
                                        width={2}
                                        x={7}
                                        y={12}
                                      />
                                      <rect
                                        height={10}
                                        width={2}
                                        x={15}
                                        y={7}
                                      />
                                      <rect
                                        height={3}
                                        width={2}
                                        x={11}
                                        y={14}
                                      />
                                      <rect
                                        height={2}
                                        width={2}
                                        x={11}
                                        y={10}
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-middle ms-3">
                              <div className="d-inline-block">
                                <h6 className="mb-1">
                                  <Link to={`/newCom/Assigned`}>
                                    {employee6?.fullName ? employee6.fullName : 'hello'}
                                  </Link>
                                </h6>
                                <p className="mb-0 tx-13 text-muted">
                                  <Link to={`/newCom/Assigned`}>
                                    {employee6?.mobileNo ? employee6.mobileNo : '1234567'}
                                  </Link>
                                </p>

                              </div>
                            </div>
                          </td>
                          <td className="text-end">
                            <div className="d-inline-block">

                              <p className="mb-0 tx-11 text-muted">
                                <Link to={`/newCom/Assigned`}>
                                  {employee6?.status ? employee6.status : 'sale'}
                                </Link>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="wd-5p">
                            <div className="main-img-user avatar-md">
                              <Link to='/newCom/Followup'>
                              <svg
                                className="text-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                enableBackground="new 0 0 24 24"
                                height={24}
                                viewBox="0 0 24 24"
                                width={24}
                              >
                                <g>
                                  <rect
                                    height={14}
                                    opacity=".3"
                                    width={14}
                                    x={5}
                                    y={5}
                                  />
                                  <g>
                                    <rect
                                      fill="none"
                                      height={24}
                                      width={24}
                                    />
                                    <g>
                                      <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                      <rect
                                        height={5}
                                        width={2}
                                        x={7}
                                        y={12}
                                      />
                                      <rect
                                        height={10}
                                        width={2}
                                        x={15}
                                        y={7}
                                      />
                                      <rect
                                        height={3}
                                        width={2}
                                        x={11}
                                        y={14}
                                      />
                                      <rect
                                        height={2}
                                        width={2}
                                        x={11}
                                        y={10}
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-middle ms-3">
                              <div className="d-inline-block">
                                <h6 className="mb-1">
                                  <Link to={`/newCom/Followup`}>
                                    {employee7?.fullName ? employee7.fullName : 'hello'}
                                  </Link>
                                </h6>
                                <p className="mb-0 tx-13 text-muted">
                                  <Link to={`/newCom/Followup`}>
                                    {employee7?.mobileNo ? employee7.mobileNo : '1234567'}
                                  </Link>
                                </p>

                              </div>
                            </div>
                          </td>
                          <td className="text-end">
                            <div className="d-inline-block">

                              <p className="mb-0 tx-11 text-muted">
                                <Link to={`/newCom/Followup`}>
                                  {employee7?.status ? employee7.status : 'sale'}
                                </Link>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="wd-5p">
                            <div className="main-img-user avatar-md">
                              <Link to='/newCom/Convertedtosale'>
                              <svg
                                className="text-primary"
                                xmlns="http://www.w3.org/2000/svg"
                                enableBackground="new 0 0 24 24"
                                height={24}
                                viewBox="0 0 24 24"
                                width={24}
                              >
                                <g>
                                  <rect
                                    height={14}
                                    opacity=".3"
                                    width={14}
                                    x={5}
                                    y={5}
                                  />
                                  <g>
                                    <rect
                                      fill="none"
                                      height={24}
                                      width={24}
                                    />
                                    <g>
                                      <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
                                      <rect
                                        height={5}
                                        width={2}
                                        x={7}
                                        y={12}
                                      />
                                      <rect
                                        height={10}
                                        width={2}
                                        x={15}
                                        y={7}
                                      />
                                      <rect
                                        height={3}
                                        width={2}
                                        x={11}
                                        y={14}
                                      />
                                      <rect
                                        height={2}
                                        width={2}
                                        x={11}
                                        y={10}
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-middle ms-3">
                              <div className="d-inline-block">
                                <h6 className="mb-1">
                                  <Link to={`/newCom/Convertedtosale`}>
                                    {employee8?.fullName ? employee8.fullName : 'hello'}
                                  </Link>
                                </h6>
                                <p className="mb-0 tx-13 text-muted">
                                  <Link to={`/newCom/Convertedtosale`}>
                                    {employee8?.mobileNo ? employee8.mobileNo : '1234567'}

                                  </Link>
                                </p>

                              </div>
                            </div>
                          </td>
                          <td className="text-end">
                            <div className="d-inline-block">

                              <p className="mb-0 tx-11 text-muted">
                                <Link to={`/newCom/Convertedtosale`}>
                                  {employee8?.status ? employee8.status : 'sale'}
                                </Link>
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>

        {/* Main Footer*/}
        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2023 <a href="javascript:void(0)">PWIP</a>. Designed
                  by <a href="http://webkype.com/">Webkype.com</a> All rights
                  reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="#top" id="back-to-top">
        <i className="fs fs-arrow-up" />
      </a>
    </>
  )
}

export default Firstpage