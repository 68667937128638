import axios from 'axios';
import React, { useState, useEffect } from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Navbar from '../../Pages/Navbar';
import Prince from '../../Components/Prince'
import { Link, useNavigate, useParams } from 'react-router-dom';

const ExportersEdit = () => {

    const { empid } = useParams();
    const navigate = useNavigate();


    const initialFormData = {
        profilePhoto: '',
        fullName: '',
        email: '',
        companyName: '',
        companyEmail: '',
        companyPhone: '',
        companyWebsite: '',
        companyAddress: '',
        companyGstNo: '',
        billingAddressCountry: '',
        billingAddressState: '',
        billingAddressCity: '',
        billingAddressArea: '',
        billingAddressLane: '',
        billingPinCode: '',
        sameAsBillingAddress: false,
        shippingAddressCountry: '',
        shippingAddressState: '',
        shippingAddressCity: '',
        shippingAddressArea: '',
        shippingAddressLane: '',
        shippingPinCode: '',
        aadhaarNo: '',
        panNo: '',
        gstNo: '',
        drivingLicence: '',
        aadhaarUpload: '',
        panUpload: '',
        cheque: '',
        accountNo: '',
        accountName: '',
        bankName: '',
        ifsc: '',

        // Add other fields as needed
    };

    const [formData, setFormData] = useState(initialFormData);
    const [countryOptions, setCountryOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [stateId, setStateId] = useState('');
    const [countryOptions8, setCountryOptions8] = useState([]);
    const [cityOptions8, setCityOptions8] = useState([]);
    const [countryId8, setCountryId8] = useState('');
    const [stateId8, setStateId8] = useState('');
    const [disableUpdate, setDisableUpdate] = useState(false);
    const [disableUpdate2, setDisableUpdate2] = useState(false);
    const [disableInput, setDisableInput] = useState(true);
    const [disableInput2, setDisableInput2] = useState(true);
    const [profilePicFile, setProfilePicFile] = useState(null);
    const [countrId, setCountrId] = useState('');
    const [statId, setStatId] = useState('');
    const [stateOptions8, setStateOptions8] = useState([]);
    const [errors, setErrors] = useState({});

    const [profilePic, setProfilePic] = useState(null);
    const apiUrl = process.env.REACT_APP_URL;

    const disableField = disableInput ? { disabled: 'disabled' } : {};
    const disableField2 = disableInput2 ? { disabled: 'disabled' } : {};
    const handleInputChange = (e) => {
        if (!disableUpdate) {
            setFormData({ ...formData, email: e.target.value });
        }
    };


    const handleCheckClick = () => {

        setDisableUpdate((prevDisable) => !prevDisable);
    };

    const handleInputChange2 = (e) => {
        if (!disableUpdate2) {
            setFormData({ ...formData, mobileNumber: e.target.value });
        }
    };

    const handleCheckClick2 = () => {
        // Toggling the disableUpdate state on check click
        setDisableUpdate2((prevDisable) => !prevDisable);
    };

    const handlePrint = () => {
        // Create a new jsPDF instance
        const pdf = new jsPDF();

        // Get the content to be printed (in this case, the entire component)
        const content = document.getElementById('printableContent');

        // Use html2canvas to capture the content as an image
        html2canvas(content).then((canvas) => {
            // Convert the canvas image to a data URL
            const imgData = canvas.toDataURL('image/png');

            // Add the image to the PDF
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust dimensions as needed

            // Save or open the PDF
            pdf.save('form_data.pdf');
            // Alternatively, you can open the print dialog
            // pdf.autoPrint();
            // window.open(pdf.output('bloburl'), '_blank');
        });
    };
    let oldSelectedState = [];
    let oldSelectedCity = [];
    let oldSelectedState2 = [];
    let oldSelectedCity2 = [];


    useEffect(() => {
        const Token = localStorage.getItem('Token');
        const fetchUser = async () => {

            try {
                const url = `${apiUrl}/exporter/exporter/${empid}`;
                let result = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                        'Content-Type': 'application/json',
                    },
                });

                result = await result.json();
                const { data } = result;
                // console.log('Fetched Data:', data);
                const trimmedData = Object.keys(data).reduce((acc, key) => {
                    if (typeof data[key] === 'string') {
                        acc[key] = data[key].trim();
                    } else {
                        acc[key] = data[key];
                    }
                    return acc;
                }, {});

                console.log('Fetched Data:', trimmedData);
                const oldSelectedStateResponse = await fetch(`${apiUrl}/employee/allStates/${trimmedData.billingAddressCountry}`);
                oldSelectedState = await oldSelectedStateResponse.json();
                const oldSelectedCityResponse = await fetch(`${apiUrl}/employee/allcities/${trimmedData.billingAddressState}`);
                oldSelectedCity = await oldSelectedCityResponse.json();
                // console.log(oldSelectedState2.data.find(oldState2 => oldState2.id === +trimmedData.permanentAddressCountry));
                // console.log(oldSelectedCity.data.find(oldCity2 => oldCity2.id === +trimmedData.permanentAddressCountry));
                // PERMANENT
                const oldSelectedStateResponse2 = await fetch(`${apiUrl}/employee/allStates/${trimmedData.shippingAddressCountry}`);
                oldSelectedState2 = await oldSelectedStateResponse2.json();
                const oldSelectedCityResponse2 = await fetch(`${apiUrl}/employee/allcities/${trimmedData.shippingAddressState}`);
                oldSelectedCity2 = await oldSelectedCityResponse2.json();
                console.log(oldSelectedState2, oldSelectedCity2, '<------');
                // console.log(oldSelectedState2.data.find(oldState2 => oldState2.id === +trimmedData.permanentAddressCountry));
                // console.log(oldSelectedCity.data.find(oldCity2 => oldCity2.id === +trimmedData.permanentAddressCountry));


                setCityOptions(oldSelectedCity.data);
                setStateOptions(oldSelectedState.data);

                // PERMANENT
                setCityOptions8(oldSelectedCity2.data);
                setStateOptions8(oldSelectedState2.data);

                setFormData((prevFormData) => ({
                    ...prevFormData,

                    profilePhoto: data.profilePhoto,
                    fullName: trimmedData.fullName,
                    email: trimmedData.email,
                    mobileNumber: trimmedData.mobileNumber,
                    companyName: trimmedData.companyName,
                    companyEmail: trimmedData.companyEmail,
                    companyPhone: trimmedData.companyPhone,
                    companyWebsite: trimmedData.companyWebsite,
                    companyAddress: trimmedData.companyAddress,
                    companyGstNo: trimmedData.companyGstNo,
                    billingAddressCountry: trimmedData.billingAddressCountry,
                    billingAddressState: trimmedData.billingAddressState,
                    billingAddressCity: trimmedData.billingAddressCity,
                    billingAddressArea: trimmedData.billingAddressArea,
                    billingAddressLane: trimmedData.billingAddressLane,
                    billingPinCode: trimmedData.billingPinCode,
                    shippingAddressCountry: trimmedData.shippingAddressCountry,
                    shippingAddressState: trimmedData.shippingAddressState,
                    shippingAddressCity: trimmedData.shippingAddressCity,
                    shippingAddressArea: trimmedData.shippingAddressArea,
                    shippingAddressLane: trimmedData.shippingAddressLane,
                    shippingPinCode: trimmedData.shippingPinCode,
                    aadhaarNo: trimmedData.aadhaarNo,
                    panNo: trimmedData.panNo,
                    gstNo: trimmedData.gstNo,
                    accountNo: trimmedData.accountNo,
                    accountName: trimmedData.accountName,
                    bankName: trimmedData.bankName,
                    ifsc: trimmedData.ifsc,


                }));

                // Update the state with fetched data

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchUser();
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        if (file) {
          if (file.type.startsWith('image/')) {
            // Set profilePicFile for image files
            setProfilePicFile(file);
            setFormData((prevData) => ({
              ...prevData,
              profilePhoto: file,
    
    
            }));
          } else if (file.type === 'application/pdf') {
            // Set aadhaarUpload for PDF files
            setFormData((prevData) => ({
              ...prevData,
              aadhaarUpload: file,
             
            }));
          } else {
            console.log('Unsupported file type');
          }
        } else {
          console.log('No file selected');
        }
      };
      const handleFileChange2 = (e) => {
        const file = e.target.files[0];
    
        if (file) {
          if (file.type.startsWith('image/')) {
            // Set profilePicFile for image files
            setProfilePicFile(file);
            setFormData((prevData) => ({
              ...prevData,
              profilePhoto: file,
    
    
            }));
          } else if (file.type === 'application/pdf') {
            // Set aadhaarUpload for PDF files
            setFormData((prevData) => ({
              ...prevData,
            
              panUpload: file,
             
            }));
          } else {
            console.log('Unsupported file type');
          }
        } else {
          console.log('No file selected');
        }
      };
      const handleFileChange3 = (e) => {
        const file = e.target.files[0];
    
        if (file) {
          if (file.type.startsWith('image/')) {
            // Set profilePicFile for image files
            setProfilePicFile(file);
            setFormData((prevData) => ({
              ...prevData,
              profilePhoto: file,
    
    
            }));
          } else if (file.type === 'application/pdf') {
            // Set aadhaarUpload for PDF files
            setFormData((prevData) => ({
              ...prevData,
            
              drivingLicence: file,
             
            }));
          } else {
            console.log('Unsupported file type');
          }
        } else {
          console.log('No file selected');
        }
      };
      const handleFileChange4 = (e) => {
        const file = e.target.files[0];
    
        if (file) {
          if (file.type.startsWith('image/')) {
            // Set profilePicFile for image files
            setProfilePicFile(file);
            setFormData((prevData) => ({
              ...prevData,
              profilePhoto: file,
    
    
            }));
          } else if (file.type === 'application/pdf') {
            // Set aadhaarUpload for PDF files
            setFormData((prevData) => ({
              ...prevData,
            
              cheque: file,
             
            }));
          } else {
            console.log('Unsupported file type');
          }
        } else {
          console.log('No file selected');
        }
      };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        const newErrors = { ...errors };

        if (!value.trim()) {

        } else if (
            name === 'fullName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
        ) {
            newErrors[name] = `Please enter a valid Full Name with only alphabetical characters`;
        } else if (
            name === 'companyName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
        ) {
            newErrors[name] = `Please enter a valid Company Name with only alphabetical characters`;
        } else if (
            name === 'email' && (!value.trim() || !emailRegex.test(value))
        ) {
            newErrors[name] = 'Please enter a valid email address';
        }
        else if (
            name === 'companyEmail' && (!value.trim() || !emailRegex.test(value))
        ) {
            newErrors[name] = 'Please enter a valid Company Email address';
        } else if (
            name === 'mobileNumber' && (!value.trim() || !/^\d{10}$/.test(value))
        ) {
            newErrors[name] = 'Please enter a valid 10-digit mobile number';
        }
        else if (
            name === 'companyPhone' && (!value.trim() || !/^\d{10}$/.test(value))
        ) {
            newErrors[name] = 'Please enter a valid 10-digit mobile number';
        } else if (
            name === 'aadhaarNo' && (!value.trim() || !/^\d{12}$/.test(value))
        ) {
            newErrors[name] = 'Please enter a valid 12-digit Aadhaar number';
        } else if (
            name === 'panNo' && (!value.trim() || !/^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value))
        ) {
            newErrors[name] = 'Please enter a valid PAN number in the format ABCDE1234F';
        } else if (
            name === 'accountNo' && (!value.trim() || !/^\d{16}$/.test(value))
        ) {
            newErrors[name] = 'Please enter a valid 16-digit account number';
        } else if (
            name === 'accountName' && !/^[A-Za-z\s]+$/.test(value)
        ) {
            newErrors[name] = 'Please enter a valid account name with only alphabetical characters';
        } else if (
            name === 'bankName' && !/^[A-Za-z\s]+$/.test(value)
        ) {
            newErrors[name] = 'Please enter a valid bank name with only alphabetical characters';
        } else if (
            name === 'ifsc' && !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)
        ) {
            newErrors[name] = 'Please enter a valid IFSC code in the format ABCD0123456';
        }
        else if (
            name === 'billingPinCode' && !/^\d{6}$/.test(value)
        ) {
            newErrors[name] = 'Please enter a valid 6-digit pin code';
        }
        else if (
            name === 'shippingPinCode' && !/^\d{6}$/.test(value)
        ) {
            newErrors[name] = 'Please enter a valid 6-digit pin code';
        }
        else if (
            name === 'offeredPackage' && !/^\d{8}$/.test(value)
        ) {
            newErrors[name] = 'Please enter a valid 8-digit offeredPackage';
        }
        else if (
            name === 'offeredCTC' && !/^\d{8}$/.test(value)
        ) {
            newErrors[name] = 'Please enter a valid 8-digit offeredCTC';
        }
        else if (
            name === 'perHourCharges' && !/^\d{8}$/.test(value)
        ) {
            newErrors[name] = 'Please enter a valid 8-digit perHourCharges';
        } else {
            delete newErrors[name]; // Remove error if field is valid
        }

        setErrors(newErrors);
    };

    const handleInputBlur = (event) => {
        const { name, value } = event.target;
        const newErrors = { ...errors };

        if (!value.trim()) {
            // Handle case where the field is required
            newErrors[name] = `Full Name is required *`;
        } else if (name === 'fullName' && !/\s/.test(value.trim())) {
            // Check if there is at least one space after the last name

        } else {
            // Remove error if the field is valid
            delete newErrors[name];
        }

        setErrors(newErrors);
    };

    const handleInputBlur2 = (event) => {
        const { name, value } = event.target;
        const newErrors = { ...errors };

        if (!value.trim()) {
            // Handle case where the field is required
            newErrors[name] = `Email is required *`;
        } else if (name === 'email' && !/\s/.test(value.trim())) {
            // Check if there is at least one space after the last name

        } else {
            // Remove error if the field is valid
            delete newErrors[name];
        }

        setErrors(newErrors);
    };

    const handleInputBlur3 = (event) => {
        const { name, value } = event.target;
        const newErrors = { ...errors };

        if (!value.trim()) {
            // Handle case where the field is required
            newErrors[name] = `Mobile Number is required *`;
        } else if (name === ' mobileNumber' && !/\s/.test(value.trim())) {
            // Check if there is at least one space after the last name

        } else {
            // Remove error if the field is valid
            delete newErrors[name];
        }

        setErrors(newErrors);
    };
   
   
    const handleUpdate = async () => {
        try {
          const formDataToSend = new FormData();
      
          for (const key in formData) {
            if (formData[key] !== null) {
              formDataToSend.append(key, formData[key]);
            }
          }
      
          const Token = localStorage.getItem('Token');
          const apiUrl = process.env.REACT_APP_URL;
      
          const response = await fetch(` ${apiUrl}/exporter/updateExporter/${empid}`, {
            method: 'PATCH',
            headers: {
              'Authorization': `Bearer ${Token}`,
            },
            body: formDataToSend,
          });
      
          if (!response.ok) {
            const responseData = await response.json();
      
            if (responseData.status === 'error' && responseData.message) {
              alert(responseData.message);
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
          }
      
          const data = await response.json();
          console.log('Form submitted successfully!', data);
      
          
          
         
         navigate('/ManageExporters')
      
          setFormData(initialFormData);
       
         
        } catch (error) {
          console.error('Error:', error);
          // Handle errors, e.g., toast.error('An error occurred');
        } finally {
          // Assuming this block should always execute regardless of success or failure.
          console.log('Form Update successfully!');
         }
      };

    useEffect(() => {
        // Fetch countries and populate the countryOptions
        fetch(`${apiUrl}/employee/allCountries`)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setCountryOptions(data.data);
                } else {
                    console.error('API response is not in the expected format for countries.');
                }

            })
            .catch((error) => {
                console.error('Error fetching country data:', error);
            });
    }, []);

    //country api 
    useEffect(() => {
        fetch(`${apiUrl}/employee/allCountries`)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setCountryOptions(data.data);
                } else {
                    console.error('API response is not in the expected format for countries.');
                }

            })
            .catch((error) => {
                console.error('Error fetching country data:', error);
            });
    }, []);



    const fetchStates = (countryId) => {
        fetch(`${apiUrl}/employee/allStates/${countryId}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setStateOptions(data.data);
                } else {
                    console.error('API response is not in the expected format for states.');
                }
            })
            .catch((error) => {
                console.error('Error fetching state data:', error);
            });
    };

    const fetchCities = async (stateId) => {
        try {
            const response = await fetch(`${apiUrl}/employee/allcities/${stateId}`);
            const data = await response.json();

            if (data.data && Array.isArray(data.data)) {
                setCityOptions(data.data);
            } else {
                console.error('API response is not in the expected format for cities.');
            }
        } catch (error) {
            console.error('Error fetching or parsing city data:', error);
        }
    };


    useEffect(() => {
        if (countryId) {

            fetchStates(countryId);
        }
    }, [countryId]);

    useEffect(() => {
        if (stateId) {

            fetchCities(stateId);
        }
    }, [stateId]);

    const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;
        setCountryId(selectedCountry);
        setFormData({
            ...formData,
            billingAddressCountry: selectedCountry,
        });
        setStateOptions([]); // Clear state options
        setCityOptions([]); // Clear city options
    };

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setStateId(selectedState);
        setFormData({
            ...formData,
            billingAddressState: selectedState,
        });
        setCityOptions([]); // Clear city options
    };
    //per

    useEffect(() => {
        fetch(`${apiUrl}/employee/allCountries`)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setCountryOptions8(data.data);
                } else {
                    console.error('API response is not in the expected format for countries.');
                }

            })
            .catch((error) => {
                console.error('Error fetching country data:', error);
            });
    }, []);



    const fetchState = (countrId) => {
        fetch(`${apiUrl}/employee/allStates/${countrId}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setStateOptions8(data.data);
                } else {
                    console.error('API response is not in the expected format for states.');
                }
            })
            .catch((error) => {
                console.error('Error fetching state data:', error);
            });
    };

    const fetchCitie = (statId) => {
        fetch(`${apiUrl}/employee/allcities/${statId}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.data && Array.isArray(data.data)) {
                    setCityOptions8(data.data);
                } else {
                    console.error('API response is not in the expected format for cities.');
                }
            })
            .catch((error) => {
                console.error('Error fetching city data:', error);
            });
    };

    useEffect(() => {
        if (countrId) {

            fetchState(countrId);
        }
    }, [countrId]);

    useEffect(() => {
        if (statId) {

            fetchCitie(statId);
        }
    }, [statId]);

    const handleCountryChang = (event) => {
        const selectedCountry = event.target.value;
        setCountrId(selectedCountry);
        setFormData({
            ...formData,
            shippingAddressCountry: selectedCountry,
        });
        setStateOptions8([]); // Clear state options
        setCityOptions8([]); // Clear city options
    };

    const handleStateChang = (event) => {
        const selectedState = event.target.value;
        setStatId(selectedState);
        setFormData({
            ...formData,
            shippingAddressState: selectedState,
        });
        setCityOptions8([]); // Clear city options
    };

    const handleInputChang = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleInputChan = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const copyCurrentAddress = () => {
        setFormData({
            ...formData,
            sameAsBillingAddress: true,
            shippingAddressCountry: formData.billingAddressCountry,
            shippingAddressState: formData.billingAddressState,
            shippingAddressCity: formData.billingAddressCity,
            shippingAddressArea: formData.billingAddressArea,
            shippingAddressLane: formData.billingAddressLane,
            shippingPinCode: formData.billingPinCode,
        });
    };



    return (
        <>

            {/* Main Header*/}
            <div className="page">


                <Navbar />
                <Prince />


                <div className="main-content  pt-0" id="printableContent">
                    <div className="main-container container-fluid">
                        <div className="inner-body">
                            {/* Page Header */}
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">Update Exporters</h2>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="#">Internal Staff </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="d-flex">
                                    <div className="justify-content-center">
                                    <button
                                            type="button"
                                            className="btn btn-primary my-2 btn-icon-text"
                                            onClick={handlePrint}
                                        >
                                            <i className="fe fe-download-cloud me-2" /> Print
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* End Page Header */}
                            {/* Row */}


                            <div className="row row-sm">
                                <div className="col-xl-3 col-lg-3 col-md-3">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div>
                                                <h6 className="main-content-label mb-4">PROFILE PHOTO</h6>
                                            </div>
                                            <form action="form-validation.html" data-parsley-validate="">
                                                <div className="">
                                                    <div className="row row-sm">
                                                        <div className="col-sm-12 col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="file"
                                                                name="profilePhoto"
                                                                onChange={handleFileChange}
                                                            />

                                                            {formData.profilePhoto && formData.profilePhoto.type && formData.profilePhoto.type.startsWith('image/') && (
                                                                <img src={URL.createObjectURL(formData.profilePhoto)} alt="Selected File" style={{ maxWidth: '350px' }} />
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-9">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div>
                                                <h6 className="main-content-label mb-3">BASIC INFO</h6>
                                            </div>
                                            <form action="form-validation.html" data-parsley-validate="">
                                                <div className="">
                                                    <div className="row row-sm">
                                                        <div className="col-lg-4 form-group">
                                                            <label className="form-label">
                                                                Full Name: <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                name="fullName"
                                                                value={formData.fullName}
                                                                placeholder="Enter firstname"
                                                                required=""
                                                                type="text"
                                                                onChange={handleChange}
                                                                onBlur={handleInputBlur}
                                                            />
                                                            {errors.fullName && <p style={{ color: 'red' }}>{errors.fullName}</p>}
                                                        </div>
                                                        <div className="col-lg-4 form-group">
                                                            <label className="form-label">
                                                                Email ID: <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                value={formData.email}
                                                                onChange={handleInputChange}
                                                                placeholder="Enter email"
                                                                required
                                                                type="text"
                                                                disabled={disableInput}
                                                            />
                                                        </div>
                                                        <div className="col-lg-4 form-group">
                                                            <label className="form-label">
                                                                Mobile No: <span className="tx-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"

                                                                placeholder="Enter mobile number"
                                                                required=""
                                                                type="text"
                                                                value={formData.mobileNumber}
                                                                onChange={handleInputChange2}
                                                                disabled={disableInput2}

                                                            />

                                                        </div>
                                                        <div className="col-sm-4 form-group ">
                                                            <label className="form-label">Company Name</label>
                                                            <input
                                                                className="form-control"
                                                                name='companyName'
                                                                required=""
                                                                type="text"
                                                                value={formData.companyName}
                                                                onChange={handleChange}

                                                            />
                                                            {errors.companyName && <p style={{ color: 'red' }}>{errors.companyName}</p>}
                                                        </div>
                                                        <div className="col-sm-4 form-group">
                                                            <label className="form-label">Company Email</label>
                                                            <input
                                                                className="form-control"
                                                                name='companyEmail'
                                                                required=""
                                                                type="text"
                                                                value={formData.companyEmail}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.companyEmail && <p style={{ color: 'red' }}>{errors.companyEmail}</p>}
                                                        </div>
                                                        <div className="col-sm-4 form-group">
                                                            <label className="form-label">Company Phone</label>
                                                            <input
                                                                className="form-control"
                                                                name='companyPhone'
                                                                required=""
                                                                type="text"
                                                                value={formData.companyPhone}
                                                                onChange={handleChange}

                                                            />
                                                            {errors.companyPhone && <p style={{ color: 'red' }}>{errors.companyPhone}</p>}
                                                        </div>
                                                        <div className="col-sm-4 ">
                                                            <label className="form-label">Company Website</label>
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                value={formData.companyWebsite}
                                                                onChange={(e) => setFormData({ ...formData, companyWebsite: e.target.value })}

                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <label className="form-label">Company Address</label>
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                value={formData.companyAddress}
                                                                onChange={(e) => setFormData({ ...formData, companyAddress: e.target.value })}

                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <label className="form-label">Company GST No</label>
                                                            <input
                                                                className="form-control"
                                                                required=""
                                                                type="text"
                                                                value={formData.companyGstNo}
                                                                onChange={(e) => setFormData({ ...formData, companyGstNo: e.target.value })}


                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Row */}
                            {/* Row */}
                            <div className="row row-sm">
                                <div className="col-lg-6 col-md-6">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div>
                                                <h6 className="main-content-label mb-1">Current Address</h6>
                                            </div>

                                            <div className="row row-sm">
                                                <div className="col-sm-6 mg-t-10">
                                                    <label className="form-label">Country</label>
                                                    <select
                                                        className="form-control select select2"
                                                        name="billingAddressCountry"
                                                        onChange={(e) => handleCountryChange(e)}
                                                    >
                                                        <option value="">Select a country</option>
                                                        {countryOptions.map((country) => (
                                                            <option selected={country.id === +formData.billingAddressCountry} key={country.id} value={(country.id)}>
                                                                {`${country.name}`}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-6 mg-t-10">
                                                    <label className="form-label">State</label>
                                                    <select
                                                        className="form-control select select2"
                                                        name="billingAddressState"

                                                        onChange={(e) => handleStateChange(e)}
                                                    >
                                                        <option value="">Select a State</option>
                                                        {stateOptions.map((state) => (
                                                            <option selected={state.id === +formData.billingAddressState} key={state.id} value={(state.id)}>
                                                                {`${state.name}`}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6 mg-t-10">
                                                    <label className="form-label">City</label>
                                                    <select
                                                        className="form-control select select2"
                                                        name="billingAddressCity"




                                                    >
                                                        <option value="">Select a city</option>
                                                        {cityOptions.map((city, index) => (
                                                            <option selected={city.id === +formData.billingAddressCity} key={city.id} value={(city.id)}>
                                                                {`${city.name}`}
                                                            </option>
                                                        ))}
                                                    </select>


                                                </div>

                                                <div className="col-sm-6 mg-t-10">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Area</label>
                                                        <input className="form-control" required="" type="text"
                                                            value={formData.billingAddressArea}
                                                            onChange={(e) => setFormData({ ...formData, billingAddressArea: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mg-t-10">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Address lane</label>
                                                        <input className="form-control" required="" type="text"
                                                            value={formData.billingAddressLane}
                                                            onChange={(e) => setFormData({ ...formData, billingAddressLane: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mg-t-10">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Pin Code</label>
                                                        <input className="form-control" required="" type="text"
                                                            name='billingPinCode'
                                                            value={formData.billingPinCode}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.billingPinCode && <p style={{ color: 'red' }}>{errors.billingPinCode}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div>
                                                <h6 className="main-content-label mb-1">
                                                    Permanent Address{" "}
                                                    <label className="ckbox" style={{ float: "right" }}>

                                                        <input
                                                            name="sameAsBillingAddress"
                                                            type="checkbox"
                                                            checked={formData.sameAsBillingAddress}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    copyCurrentAddress();
                                                                } else {
                                                                    setFormData({
                                                                        ...formData,
                                                                        sameAsBillingAddress: false,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <span className="tx-12">Same as Current Address</span>
                                                    </label>
                                                </h6>
                                            </div>
                                            <div className="row row-sm">
                                                <div className="col-sm-6 mg-t-10">
                                                    <label className="form-label">Country</label>
                                                    <select
                                                        className="form-control select select2"
                                                        name="shippingAddressCountry"

                                                        onChange={(e) => handleCountryChang(e)}
                                                        disabled={formData.sameAsCurrentAddress}
                                                    >
                                                        <option value="">Select a country</option>
                                                        {countryOptions8.map((country) => (
                                                            <option selected={country.id === +formData.shippingAddressCountry} key={country.id} value={(country.id)}>
                                                                {`${country.name}`}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </div>
                                                <div className="col-sm-6 mg-t-10">
                                                    <label className="form-label">State</label>
                                                    <select
                                                        className="form-control"
                                                        name="shippingAddressState"
                                                        // value={formData.permanentAddressState}
                                                        onChange={(e) => handleStateChang(e)}
                                                        disabled={formData.sameAsCurrentAddress}
                                                    >
                                                        <option value="">Select a State</option>
                                                        {stateOptions8.map((state) => (
                                                            <option selected={state.id === +formData.shippingAddressState} key={state.id} value={(state.id)}>
                                                                {`${state.name}`}
                                                            </option>
                                                        ))}
                                                    </select>


                                                </div>
                                                <div className="col-sm-6 mg-t-10">
                                                    <label className="form-label">City</label>
                                                    <select
                                                        className="form-control select select2"
                                                        name="shippingAddressCity"
                                                        onChange={handleInputChang}
                                                        disabled={formData.sameAsCurrentAddress}
                                                    >
                                                        <option value="">Select a city</option>
                                                        {cityOptions8.map((city) => (
                                                            <option selected={city.id === +formData.shippingAddressCity} key={city.id} value={(city.id)}>
                                                                {`${city.name}`}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-6 mg-t-10">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Area</label>
                                                        <input className="form-control" required=""
                                                            value={formData.shippingAddressArea}
                                                            onChange={(e) => setFormData({ ...formData, shippingAddressArea: e.target.value })}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mg-t-10">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Address lane</label>
                                                        <input className="form-control" required="" type="text"
                                                            value={formData.shippingAddressLane}
                                                            onChange={(e) => setFormData({ ...formData, shippingAddressLane: e.target.value })}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 mg-t-10">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Pin Code</label>
                                                        <input className="form-control" required="" type="text"
                                                            name='shippingPinCode'
                                                            value={formData.shippingPinCode}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.shippingPinCode && <p style={{ color: 'red' }}>{errors.shippingPinCode}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Row */}
                            {/* Row */}
                            <div className="row row-sm">
                                <div className="col-lg-12 col-md-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div>
                                                <h6 className="main-content-label  mb-3">KYC</h6>
                                            </div>
                                            <div className="row row-sm">
                                                <div className="col-sm-3 ">
                                                    <div className="form-group">
                                                        <label className="form-label">Aadhaar Upload</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="aadhaarUpload"

                                                            accept="/pdf"
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label className="form-label">Aadhaar No</label>
                                                        <input
                                                            className="form-control"
                                                            name='aadhaarNo'
                                                            required=""
                                                            type="text"
                                                            value={formData.aadhaarNo}
                                                            onChange={handleChange}

                                                        />
                                                        {errors.aadhaarNo && <p style={{ color: 'red' }}>{errors.aadhaarNo}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label className="form-label">PAN Upload</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="panUpload"
                                                            onChange={handleFileChange2}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label className="form-label">PAN No</label>
                                                        <input
                                                            className="form-control"
                                                            name='panNo'
                                                            required=""
                                                            type="text"
                                                            value={formData.panNo}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.panNo && <p style={{ color: 'red' }}>{errors.panNo}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Driving Licence</label>
                                                        <input
                                                            type="file"
                                                            className="dropify"
                                                            name="drivingLicence"
                                                            onChange={handleFileChange3}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Cheque</label>
                                                        <input
                                                            type="file"
                                                            className="dropify"
                                                            name="cheque"
                                                            onChange={handleFileChange4}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">GST No</label>
                                                        <input
                                                            className="form-control"
                                                            required=""
                                                            type="text"
                                                            value={formData.gstNo}
                                                            onChange={(e) => setFormData({ ...formData, gstNo: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Row */}
                            {/* Row */}
                            <div className="row row-sm">
                                <div className="col-lg-12 col-md-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div>
                                                <h6 className="main-content-label  mb-3">
                                                    Account Details
                                                </h6>
                                            </div>
                                            <div className="row row-sm">
                                                <div className="col-sm-3 ">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Account No.</label>
                                                        <input
                                                            className="form-control"
                                                            name='accountNo'
                                                            required=""
                                                            type="text"
                                                            value={formData.accountNo}
                                                            onChange={handleChange}

                                                        />
                                                        {errors.accountNo && <p style={{ color: 'red' }}>{errors.accountNo}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Account Name</label>
                                                        <input
                                                            className="form-control"
                                                            name='accountName'
                                                            required=""
                                                            type="text"
                                                            value={formData.accountName}
                                                            onChange={handleChange}

                                                        />
                                                        {errors.accountName && <p style={{ color: 'red' }}>{errors.accountName}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">Bank Name</label>
                                                        <input
                                                            className="form-control"
                                                            name='bankName'
                                                            required=""
                                                            type="text"
                                                            value={formData.bankName}
                                                            onChange={handleChange}

                                                        />
                                                        {errors.bankName && <p style={{ color: 'red' }}>{errors.bankName}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group mb-0">
                                                        <label className="form-label">IFSC</label>
                                                        <input
                                                            className="form-control"
                                                            required=""
                                                            type="text"
                                                            value={formData.ifsc}
                                                            onChange={(e) => setFormData({ ...formData, ifsc: e.target.value })}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Row */}
                            {/* Row */}

                            {/* End Row */}
                            <div className="row row-sm">
                                <div className="col-12 mb-3">
                                    <button className="btn btn-primary" onClick={handleUpdate} type="submit">
                                    Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Main Content*/}
                {/* Main Footer*/}
                <div className="main-footer text-center">
                    <div className="container">
                        <div className="row row-sm">
                            <div className="col-md-12">
                                <span>
                                    Copyright © 2023 <a href="javascript:void(0)">PWIP</a>. Designed
                                    by <a href="http://webkype.com/">Webkype.com</a> All rights
                                    reserved.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>

    )
}

export default ExportersEdit