import React, { useState, useEffect } from 'react'
import Prince from './Prince';
import Navbar from '../Pages/Navbar'
import { Link, useNavigate } from 'react-router-dom';

const MasterValue = () => {

    const [profilePicFile, setProfilePicFile] = useState(null);
    const initialFormData = {
        name: '',
        mastersId: "",


    };

    const [formData, setFormData] = useState(initialFormData);
    const [departments, setDepartments] = useState([]);
   
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_URL;
    console.log(apiUrl);

    useEffect(() => {
        fetchDepartments();
    }, []);

    const fetchDepartments = () => {
        const Token = localStorage.getItem('Token');
        console.log('Token:', Token);

        fetch(`${apiUrl}/master/getAllMaster`, {
            headers: {
                'Authorization': `Bearer ${Token}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    setDepartments(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching department options:', error);
            });
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            if (file.type.startsWith('image/')) {
                // Set profilePicFile for image files
                setProfilePicFile(file);
                setFormData((prevData) => ({
                    ...prevData,
                    profilePhoto: file,


                }));
            } else if (file.type === 'application/pdf') {
                // Set aadhaarUpload for PDF files
                setFormData((prevData) => ({
                    ...prevData,
                    aadhaarUpload: file,
                    panUpload: file,
                    drivingLicence: file,
                    cheque: file,
                }));
            } else {
                console.log('Unsupported file type');
            }
        } else {
            console.log('No file selected');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const Token = localStorage.getItem('Token');
        console.log('Token:', Token);

        try {
            const formDataToSend = new FormData();
            for (const key in formData) {
                if (formData[key] !== null) {
                    formDataToSend.append(key, formData[key]);
                }
            }

            const apiUrl = process.env.REACT_APP_URL;
            console.log(apiUrl);

            const response = await fetch(`${apiUrl}/master/addMasterData`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Token}`,
                },
                body: formDataToSend,
            });

            console.log(response);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Form submitted successfully!', data);
            navigate('/MasterVal');

            setFormData(initialFormData);
        } catch (error) {
            console.error('Error:', error);
            // Handle specific errors or display appropriate messages
        }
    };

    const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;

        setFormData({
            ...formData,
            mastersId: selectedCountry,
        });

    };

    useEffect(() => {
        const token = localStorage.getItem('Token');

        if (!token) {
            console.log(token);
            navigate('/');
        }
    }, [navigate]);
    return (
        <>
            {/* Main Header*/}
            
            <div className="page">
            
               <Navbar />
                <Prince />
                
                {/* Main Content*/}
                <div className="main-content  ">
                    <div className="main-container container-fluid">
                        <div className="inner-body">
                            {/* Page Header */}
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">Master Value</h2>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="#">Internal Staff </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="d-flex">
                                    <div className="justify-content-center">

                                    </div>
                                </div>
                            </div>

                            <div className="row row-sm">
                                <div className="col-lg-12 col-md-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div>
                                                <h6 className="main-content-label mb-3">Master</h6>
                                            </div>
                                            <div className="row row-sm">
                                                <div className="col-sm-6 mg-t-10">
                                                    <label className="form-label">Master Name</label>
                                                    <select
                                                        className="form-control select select2"
                                                        onChange={handleCountryChange}
                                                        name="mastersId"
                                                        value={formData.mastersId}>
                                                        <option>Select Master</option>
                                                        {departments.map((department) => (
                                                            <option key={department.id} value={department.id}>
                                                                {department.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6 mg-t-10">
                                                    <label className="form-label">Master Value</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter master value"
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Row */}
                           
                            <div className="row row-sm">
                                <div className="col-12 mb-3">
                                    <button

                                        className="btn btn-primary"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Main Content*/}
                
            </div>

        </>

    )
}

export default MasterValue