import React, { useState, useEffect } from 'react';
import '../assets/plugins/pickr-master/themes/classic.min.css';
import '../assets/css/style.css';
import './UserProfileDropdown.css'; 
import { Link, useParams, useNavigate } from 'react-router-dom';

function UserProfileDropdown() {
  const [employee, setEmployee] = useState({})
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    if (!isDropdownOpen) {
        setIsDropdownOpen(true);
    } else {
        setIsDropdownOpen(false);
    }
};

useEffect(() => {
    const handleOutsideClick = (event) => {
        if (isDropdownOpen && !event.target.closest('.your-dropdown-class')) {
            // Close the dropdown only if it's open and the click is outside the dropdown
            setIsDropdownOpen(false);
        }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
        document.removeEventListener('click', handleOutsideClick);
    };
}, [isDropdownOpen]);

  console.log(isDropdownOpen);

  function signOut() {
   
    navigate('/')
  }
  const apiUrl = process.env.REACT_APP_URL;
 
  useEffect(() => {
    async function getEmp() {
     
      const Token = localStorage.getItem("Token");
      let response = await fetch(`${apiUrl}/employee/employee`, {
        method: "POST",
        headers: {
          Auth: `Bearer ${Token}`
        },
      })
      response = await response.json();

      if (response.status === "success") {
        setEmployee(response.data);
      }
    }
    getEmp();
  }, [])
  // console.log(employee);
  return (
    <div className="dropdown main-profile-menu">
    <button className="main-img-user" onClick={toggleDropdown}>
      <img alt="avatar" src={employee.profilePic} />
    </button>
    <div className="dropdown-menu show-dropdown" style={{display: isDropdownOpen ? 'block' : 'none'}}>
        {/* Dropdown content */}
        <div className="header-navheading">
          <h6 className="main-notification-title">{employee.firstName}</h6>
          <p className="main-notification-text">Web Designer</p>
        </div>
        {/* Dropdown items */}
        <div className="dropdown-item border-top">
          <i className="fe fe-user" /> My Profile
        </div>
       
        <div className="dropdown-item" onClick={signOut}>
          <i className="fe fe-power"></i> Sign Out
        </div>
      </div>
  </div>
  );
}

export default UserProfileDropdown;
