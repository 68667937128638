import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Content from '../Components/Content'
import Employee from '../Employee'
import MainPage3 from './MainPage'
import DropdownMenu from '../Components/DropdownMenu'
import Prince from './Prince'
import MainPag from './Date'
import Content2 from './Content2'
import Navbar from '../Pages/Navbar'
import Amit from '../assets/img/brand/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function MainPage2() {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [action, setAction] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [genderFilter, setGenderFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isToggleOn, setToggleOn] = useState(false);
    const [toggleStatus, setToggleStatus] = useState({});

    const navigation = useNavigate();
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_URL;
  
    const Token = localStorage.getItem('Token');




    // on and of toggle
    useEffect(() => {
        // Load toggleStatus from localStorage on component mount
        const storedToggleStatus = JSON.parse(localStorage.getItem('toggleStatus')) || {};
        setToggleStatus(storedToggleStatus);
    }, []);

    const handleToggle = (id) => {
        setToggleStatus(prevStatus => {
            const newStatus = prevStatus[id] === 0 ? 1 : 1 ?0 :0;

            // Save toggleStatus to localStorage
            localStorage.setItem('toggleStatus', JSON.stringify({
                ...prevStatus,
                [id]: newStatus,
            }));

            // Log the body content before making the API call
            console.log('Request Body:', JSON.stringify({
                status: newStatus,
            }));

            // Make API call to update employee status
            fetch(`${apiUrl}/employee/employeeStatus/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${Token}`
                },
                body: JSON.stringify({
                    status: newStatus,
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to update employee status');
                    }
                    
                })
                .catch(error => {
                    console.error('Error updating employee status:', error);
                  
                    
                })
                .finally(() => {
                   
                    console.log('API URL:', `${apiUrl}/employee/employeeStatus/${id}`);
                });
                

            return {
                ...prevStatus,
                [id]: newStatus,
            };
        });
    };


    const loadcontent = (id) => {
        console.log(id);
        navigation(`/Content2/${id}`);
    };
    const deletecontent = (id) => {
        const Token = localStorage.getItem('Token');
        console.log('Token:', Token);

        if (window.confirm("Do you want to delete")) {
            fetch(`${apiUrl}/employee/employeeDelete/` + id, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${Token}`,
                    // "Content-Type": "application/json" 
                }
            })
                .then((res) => {
                    if (res.ok) {
                        alert('Record Deleted');
                        window.location.reload(false);
                    } else {
                        throw new Error('Failed to delete');
                    }
                })
                .catch((err) => {
                    console.log(err);

                });
        }
    };


    useEffect(() => {
        
        const scrollToTop = () => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        };
    
        // Event listener for the arrow click
        const arrowButton = document.getElementById('back-to-top');
        if (arrowButton) {
          arrowButton.addEventListener('click', scrollToTop);
        }
    
       
        return () => {
          if (arrowButton) {
            arrowButton.removeEventListener('click', scrollToTop);
          }
        };
      }, []); 
    

    useEffect(() => {


        fetch(`${apiUrl}/employee/employees`, {
            headers: {
                'Authorization': `Bearer ${Token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    if (Array.isArray(data.data)) {
                        setUsers(data.data);
                    } else {
                        console.error('API response does not contain users array:', data);
                    }
                } else {
                    console.error('API request was not successful:', data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    console.warn(users);

    const filteredUsers = users.filter((user) => {
        return (
            (user.id.toString().includes(search) || user.firstName.includes(search)) ||  user.email.includes(search) &&
            (statusFilter === '' || user.status.toString() === statusFilter) &&
            (genderFilter === '' || user.gender === genderFilter || (genderFilter === 'male' && user.gender === 'Male') || (genderFilter === 'female' && user.gender === 'Female'))
        );
    });
    useEffect(() => {
        const token = localStorage.getItem('Token');

        if (!token) {
            console.log(token);
            navigate('/');
        }
    }, [navigate]);

    return (
        <>




            <div className="page">
                <Navbar />
                <Prince />


                <div className="main-content  pt-0">
                    <div className="main-container container-fluid">
                        <div className="inner-body">
                            {/* Page Header */}
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5">Employee List</h2>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="#">HRMS </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Employee List{" "}
                                        </li>
                                    </ol>
                                </div>
                                <div className="d-flex">
                                    <div className="justify-content-center">
                                       
                                        <Link to="/Employee" className="btn btn-primary my-2 btn-icon-text" type="button">
                                            {" "}
                                            <i className="fe fe-plus me-2" /> Add Employee
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* End Page Header */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body py-3">
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <input
                                                        type="search"
                                                        className="form-control form-control"
                                                        placeholder="Search..."
                                                        aria-controls="example1"
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                    />
                                                </div>

                                                <div className="col-sm-3">
                                                    <select
                                                        className="form-control select2"
                                                        value={genderFilter}
                                                        onChange={(e) => setGenderFilter(e.target.value)}
                                                    >
                                                        <option value="" label="Gender">Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        {/* Add more gender options as needed */}
                                                    </select>
                                                </div>


                                            </div>{" "}
                                        </div>
                                    </div>
                                </div>{" "}
                            </div>
                            {/* Row */}
                            <div className="row row-sm">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered text-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <label className="ckbox">
                                                                    <input type="checkbox" defaultValue={5} />
                                                                    <span />
                                                                </label>
                                                            </th>
                                                            <th>ID</th>
                                                            <th>Photo</th>
                                                            <th>Contact</th>
                                                            <th>Onboarding</th>
                                                            <th>Department</th>
                                                            <th>KYC</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredUsers.map((user) => (
                                                            <tr key={user.id}>
                                                                <td>
                                                                    <label className="ckbox">
                                                                        <input type="checkbox" defaultValue={5} />
                                                                        <span />
                                                                    </label>
                                                                </td>
                                                                <td>

                                                                    EMP-{user.id}
                                                                    <br />
                                                                    Source : By Hr
                                                                    {/* Include your modal trigger button here */}
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        alt="avatar"
                                                                        className="rounded-circle me-3"
                                                                        src={user.profilePic}
                                                                        style={{ width: 60 }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">
                                                                        {user.firstName} {user.lastName}

                                                                        <br />
                                                                        M: {user.mobileNumber}

                                                                        <br />
                                                                        E: {user.email}
                                                                        <br />
                                                                        DOB: {user.dob}
                                                                        <br />
                                                                        {user.gender}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">
                                                                        Pwip Business
                                                                        <br />
                                                                        E: {user.email}
                                                                        <br />
                                                                        M: {user.mobileNumber}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p>
                                                                        {user.department}
                                                                        <br />
                                                                        {user.designation}
                                                                        <br />
                                                                        DOJ : {user.dateOfJoin}
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">
                                                                        Aadhaar:{" "}
                                                                        <Link
                                                                            to={user.adharImage}
                                                                            className="mb-1"
                                                                            title="View"

                                                                            target='blanck'
                                                                        >
                                                                            <i className="fa fa-fw fa-eye " />
                                                                        </Link>
                                                                        <br />
                                                                        Pan:{" "}
                                                                        <Link
                                                                            to={user.panImage}
                                                                            className="mb-1"
                                                                            title="View"

                                                                            target='blanck'
                                                                        >
                                                                            <i className="fa fa-fw fa-eye " />
                                                                        </Link>


                                                                      

                                                                        <br />
                                                                        Driving Licence:{" "}
                                                                        <Link
                                                                            to={user.drivingLicenseImage}
                                                                            className=" mb-1"
                                                                            title="View"
                                                                            data-bs-target="#modaldemo-cheque"
                                                                            data-bs-toggle="modal"
                                                                            target='blanck'

                                                                        >
                                                                            <i className="fa fa-fw fa-eye " />
                                                                        </Link>
                                                                        <br />
                                                                        Offer Letter:{" "}
                                                                        <Link
                                                                            to={user.offerLetterUpload}
                                                                            className=" mb-1"
                                                                            title="View"
                                                                            data-bs-target="#modaldemo-address"
                                                                            data-bs-toggle="modal"
                                                                            target='blanck'
                                                                        >
                                                                            <i className="fa fa-fw fa-eye " />

                                                                        </Link>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <div className={`main-toggle main-toggle-success ${toggleStatus[user.id] === 1 ? 'on' : ''}`} onClick={() => handleToggle(user.id)}>
                                                                        <span />
                                                                    </div>
                                                                   
                                                                   
                                                                    <a onClick={() => loadcontent(user.id)} className="btn ripple btn-info btn-xs w-100">

                                                                        Edit Profile
                                                                    </a>
                                                                    <br />

                                                                    <a href="#" className="btn ripple btn-info btn-xs w-100 my-1"
                                                                        onClick={() => deletecontent(user.id)}
                                                                    >
                                                                        Delete

                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Row */}
                        </div>
                    </div>
                </div>
                {/* End Main Content*/}
                {/* Main Footer*/}
                <div className="main-footer text-center">
                    <div className="container">
                        <div className="row row-sm">
                            <div className="col-md-12">
                                <span>
                                    Copyright © 2023 <a href="javascript:void(0)">PWIP</a>. Designed
                                    by <a href="http://webkype.com/">Webkype.com</a> All rights
                                    reserved.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/*End Footer*/}
            </div>
            <a href="#top" id="back-to-top">
        <FontAwesomeIcon icon={faArrowUp} className="fs-arrow-up" />
      </a>
        </>

    )
}


export default MainPage2