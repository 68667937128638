import React, { useState, useEffect } from 'react';
import { BsFillCalendarDateFill } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './LoginForm.css'
import { Link, useNavigate } from 'react-router-dom';




function YourFormComponent() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [genderFilter, setGenderFilter] = useState('');

  const [genderOptions2, setGenderOptions2] = useState([]);
  const [genderOptions3, setGenderOptions3] = useState([]);
  const [genderOptions4, setGenderOptions4] = useState([]);
  const [genderOptions5, setGenderOptions5] = useState([]);
  const navigate = useNavigate();
  const initialFormData = {
    profilePic: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    mobileNumber: '',
    gender: '',
    dob: '',
    currentAddressCountry: '',
    currentAddressState: '',
    currentAddressCity: '',
    currentAddressArea: '',
    currentAddressLane: '',
    currentAddressPinCode: '',
    sameAsCurrentAddress: false,
    permanentAddressCountry: '',
    permanentAddressState: '',
    permanentAddressCity: '',
    permanentAddressArea: '',
    permanentAddressLane: '',
    permanentAddressPinCode: '',
    companyName: '',
    department: '',
    designation: '',
    userType: '',
    dateOfJoin: "",
    reportingBossA: '',
    reportingBossB: '',
    permissions: '',
    adharImage: null,
    adharNumber: '',
    panImage: '',
    panNumber: '',
    drivingLicenseImage: null,
    chequeImage: null,
    accountNumber: '',
    accountName: '',
    bankName: '',
    ifsc: '',
    offeredPackage: '',
    offeredCTC: '',
    noticePeriod: '',
    workingShift: '',
    perHourCharges: '',
    perCasesCharges: '',
    perMonthCharges: '',
    consultingFees: '',
    offerLetterUpload: null,
  };



  const [formData, setFormData] = useState(initialFormData);
  
  // Initialize the state to store the date input value

  const [profilePicFile, setProfilePicFile] = useState(null);
  const [profilePicFile2, setProfilePicFile2] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryOptions8, setCountryOptions8] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateOptions8, setStateOptions8] = useState([]);
  const [countryIdLookup, setCountryIdLookup] = useState({});
  const [cityOptions, setCityOptions] = useState([]);
  const [cityOptions8, setCityOptions8] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateId8, setStateId8] = useState([]);
  const [designationId, setDesignationId] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCity8, setSelectedCit8] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [countryId, setCountryId] = useState('');
  const [countryId8, setCountryId8] = useState('');
  const [departmentId, setDepartmentI] = useState('');
  const [genderOption, setGenderOption] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [options8, setOptions8] = useState([]);
  const [optio, setOptio] = useState([]);
  const [optio8, setOptio8] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [noticePeriod, setNoticePeriod] = useState([]);
  const [working, setWorking] = useState([]);
  const [reportingBossA, setReportingBossA] = useState([]);
  const [reportingBossB, setReportingBossB] = useState([]);
  const [profilePic, setProfilePic] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedDesignation, setSelectedDesignation] = useState('');
  const [errors, setErrors] = useState({});

  const apiUrl = process.env.REACT_APP_URL;
  console.log(apiUrl);

  const Token = localStorage.getItem('Token');
  console.log('Token:', Token);

  //Gender
  useEffect(() => {
    const Token = localStorage.getItem('Token');

    fetch(`${apiUrl}/master/getAllMasterData/14`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOption(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  //departments
  useEffect(() => {

    const Token = localStorage.getItem('Token');
    fetch(`${apiUrl}/master/getAllMasterData/7`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions2(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  // Designation
  useEffect(() => {
    const Token = localStorage.getItem('Token');
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions3(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);


  // notice 
  useEffect(() => {
    const Token = localStorage.getItem('Token');
    fetch(`${apiUrl}/master/getAllMasterData/12`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions4(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  //working shift
  useEffect(() => {
    const Token = localStorage.getItem('Token');
    fetch(`${apiUrl}/master/getAllMasterData/13`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions5(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

//print from

  const handlePrint = () => {
    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Get the content to be printed (in this case, the entire component)
    const content = document.getElementById('printableContent');

    // Use html2canvas to capture the content as an image
    html2canvas(content).then((canvas) => {
      // Convert the canvas image to a data URL
      const imgData = canvas.toDataURL('image/png');

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust dimensions as needed

      // Save or open the PDF
      pdf.save('form_data.pdf');
      // Alternatively, you can open the print dialog
      // pdf.autoPrint();
      // window.open(pdf.output('bloburl'), '_blank');
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const maxDate = new Date(); // Get the current date
  maxDate.setHours(0, 0, 0, 0); // Set the time to the beginning of the day


  const getCurrentDate = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const getCurrentDate2 = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };



//date format
  const handleDateChange = (event) => {
    const inputDate = event.target.value;
    setFormData(prevState => ({
      ...prevState,
      dob: inputDate,
    }));
  };
  const handleDateChange2 = (event) => {
    const inputDate = event.target.value;
    setFormData(prevState => ({
      ...prevState,
      dateOfJoin: inputDate,
    }));
  };

  useEffect(() => {
    const Token = localStorage.getItem("Token");
    console.log("Token " + Token);

    fetch(`${apiUrl}/employee/add-employee`, {
      headers: {
        'Authorization': `Bearer ${Token}` // Include the Token in the Authorization header
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          if (Array.isArray(data.data)) {
            setUsers(data.data);
          } else {
            console.error('API response does not contain users array:', data);
          }
        } else {
          console.error('API request was not successful:', data);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  console.warn(users);

  const filteredUsers = users.filter((user) => {
    return (
      (user.id.toString().includes(search) || user.firstName.includes(search)) &&
      (statusFilter === '' || user.status.toString() === statusFilter) &&
      (genderFilter === '' || user.gender === genderFilter || (genderFilter === 'male' && user.gender === 'Male') || (genderFilter === 'female' && user.gender === 'Female'))
    );
  });

//image,pan call 
  const handleFileChange = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith('image/')) {
        // Handle image files (jpeg, png, etc.)
        setProfilePicFile(profilePicFile);
        setFormData((prevData) => ({
          ...prevData,
          profilePic: profilePicFile,
        }));
      } else if (profilePicFile.type === 'application/pdf') {
        // Handle PDF files
        setFormData((prevData) => ({
          ...prevData,
          adharImage: profilePicFile,
         
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };

  const handleFileChange2 = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith('image/')) {
        // Handle image files (jpeg, png, etc.)
        setProfilePicFile(profilePicFile);
        setFormData((prevData) => ({
          ...prevData,
          profilePic: profilePicFile,
        }));
      } else if (profilePicFile.type === 'application/pdf') {
        // Handle PDF files
        setFormData((prevData) => ({
          ...prevData,
        
          panImage: profilePicFile,
         
          
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };

  const handleFileChange3 = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith('image/')) {
        // Handle image files (jpeg, png, etc.)
        setProfilePicFile(profilePicFile);
        setFormData((prevData) => ({
          ...prevData,
          profilePic: profilePicFile,
        }));
      } else if (profilePicFile.type === 'application/pdf') {
        // Handle PDF files
        setFormData((prevData) => ({
          ...prevData,
        
        
          drivingLicenseImage: profilePicFile,
          
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };

  const handleFileChange4 = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith('image/')) {
        // Handle image files (jpeg, png, etc.)
        setProfilePicFile(profilePicFile);
        setFormData((prevData) => ({
          ...prevData,
          profilePic: profilePicFile,
        }));
      } else if (profilePicFile.type === 'application/pdf') {
        // Handle PDF files
        setFormData((prevData) => ({
          ...prevData,
        
        
          offerLetterUpload: profilePicFile,
          
        }));
      } else {
        console.log('Unsupported file type');
      }
    } else {
      console.log('No file selected');
    }
  };



  // const validateForm = () => {
  //   let valid = true;
  //   const newErrors = { ...errors }; // Copy existing errors

  //   const nameRegex = /^[A-Za-z]+$/; // Regex to match only alphabetical characters

  //   if (!formData.firstName.trim()) {
  //     newErrors.firstName = 'First name is required';
  //     valid = false;
  //   } else if (!nameRegex.test(formData.firstName)) {
  //     newErrors.firstName = 'Please enter a valid first name with only alphabetical characters';
  //     valid = false;
  //   } else {
  //     delete newErrors.firstName; // Remove error if field is a valid string
  //   }

  //   if (!formData.lastName.trim() || !nameRegex.test(formData.lastName)) {
  //     newErrors.lastName = 'Please enter a valid last name with only alphabetical characters';
  //     valid = false;
  //   } else {
  //     delete newErrors.lastName; // Remove error if field is a valid string
  //   }

  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!formData.email.trim() || !emailRegex.test(formData.email)) {
  //     newErrors.email = 'Please enter a valid email address';
  //     alert("Please enter a valid email address")
  //     valid = false;
  //   }

  //   const mobileRegex = /^[0-9]{10}$/;
  //   if (!formData.mobileNumber.trim() || !mobileRegex.test(formData.mobileNumber)) {
  //     newErrors.mobileNumber = 'Please enter a valid 10-digit mobile number';
  //     alert("MobileNumber is required")
  //     valid = false;
  //   }

  //   setErrors(newErrors);
  //   return valid;
  // };
  const nameRegex = /^[A-Za-z]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^[0-9]{10}$/;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });


   // validation form

    const newErrors = { ...errors };
    if (!value.trim()) {
     
    } else if (
      name === 'firstName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[name] = `Please enter a valid First Name with only alphabetical characters`;
      // You can customize the error message accordingly
  
    
    } else if (
      name === 'lastName' && !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[name] = `Please enter a valid Last Name with only alphabetical characters`;
    } else if (
      name === 'email' && (!value.trim() || !emailRegex.test(value))
    ) {
      newErrors[name] = 'Please enter a valid email address';
    } else if (
      name === 'mobileNumber' && (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid 10-digit mobile number';
    } else if (
      name === 'adharNumber' && (!value.trim() || !/^\d{12}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid 12-digit Aadhaar number';
    } else if (
      name === 'panNumber' && (!value.trim() || !/^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value))
    ) {
      newErrors[name] = 'Please enter a valid PAN number in the format ABCDE1234F';
    
    
    } else if (name === 'accountNumber' && (value.length < 0 || value.length > 16 || !/^[A-Z0-9]{0,16}$/i.test(value))) {
      newErrors[name] = 'Please enter a valid account number with 0 to 16 characters, including numbers and capital letters';

    } else if (
      name === 'accountName' && !/^[A-Za-z\s]+$/.test(value)
    ) {
      newErrors[name] = 'Please enter a valid account name with only alphabetical characters';
    } else if (
      name === 'bankName' && !/^[A-Za-z\s]+$/.test(value)
    ) {
      newErrors[name] = 'Please enter a valid bank name with only alphabetical characters';
    } else if (name === 'ifsc' && (value.length < 0 || value.length > 11 || !/^[A-Z0-9]{0,11}$/.test(value))) {
      newErrors[name] = 'Please enter a valid IFSC code with 0 to 11 characters, including numbers and capital letters';

    }

    else if (
      name === 'currentAddressPinCode' && !/^\d{6}$/.test(value)
    ) {
      newErrors[name] = 'Please enter a valid 6-digit pin code';
    }
    // Assuming this block is inside some kind of validation logic
    
    else if (name === 'offeredPackage') {
      const floatValue = parseFloat(value.replace(',', '.'));
    
      if (isNaN(floatValue) || !/^\d+(\.\d*)?$/.test(value)) {
        newErrors[name] = 'Please enter a valid numeric value for offeredPackage';
      }
    }
    
  
    else if (name === 'offeredCTC' && (value.length === 1 || value.length > 8 || !/^\d{1,8}$/.test(value))) {
      newErrors[name] = 'Please enter a valid offeredPackage between 1 to 8 digits';
    }


    else if (name === 'perMonthCharges' && (value.length === 1 || value.length > 8 || !/^\d{1,8}$/.test(value))) {
      newErrors[name] = 'Please enter a valid offeredPackage between 1 to 8 digits';

    } else {
      delete newErrors[name]; // Remove error if field is valid
    }

    setErrors(newErrors);
  };
  const handleInputBlur2 = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `Last Name is required *`;
    } else if (name === 'lastName' && !/\s/.test(value.trim())) {
  
      
    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };
  const handleInputBlur4 = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `Mobile Number is required *`;
    } else if (name === 'mobileNumber' && !/\s/.test(value.trim())) {
      // Check if there is at least one space after the last name
      
    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };
  const handleInputBlur5 = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `Password is required *`;
    } else if (name === 'password' && !/\s/.test(value.trim())) {
      // Check if there is at least one space after the last name
     
    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };
  const handleInputBlur3 = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `Email is required *`;
    } else if (name === 'email' && !/\s/.test(value.trim())) {
      // Check if there is at least one space after the last name
     
    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };
  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      // Handle case where the field is required
      newErrors[name] = `First Name is required *`;
    } else if (name === 'firstName' && !/\s/.test(value.trim())) {
      
      
    } else {
      // Remove error if the field is valid
      delete newErrors[name];
    }

    setErrors(newErrors);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }

      const apiUrl = process.env.REACT_APP_URL;
      console.log(apiUrl);

      const response = await fetch(`${apiUrl}/employee/add-employee`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      console.log(response);

      if (!response.ok) {
        const responseData = await response.json();

        if (responseData && responseData.status === 'error' && responseData.message) {
          alert(responseData.message);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      console.log('Form submitted successfully!', data);

      toast.success('Form submitted successfully');
      navigate('/MainPage2');

      setFormData(initialFormData);
    } catch (error) {
      console.error('Error:', error);
      // toast.error('Please check your API!');
    }
  };


  //Boss a

  useEffect(() => {
    const Token = localStorage.getItem('Token');
    console.log('Token:', Token);

    fetch(`${apiUrl}/employee/allEmpDesig`, {
      headers: {
        'Authorization': `Bearer ${Token}` // Assuming it's a Bearer token
        // Add other headers if needed
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  //Boss b
  useEffect(() => {
    const Token = localStorage.getItem('Token');
    console.log('Token:', Token);
    fetch(`${apiUrl}/employee/allEmpDesig`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Token}`

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setOptio(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  // Working Shift
  useEffect(() => {

    fetch(`${apiUrl}/employee/workingShift`)
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setWorking(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);


  //noticePeriod api
  useEffect(() => {

    fetch(`${apiUrl}/employee/noticePeriod`)
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setNoticePeriod(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/employee/gender`)
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching gender options:', error);
      });
  }, []);



  useEffect(() => {
    // Fetch countries and populate the countryOptions
    fetch(`${apiUrl}/employee/allEmpDesig`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setOptions(data.data);
        } else {
          console.error('API response is not in the expected format for countries.');
        }
      })
      .catch((error) => {
        console.error('Error fetching country data:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch department options on initial load
    fetch(`${apiUrl}/employee/departments`)
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setDepartments(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching department options:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      // Fetch designations based on the selected department
      fetchDesignations(selectedDepartment);
    }
  }, [selectedDepartment]);

  const fetchDesignations = (departmentId) => {
    fetch(`${apiUrl}/employee/designations/${departmentId}`)
      .then(response => response.json())
      .then(data => {
        if (data.data && Array.isArray(data.data)) {
          setDesignations(data.data);
        } else {
          console.error('API response is not in the expected format for designations.');
        }
      })
      .catch(error => {
        console.error('Error fetching designation data:', error);
      });
  };

  const handleDepartmentChange = (event) => {
    const selectedDept = event.target.value;
    setSelectedDepartment(selectedDept);
    setFormData({
      ...formData,
      department: selectedDept,
      designation: '' // Reset designation when department changes
    });
  };

  const handleDesignationChange = (event) => {
    const selectedDesig = event.target.value;
    setFormData({
      ...formData,
      designation: selectedDesig
    });
  };

  //country api 
  useEffect(() => {
    fetch(`${apiUrl}/employee/allCountries`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCountryOptions(data.data);
        } else {
          console.error('API response is not in the expected format for countries.');
        }

      })
      .catch((error) => {
        console.error('Error fetching country data:', error);
      });
  }, []);



  const fetchStates = (countryId) => {
    fetch(`${apiUrl}/employee/allStates/${countryId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setStateOptions(data.data);
        } else {
          console.error('API response is not in the expected format for states.');
        }
      })
      .catch((error) => {
        console.error('Error fetching state data:', error);
      });
  };

  const fetchCities = (stateId) => {
    fetch(`${apiUrl}/employee/allcities/${stateId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCityOptions(data.data);
        } else {
          console.error('API response is not in the expected format for cities.');
        }
      })
      .catch((error) => {
        console.error('Error fetching city data:', error);
      });
  };

  useEffect(() => {
    if (countryId) {

      fetchStates(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId) {

      fetchCities(stateId);
    }
  }, [stateId]);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountryId(selectedCountry);
    setFormData({
      ...formData,
      currentAddressCountry: selectedCountry,
    });
    setStateOptions([]); // Clear state options
    setCityOptions([]); // Clear city options
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setStateId(selectedState);
    setFormData({
      ...formData,
      currentAddressState: selectedState,
    });
    setCityOptions([]); // Clear city options
  };
  //per

  useEffect(() => {
    fetch(`${apiUrl}/employee/allCountries`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCountryOptions8(data.data);
        } else {
          console.error('API response is not in the expected format for countries.');
        }

      })
      .catch((error) => {
        console.error('Error fetching country data:', error);
      });
  }, []);



  const fetchStat = (countrId) => {
    fetch(`${apiUrl}/employee/allStates/${countrId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setStateOptions8(data.data);
        } else {
          console.error('API response is not in the expected format for states.');
        }
      })
      .catch((error) => {
        console.error('Error fetching state data:', error);
      });
  };

  const fetchCiti = (statId) => {
    fetch(`${apiUrl}/employee/allcities/${statId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCityOptions8(data.data);
        } else {
          console.error('API response is not in the expected format for cities.');
        }
      })
      .catch((error) => {
        console.error('Error fetching city data:', error);
      });
  };

  useEffect(() => {
    if (countryId) {

      fetchStat(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId) {

      fetchCiti(stateId);
    }
  }, [stateId]);

  const handleCountryChang = (event) => {
    const selectedCountry = event.target.value;
    setCountryId(selectedCountry);
    setFormData({
      ...formData,
      permanentAddressCountry: selectedCountry,
    });
    setStateOptions8([]); // Clear state options
    setCityOptions8([]); // Clear city options
  };

  const handleStateChang = (event) => {
    const selectedState = event.target.value;
    setStateId(selectedState);
    setFormData({
      ...formData,
      permanentAddressState: selectedState,
    });
    setCityOptions8([]); // Clear city options
  };
  const handleInputChang = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const copyCurrentAddress = () => {
    setFormData({
      ...formData,
      sameAsCurrentAddress: true,
      permanentAddressCountry: formData.currentAddressCountry,
      permanentAddressState: formData.currentAddressState,
      permanentAddressCity: formData.currentAddressCity,
      permanentAddressArea: formData.currentAddressArea,
      permanentAddressLane: formData.currentAddressLane,
      permanentAddressPinCode: formData.currentAddressPinCode,
    });
  };

  return (
    <>

      <div id="printableContent">
        <div class="main-container container-fluid">
          <div class="inner-body">

            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Add Employee</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Internal Staff</a></li>
                </ol>
              </div>
              <div className="d-flex">
                <div className="justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary my-2 btn-icon-text"
                    onClick={handlePrint}
                  >
                    <i className="fe fe-download-cloud me-2" /> Print
                  </button>
                </div>
              </div>
            </div>
            <div className="row row-lg">
              <div className="col-xl-4 col-lg-8 col-md-17">
                <div className="card custom-card row-sm-20">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-4">PROFILE PHOTO</h6>
                    </div>
                    <div className>
                      <div className="row row-lg">
                        <div className="col-sm-12 col-md-12">
                          <input
                            className="form-control"
                            type="file"
                            name="profilePic"
                            onChange={handleFileChange}
                          />

                          {profilePicFile && (
                            <img src={URL.createObjectURL(profilePicFile)} alt="Selected File" style={{ maxWidth: '350px' }} />
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Basic Info */}
              <div className="col-xl-8 col-lg-8 col-md-8">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">BASIC INFO</h6>
                    </div>
                    <div className>
                      <div className="row row-sm">
                        {/* Firstname */}
                        <div className="col-lg-6 form-group">
                          <label className="form-label">FirstName: <span className="tx-danger">*</span>

                          </label>
                          <input
                            className="form-control"
                            name="firstName"
                            value={formData.firstName}
                            placeholder="Enter firstName"
                            required
                            type="text"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                          />
                          {errors.firstName && <p style={{ color: 'red' }} className="error-message">{errors.firstName}</p>}


                        </div>
                        {/* Lastname */}
                        <div className="col-lg-6 form-group">
                          <label className="form-label">Lastname: <span className="tx-danger">*</span></label>
                          <input
                            className="form-control"
                            name="lastName"
                            value={formData.lastName}
                            placeholder="Enter lastName"
                            required
                            type="text"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur2}
                          />
                          {errors.lastName && <p style={{ color: 'red' }}>{errors.lastName}</p>}
                        </div>
                        {/* Email ID */}
                        <div className="col-lg-6 form-group">
                          <label className="form-label">Email ID: <span className="tx-danger">*</span></label>
                          <input
                            className="form-control"
                            name="email"
                            value={formData.email}
                            placeholder="Enter email"
                            required
                            type="email"
                            onChange={handleInputChange}

                            onBlur={handleInputBlur3}
                          />
                          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                        </div>
                        <div className="col-lg-6 form-group">
                          <label className="form-label">Password <span className="tx-danger">*</span></label>
                          <input
                            className="form-control"
                            name="password"
                            value={formData.password}
                            placeholder="Enter password"
                            required
                            type={showPassword ? 'text' : 'password'}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur5}
                          />
                          {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                          <span
                            onClick={togglePasswordVisibility}
                            className="fa fa-fw fa-eye field-icon toggle-password"
                          ></span>
                        </div>
                        {/* Mobile No */}
                        <div className="col-lg-6 form-group">
                          <label className="form-label">Mobile No: <span className="tx-danger">*</span></label>
                          <input
                            className="form-control"
                            name="mobileNumber"
                            value={formData.mobileNumber}
                            placeholder="Enter mobileNumber"
                            required
                            type="tel"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur4}
                          />
                          {errors.mobileNumber && <p style={{ color: 'red' }}>{errors.mobileNumber}</p>}

                        </div>
                        {/* Gender */}
                        <div className="col-lg-6">
                          <label className="form-label">Gender: <span className="tx-danger">*</span></label>
                          <select
                            className="form-control select2"

                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                          >
                            <option>Select</option>
                            {genderOption.map((option, index) => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                        {/* Date of Birth */}
                        <div className="col-lg-6">
                          <label className="form-label">Date of Birth:: <span className="tx-danger">*</span></label>
                          <div className="input-group">
                            <input
                              className="form-control select2"
                              type="date"
                              name="dob"
                              value={formData.dob}
                              onChange={handleDateChange}
                              max={getCurrentDate()}
                            // Restricts selection to current date or earlier
                            />


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Current Address */}
              <div className="row row-sm">
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">Current Address</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">Country</label>
                          <select
                            className="form-control select select2"
                            name="currentAddressCountry"
                            // value={formData.currentAddressCountry}
                            onChange={handleCountryChange}
                          >

                            <option value="">Select a country</option>
                            {countryOptions.map((country) => (
                              <option selected={country.id === +formData.currentAddressCountry} key={country.id} value={(country.id)}>
                                {`${country.name}`}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">State</label>
                          <select
                            className="form-control select select2"
                            name="currentAddressState"
                            // value={formData.currentAddressState}
                            onChange={handleStateChange}

                          >
                            <option value="">Select a State</option>
                            {stateOptions.map((state) => (
                              <option selected={state.id === +formData.currentAddressState} key={state.id} value={(state.id)}>
                                {`${state.name}`}
                              </option>
                            ))}

                          </select>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">City</label>
                          <select
                            className="form-control select select2"
                            name="currentAddressCity"
                            // value={formData.currentAddressCity}
                            onChange={handleInputChange}


                          >
                            <option value="">Select a city</option>
                            {cityOptions.map((city, index) => (
                              <option selected={city.id === +formData.currentAddressCity} key={city.id} value={(city.id)}>
                                {`${city.name}`}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Area</label>
                            <input
                              className="form-control"
                              name="currentAddressArea"
                              value={formData.currentAddressArea}
                              placeholder="Enter area"
                              type="text"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Address lane</label>
                            <input
                              className="form-control"
                              name="currentAddressLane"
                              value={formData.currentAddressLane}
                              placeholder="Enter address lane"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Pin Code</label>
                            <input
                              className="form-control"
                              name="currentAddressPinCode"
                              value={formData.currentAddressPinCode}
                              placeholder="Enter pin code"
                              type="text"
                              onChange={handleInputChange}
                             
                            />
                            {errors.currentAddressPinCode && <p style={{ color: 'red' }}>{errors.currentAddressPinCode}</p>}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">
                          Permanent Address{" "}
                          <label className="ckbox" style={{ float: "right" }}>
                            <input
                              name="sameAsCurrentAddress"
                              type="checkbox"
                              checked={formData.sameAsCurrentAddress}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  copyCurrentAddress();
                                } else {
                                  setFormData({
                                    ...formData,
                                    sameAsCurrentAddress: false,
                                  });
                                }
                              }}
                            />

                            <span className="tx-12">Same as Current Address</span>
                          </label>
                        </h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">Country</label>
                          <select
                            className="form-control select select2"
                            name="permanentAddressCountry"

                            onChange={handleCountryChang}
                            disabled={formData.sameAsCurrentAddress}
                          >

                            <option value="">Select a country</option>
                            {countryOptions8.map((country) => (
                              <option selected={country.id === +formData.permanentAddressCountry} key={country.id} value={(country.id)}>
                                {`${country.name}`}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">State</label>
                          <select
                            className="form-control select select2"
                            name="permanentAddressState"
                            onChange={handleStateChang}
                            disabled={formData.sameAsCurrentAddress}
                          >
                            <option value="">Select a state</option>
                            {stateOptions8.map((state) => (
                              <option selected={state.id === +formData.permanentAddressState} key={state.id} value={(state.id)}>
                                {`${state.name}`}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">City</label>
                          <select
                            className="form-control select select2"
                            name="permanentAddressCity"
                            onChange={handleInputChang}
                            disabled={formData.sameAsCurrentAddress}
                          >
                            <option value="">Select a city</option>
                            {cityOptions8.map((city, index) => (
                              <option selected={city.id === +formData.permanentAddressCity} key={city.id} value={(city.id)}>
                                {`${city.name}`}
                              </option>
                            ))}
                          </select>

                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Area</label>
                            <input
                              className="form-control"
                              name="permanentAddressArea"
                              value={formData.permanentAddressArea}
                              placeholder="Enter area"
                              type="text"
                              onChange={handleInputChange}
                              disabled={formData.sameAsCurrentAddress}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Address lane</label>
                            <input
                              className="form-control"
                              name="permanentAddressLane"
                              value={formData.permanentAddressLane}
                              placeholder="Enter address lane"
                              onChange={handleInputChange}
                              disabled={formData.sameAsCurrentAddress}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 mg-t-10">
                          <div className="form-group mb-0">
                            <label className="form-label">Pin Code</label>
                            <input
                              className="form-control"
                              name="permanentAddressPinCode"
                              value={formData.permanentAddressPinCode}
                              placeholder="Enter pin code"
                              type="text"
                              onChange={handleInputChange}
                              disabled={formData.sameAsCurrentAddress}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Company Info */}


              {/* Permissions */}
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">ONBOARDING</h6>
                      </div>
                      <div className="row row-sm">
                        {/* <div className="col-sm-4 form-group">
                          <label className="form-label">Company</label>
                          <select className="form-control select select2">
                            <option>Select</option>
                          </select>
                        </div> */}
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Department</label>

                          <select
                            className="form-control select2"
                            name="department"
                            value={formData.department}
                            onChange={handleInputChange}
                          >
                            <option>Select</option>
                            {genderOptions2.map((option, index) => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>


                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Designation</label>
                          <select
                            className="form-control select2"
                            name="designation"
                            value={formData.designation}
                            onChange={handleInputChange}
                          >
                            <option>Select</option>
                            {genderOptions3.map((option, index) => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Types of user</label>
                          <select
                            className="form-control select2"
                            name="userType"
                            value={formData.userType}
                            onChange={handleInputChange}
                          >
                            <option>Select</option>
                            <option>User</option>
                            <option>Admin </option>

                          </select>
                        </div>
                        <div className="col-lg-4 form-group">
                          <label className="form-label">
                            Date of Joining: <span className="tx-danger">*</span>
                          </label>
                          <div className="input-group">

                            <input
                              className="form-control select2"
                              type="date"
                              name="dateOfJoin"
                              value={formData.dateOfJoin}
                              onChange={handleDateChange2}
                              max={getCurrentDate2()} // Restricts selection to current date or earlier
                            />
                          </div>
                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Reporting Boss-A</label>
                          <select
                            className="form-control select2"
                            name="reportingBossA"
                            value={formData.reportingBossA}
                            onChange={handleInputChange}
                          >
                            <option>Select</option>
                            {options.map((option, index) => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>

                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Reporting Boss-B</label>
                          <select
                            className="form-control select2"
                            name="reportingBossB"
                            value={formData.reportingBossB}
                            onChange={handleInputChange}
                          >
                            <option>Select</option>
                            {optio.map((option, index) => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Documents */}
              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">DOCUMENTS</h6>
                    </div>

                    <div className="row row-sm">
                      {/* Aadhaar Card */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Aadhaar Card (Upload pdf) <span className="tx-danger">*</span></label>
                        <input
                          className="form-control"
                          name="adharNumber"
                          value={formData.adharNumber}
                          placeholder="Enter Aadhaar number"
                          type="text"
                          onChange={handleInputChange}

                        />
                        {errors.adharNumber && <p style={{ color: 'red' }}>{errors.adharNumber}</p>}
                        <input
                          className="form-control"
                          type="file"
                          name="adharImage"

                          accept="/pdf"
                          onChange={handleFileChange}
                        />

                      </div>

                      <div className="col-lg-4 form-group">
                        <label className="form-label">PAN Card (Upload pdf) <span className="tx-danger">*</span></label>
                        <input
                          className="form-control"
                          name="panNumber"
                          value={formData.panNumber}
                          placeholder="Enter PAN number"
                          type="text"
                          onChange={handleInputChange}
                         
                        />
                        {errors.panNumber && <p style={{ color: 'red' }}>{errors.panNumber}</p>}
                        <input
                          className="form-control"
                          type="file"
                          name="panImage"
                          onChange={handleFileChange2}
                        />

                      </div>

                      <div className="col-lg-4 form-group">
                        <label className="form-label">Driving Licence (Upload pdf) <span className="tx-danger">*</span></label>
                        <input
                          type="file"
                          className="dropify"
                          name="drivingLicenseImage"
                          onChange={handleFileChange3}
                        />

                      </div>

                    </div>
                  </div>
                </div>
              </div>

              {/* Bank Info */}
              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">BANK INFO</h6>
                    </div>
                    <div className="row row-sm">
                      {/* Account No */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Account No</label>
                        <input
                          className="form-control"
                          name="accountNumber"
                          value={formData.accountNumber}
                          placeholder="Enter account number"
                          type="text"
                          onChange={handleInputChange}
                         
                        />
                        {errors.accountNumber && <p style={{ color: 'red' }}>{errors.accountNumber}</p>}
                      </div>
                      {/* Account Name */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Account Name</label>
                        <input
                          className="form-control"
                          name="accountName"
                          value={formData.accountName}
                          placeholder="Enter account name"
                          type="text"
                          onChange={handleInputChange}
                         
                        />
                        {errors.accountName && <p style={{ color: 'red' }}>{errors.accountName}</p>}
                      </div>
                      {/* Bank Name */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Bank Name</label>
                        <input
                          className="form-control"
                          name="bankName"
                          value={formData.bankName}
                          placeholder="Enter bank name"
                          type="text"
                          onChange={handleInputChange}
                         
                        />
                        {errors.bankName && <p style={{ color: 'red' }}>{errors.bankName}</p>}
                      </div>
                      {/* IFSC */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">IFSC</label>
                        <input
                          className="form-control"
                          name="ifsc"
                          value={formData.ifsc}
                          placeholder="Enter IFSC"
                          type="text"
                          onChange={handleInputChange}

                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Offer Details */}
              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">OFFER DETAILS</h6>
                    </div>
                    <div className="row row-sm">
                      {/* Offered Package */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Offered Package(Yearly) </label>
                        <input
                          className="form-control"
                          name="offeredPackage"
                          value={formData.offeredPackage}
                          placeholder="Enter offered package"
                          type="text"
                          onChange={handleInputChange}
                          
                        />
                        {errors.offeredPackage && <p style={{ color: 'red' }}>{errors.offeredPackage}</p>}
                      </div>
                      {/* Offered CTC */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Offered CTC Salary(Yearly)</label>
                        <input
                          className="form-control"
                          name="offeredCTC"
                          value={formData.offeredCTC}
                          placeholder="Enter offered CTC"
                          type="text"
                          onChange={handleInputChange}
                         
                        />
                        {errors.offeredCTC && <p style={{ color: 'red' }}>{errors.offeredCTC}</p>}
                      </div>
                      {/* Notice Period */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Notice Period</label>
                        <select
                          className="form-control select2"
                          name="noticePeriod"
                          value={formData.noticePeriod}
                          onChange={handleInputChange}
                        >
                          <option>Select</option>
                          {genderOptions4.map((option, index) => (
                            <option key={option.id} value={option.name}>{option.name}</option>
                          ))}
                        </select>
                      </div>
                      {/* Working Shift */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Working Shift</label>
                        <select
                          className="form-control select2"
                          name="workingShift"
                          value={formData.workingShift}
                          onChange={handleInputChange}
                        >
                          <option>Select</option>
                          {genderOptions5.map((option, index) => (
                            <option key={option.id} value={option.name}>{option.name}</option>
                          ))}
                        </select>
                      </div>
                      {/* Per Month Salary */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Per Month Salary </label>
                        <input
                          className="form-control"
                          name="perMonthCharges"
                          value={formData.perMonthCharges}
                          placeholder="Enter per month charges"
                          type="text"
                          onChange={handleInputChange}
                        
                        />
                        {errors.perMonthCharges && <p style={{ color: 'red' }}>{errors.perMonthCharges}</p>}
                      </div>
                     

                      {/* Offer Letter */}
                      <div className="col-lg-4 form-group">
                        <label className="form-label">Offer Letter (Upload pdf)</label>
                        <input
                          type="file"
                          className="dropify"
                          name="offerLetterUpload"
                          onChange={handleFileChange4}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="col-lg-12 mg-t-10">
                    <button type="submit" onClick={handleSubmit} className="btn btn-primary">
                      Submit
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default YourFormComponent;
